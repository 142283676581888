/*  --TABLE OF CONTENTS--

	- Bootstrap customization
	- Half coulumns
	- Banners
	- Features
	- Blur area
	- iCheck
	- Range slider
	- Date picker
	- Quantity selector
	- Autocomplete
	- Spinner
	- Window scroll
	- Mega Navigation
	- Language and currency selector
	- Page section
	- Account page
	- Breadcrumbs
	- Hero area
	- Hero text
	- Search area
	- Blog
	- 404
	- About
	- Contact
	- Login
	- Payment
	- Loading
	- Elements
	- Footer
	- Utilities

   /--TABLE OF CONTENTS--  */
   :root {
    --craneGreenLight: #2fbc31;
    --craneGreenDark: #0c6013;
     --craneGreenDark200: #baf1bd;
     --sooresWhite: #ffffff;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     letter-spacing: 0.33px;
     font-weight: bold;
   }
   body {
     font:14px/1.7 "Open Sans",Helvetica,Arial,sans-serif;
     font-size: 15px;
     background-color: #f2f2f2;
   }
   .text-white {
     color: #fff;
   }
   .text-muted {
     color: inherit;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     font-size: 13px;
   }
   .text-upcase {
     text-transform: uppercase;
   }
   .google-map {
     width: 100%;
     height: 300px;
   }
   .gm-style-iw + div {
     display: none;
   }
   .bg-grad {
     background: -webkit-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -moz-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -o-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -ms-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: linear-gradient(to right, #00a8f0, #0084bd) !important;
   }
   .bg-grad-i {
     background: -webkit-linear-gradient(left, #ff7b42, #ff570f) !important;
     background: -moz-linear-gradient(left, #ff7b42, #ff570f) !important;
     background: -o-linear-gradient(left, #ff7b42, #ff570f) !important;
     background: -ms-linear-gradient(left, #ff7b42, #ff570f) !important;
     background: linear-gradient(to right, #ff7b42, #ff570f) !important;
   }
   /*  --BOOTSTRAP CUSTOMIZATION--  */
   .btn {
     -webkit-transition: 0.2s;
     -moz-transition: 0.2s;
     -o-transition: 0.2s;
     -ms-transition: 0.2s;
     transition: 0.2s;
     border-radius: 2px;
     letter-spacing: 0.75px;
     font-size: 13px;
     position: relative;
     font-weight: 700;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
   }
   .btn:hover {
     -webkit-transition: 0.2s;
     -moz-transition: 0.2s;
     -o-transition: 0.2s;
     -ms-transition: 0.2s;
     transition: 0.2s;
   }
   .btn > .btn-icon {
     margin-right: 5px;
   }
   .btn-xs {
     font-size: 11px;
     letter-spacing: 0;
   }
   .btn-sm {
     letter-spacing: 0;
     font-size: 12px;
   }
   .btn-lg {
     padding: 20px;
     font-size: 17px;
   }
   .btn-xxl {
     padding: 20px 30px;
     font-size: 30px;
   }
   .btn-xl {
     padding: 15px 20px;
     font-size: 20px;
   }
   .btn-uc {
     text-transform: uppercase;
   }
   .btn-primary {
     background: var(--craneGreenLight);
     border-color: #0084bd;
   }

   .btn-primary:hover {
     background: #0084bd;
     border-color: #0076a8;
   }
   .btn-primary:focus {
     background: #0084bd;
     border-color: #0076a8;
   }
   .btn-primary-invert,
   .btn-primary-inverse {
     background: #fff;
     border-color: var(--craneGreenLight);
     color: var(--craneGreenLight) !important;
   }
   .btn-primary-invert:hover,
   .btn-primary-inverse:hover {
     color: #fff !important;
     background: var(--craneGreenLight);
     border-color: #fff;
   }
   .btn-dark {
     background: #595959;
     color: #fff;
     border-color: #404040;
   }
   .btn-dark:hover {
     color: #fff;
     background: #404040;
   }
   .btn-black {
     background: linten(#000, 15%);
     border-color: #000;
     color: #fff;
   }
   .btn-black:hover {
     color: #fff;
     background: #000;
   }
   .btn-paypal {
     background: #0079c1;
     color: #fff;
     border-color: #006dae;
   }
   .btn-paypal:hover {
     color: #fff;
     background: #006dae;
     border-color: #00619a;
   }
   .btn-white {
     color: #595959;
     background: #fff;
     border: none;
   }
   .btn-ghost {
     background: none;
     border: 1px solid;
   }
   .btn-ghost.btn-primary {
     color: #0093d2;
   }
   .btn-ghost.btn-primary:hover {
     background: #0093d2;
   }
   .btn-ghost.btn-success {
     color: #5cb85c;
   }
   .btn-ghost.btn-success:hover {
     background: #5cb85c;
   }
   .btn-ghost.btn-info {
     color: #5bc0de;
   }
   .btn-ghost.btn-info:hover {
     background: #5bc0de;
   }
   .btn-ghost.btn-warning {
     color: #f0ad4e;
   }
   .btn-ghost.btn-warning:hover {
     background: #f0ad4e;
   }
   .btn-ghost.btn-danger {
     color: #d9534f;
   }
   .btn-ghost.btn-danger:hover {
     background: #d9534f;
   }
   .btn-ghost.btn-primary-inverse {
     color: #ff6c2d;
   }
   .btn-ghost.btn-primary-inverse:hover {
     background: #ff6c2d;
   }
   .btn-ghost:hover {
     color: #fff;
   }
   .btn-ghost.btn-default:hover {
     color: #353535;
   }
   .btn-ghost.btn-white {
     border-color: #fff;
     color: #fff;
   }
   .btn-ghost.btn-white:hover {
     background: #fff !important;
     color: #595959;
   }
   .btn-ghost.btn-black {
     border-color: #000;
     color: #000;
   }
   .btn-ghost.btn-black:hover {
     background: #000;
     color: #fff;
   }
   .btn-ghost.btn-dark {
     border-color: #595959;
     color: #595959;
   }
   .btn-ghost.btn-dark:hover {
     background: #595959;
     color: #fff;
   }
   .btn-lock-screen {
     left: 50%;
     position: fixed;
     top: 70px;
     z-index: 999;
     -webkit-transform: translate3d(-50%, 0, 0);
     -moz-transform: translate3d(-50%, 0, 0);
     -o-transform: translate3d(-50%, 0, 0);
     -ms-transform: translate3d(-50%, 0, 0);
     transform: translate3d(-50%, 0, 0);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     opacity: 1;
     -ms-filter: none;
     filter: none;
     padding: 5px 7px;
     font-size: 11px;
     letter-spacing: 1px;
     border-radius: 10px;
   }
   .btn-lock-screen.btn-lock-screen-hide {
     -webkit-transform: translate3d(-50%, -100px, 0);
     -moz-transform: translate3d(-50%, -100px, 0);
     -o-transform: translate3d(-50%, -100px, 0);
     -ms-transform: translate3d(-50%, -100px, 0);
     transform: translate3d(-50%, -100px, 0);
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .btn-shadow {
     -webkit-box-shadow: 0 1px 30px rgba(0,0,0,0.28);
     box-shadow: 0 1px 30px rgba(0,0,0,0.28);
   }
   .row.row-wrap,
   .row.row-col-gap {
     margin-bottom: -30px;
     position: relative;
     display: table;
     width: 100%;
     width: calc(100% + 30px);
   }
   .row.row-wrap > [class^="col-"],
   .row.row-col-gap > [class^="col-"] {
     padding-bottom: 30px;
   }
   .row.row-wrap[data-gutter="10"],
   .row.row-col-gap[data-gutter="10"] {
     margin-bottom: -10px;
     width: calc(100% + 10px);
   }
   .row.row-wrap[data-gutter="10"] > [class^="col-"],
   .row.row-col-gap[data-gutter="10"] > [class^="col-"] {
     padding-bottom: 10px;
   }
   .row.row-wrap[data-gutter="20"],
   .row.row-col-gap[data-gutter="20"] {
     margin-bottom: -20px;
     width: calc(100% + 20px);
   }
   .row.row-wrap[data-gutter="20"] > [class^="col-"],
   .row.row-col-gap[data-gutter="20"] > [class^="col-"] {
     padding-bottom: 20px;
   }
   @media (max-width: 992px) {
     .row.row-col-mob-gap > [class^="col-"] {
       margin-bottom: 30px;
     }
   }
   @media (min-width: 992px) {
     .row.row-col-border > [class^="col-"],
     .row.row-col-border-white > [class^="col-"] {
       border-left: 1px solid #e6e6e6;
     }
     .row.row-col-border > [class^="col-"]:first-child,
     .row.row-col-border-white > [class^="col-"]:first-child {
       border-left: none;
     }
   }
   @media (min-width: 992px) {
     .row.row-col-border-white > [class^="col-"] {
       border-color: #fff;
     }
   }
   .row.row-col-reverse > [class^="col-"] {
     float: right;
   }
   @media (max-width: 992px) {
     .row.row-col-reverse > [class^="col-"] {
       float: none;
     }
   }
   @media (max-width: 992px) {
     .row.row-mob-full {
       margin: 0 !important;
     }
     .row.row-mob-full > [class^="col-"] {
       padding: 0 !important;
     }
   }
   .row.row-col-static > [class^="col-"] {
     position: static;
   }
   .row.row-col-full > [class^="col-"] {
     height: 100%;
   }
   .row.row-full {
     margin-left: 0;
     margin-right: 0;
     width: 100%;
   }
   .row.row-sm-gap {
     margin-bottom: -10px;
   }
   .row.row-sm-gap > [class^="col-"] {
     margin-bottom: 10px;
   }
   .row.row-no-gutter,
   .row[data-gutter="0"],
   .row[data-gutter="none"] {
     margin: 0 !important;
   }
   .row.row-no-gutter > [class^="col-"],
   .row[data-gutter="0"] > [class^="col-"],
   .row[data-gutter="none"] > [class^="col-"] {
     padding: 0 !important;
   }
   .row[data-gutter="10"] {
     margin-left: -5px;
     margin-right: -5px;
   }
   .row[data-gutter="10"] > [class^="col-"] {
     padding-left: 5px;
     padding-right: 5px;
   }
   .row[data-gutter="20"] {
     margin-left: -10px;
     margin-right: -10px;
   }
   .row[data-gutter="20"] > [class^="col-"] {
     padding-left: 10px;
     padding-right: 10px;
   }
   @media (min-width: 992px) {
     .row[data-gutter="40"] {
       margin-left: -20px;
       margin-right: -20px;
     }
     .row[data-gutter="40"] > [class^="col-"] {
       padding-left: 20px;
       padding-right: 20px;
     }
   }
   @media (min-width: 992px) {
     .row[data-gutter="60"] {
       margin-left: -30px;
       margin-right: -30px;
     }
     .row[data-gutter="60"] > [class^="col-"] {
       padding-left: 30px;
       padding-right: 30px;
     }
   }
   @media (min-width: 992px) {
     .row[data-gutter="80"] {
       margin-left: -40px;
       margin-right: -40px;
     }
     .row[data-gutter="80"] > [class^="col-"] {
       padding-left: 40px;
       padding-right: 40px;
     }
   }
   @media (min-width: 992px) {
     .row[data-gutter="100"] {
       margin-left: -50px;
       margin-right: -50px;
     }
     .row[data-gutter="100"] > [class^="col-"] {
       padding-left: 50px;
       padding-right: 50px;
     }
   }
   @media (min-width: 992px) {
     .row[data-gutter="120"] {
       margin-left: -60px;
       margin-right: -60px;
     }
     .row[data-gutter="120"] > [class^="col-"] {
       padding-left: 60px;
       padding-right: 60px;
     }
   }
   @media (min-width: 992px) {
     .row-eq-height,
     .row-col-eq {
       display: -webkit-box;
       display: -webkit-flex;
       display: -ms-flexbox;
       display: -webkit-box;
       display: -moz-box;
       display: -webkit-flex;
       display: -ms-flexbox;
       display: box;
       display: flex;
     }
   }
   @media (min-width: 992px) {
     .row-eq-height.row-col-reverse,
     .row-col-eq.row-col-reverse {
       -webkit-box-direction: reverse;
       -moz-box-direction: reverse;
       -o-box-direction: reverse;
       -webkit-box-orient: horizontal;
       -moz-box-orient: horizontal;
       -o-box-orient: horizontal;
       -webkit-flex-direction: row-reverse;
       -ms-flex-direction: row-reverse;
       flex-direction: row-reverse;
     }
   }
   .col-ng {
     padding: 0;
   }
   .nav > li > a {
     margin-right: 0;
   }
   @media (max-width: 992px) {
     .nav-mob-inline {
       overflow-x: auto;
       white-space: nowrap;
     }
     .nav-mob-inline > li {
       display: inline-block;
       float: none;
       margin-bottom: 0;
       text-align: center;
     }
   }
   @media (max-width: 992px) {
     .nav-mob-bg-dark {
       background: #333;
     }
   }
   .nav-blank {
     border: none;
   }
   .nav-blank > li {
     margin-right: 30px;
   }
   .nav-blank > li > a,
   .nav-blank > li > a:hover,
   .nav-blank > li > a:focus {
     border: none;
     background: none !important;
     border-radius: 0;
     padding: 0;
   }
   .nav-blank > li.active > a,
   .nav-blank > li.active > a:hover,
   .nav-blank > li.active > a:focus {
     border: none;
   }
   .nav-default > li > a,
   .nav-default > li > a:hover,
   .nav-default > li > a:focus {
     color: #727272;
   }
   .nav-default.active > a,
   .nav-default.active > a:hover,
   .nav-default.active > a:focus {
     color: #313131;
   }
   .nav-sqr > li > a {
     border-radius: 0;
   }
   .nav-lg {
     font-size: 17px;
   }
   .nav-lg .nav-icon {
     font-size: 19px;
   }
   .nav-lg > li > a {
     padding: 13px 17px;
   }
   .nav-lg.nav-blank > li > a {
     padding: 0;
   }
   .nav-lg.nav-line > li {
     margin-right: 30px;
   }
   .nav-lg.nav-line > li:last-child {
     margin-right: 0;
   }
   .nav-xl {
     font-size: 20px;
   }
   .nav-pull-up {
     margin-top: -41px;
   }
   .nav-pull-up.nav-lg {
     margin-top: -54px;
   }
   .nav-line > li {
     margin-right: 25px;
   }
   .nav-line > li > a,
   .nav-line > li > a:hover,
   .nav-line > li > a:focus {
     border: none;
     background: none !important;
     border-radius: 0 !important;
     padding-left: 0;
     padding-right: 0;
   }
   .nav-line > li.active > a,
   .nav-line > li.active > a:hover,
   .nav-line > li.active > a:focus {
     border: none;
     border-bottom: 2px solid #666;
   }
   .nav-line > li:last-child {
     margin-right: 0;
   }
   .nav-line.nav-center > li,
   .nav-blank.nav-center > li {
     margin-right: 15px;
     margin-left: 15px;
   }
   .nav-line.nav-center > li:last-child,
   .nav-blank.nav-center > li:last-child {
     margin-right: 15px;
   }
   .nav-center {
     text-align: center;
   }
   .nav-center > li {
     float: none;
     display: inline-block;
   }
   .nav-white > li > a,
   .nav-white > li > a:hover,
   .nav-white > li > a:focus {
     color: #fff;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     background: none;
   }
   .nav-white > li.active > a,
   .nav-white > li.active > a:hover,
   .nav-white > li.active > a:focus {
     color: #595959;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .nav-white.nav-blank > li.active > a,
   .nav-white.nav-blank > li.active > a:hover,
   .nav-white.nav-blank > li.active > a:focus {
     color: #fff;
   }
   .nav-white.nav-line {
     border-color: rgba(255,255,255,0.3);
   }
   .nav-white.nav-line > li > a,
   .nav-white.nav-line > li > a:hover,
   .nav-white.nav-line > li > a:focus {
     border-color: #fff;
   }
   .nav-white.nav-line > li.active > a {
     color: #fff !important;
   }
   .nav-no-br {
     border-bottom: none;
   }
   .nav-no-br > li {
     margin-bottom: 0;
   }
   .nav-no-br > li > a,
   .nav-no-br > li > a:hover,
   .nav-no-br > li > a:focus {
     border: none;
   }
   .nav-no-br > li.active > a,
   .nav-no-br > li.active > a:hover,
   .nav-no-br > li.active > a:focus {
     border: none;
   }
   .nav-icon-center {
     display: block;
     text-align: center;
     margin-bottom: 10px;
     font-size: 19px;
   }
   .nav-active-primary > li.active > a,
   .nav-active-primary > li.active > a:hover,
   .nav-active-primary > li.active > a:focus {
     background: #0093d2;
     color: #fff;
   }
   .nav-active-primary.nav-line > li.active > a,
   .nav-active-primary.nav-blank > li.active > a,
   .nav-active-primary.nav-line > li.active > a:hover,
   .nav-active-primary.nav-blank > li.active > a:hover,
   .nav-active-primary.nav-line > li.active > a:focus,
   .nav-active-primary.nav-blank > li.active > a:focus {
     color: #0093d2;
     background: none;
     border-color: #0093d2;
   }
   .nav-active-primary-inverse > li.active > a,
   .nav-active-primary-inverse > li.active > a:hover,
   .nav-active-primary-inverse > li.active > a:focus {
     background: #fff;
     color: var(--craneGreenLight);
   }
   .nav-active-primary-inverse.nav-line > li.active > a,
   .nav-active-primary-inverse.nav-blank > li.active > a,
   .nav-active-primary-inverse.nav-line > li.active > a:hover,
   .nav-active-primary-inverse.nav-blank > li.active > a:hover,
   .nav-active-primary-inverse.nav-line > li.active > a:focus,
   .nav-active-primary-inverse.nav-blank > li.active > a:focus {
     color: var(--craneGreenLight);
     background: none;
     border-color: var(--craneGreenLight);
   }
   .nav-active-white > li.active > a,
   .nav-active-white > li.active > a:hover,
   .nav-active-white > li.active > a:focus {
     background: #fff;
     color: #595959;
   }
   .nav-eq-width {
     display: table;
     width: 100%;
   }
   .nav-eq-width > li {
     display: table-cell;
     float: none;
   }
   .nav-eq-width > li > a {
     margin: 0;
   }
   .tab-content-curved {
     border-radius: 4px;
   }
   .navbar-theme {
     margin-bottom: 0;
     border-radius: 0;
     background: #fff;
     border: none;
   }
   .navbar-theme .navbar-toggle {
     border: none !important;
     background: none !important;
   }
   .navbar-inverse {
     background: #333;
   }
   .navbar-brand > img {
     max-width: 100%;
     max-height: 100%;
   }
   .navbar-primary {
     background: #0093d2;
   }
   .navbar-primary .navbar-nav > li > a {
     color: rgba(255,255,255,0.7);
   }
   @media (max-width: 992px) {
     .navbar-primary .navbar-nav > li {
       border-color: #006793 !important;
     }
   }
   .navbar-primary .navbar-collapse {
     border-color: #006793 !important;
   }
   .navbar-primary-inverse {
     background: #ff6c2d;
   }
   .navbar-primary-inverse .navbar-nav > li > a {
     color: rgba(255,255,255,0.7);
   }
   @media (max-width: 992px) {
     .navbar-primary-inverse .navbar-nav > li {
       border-color: #d23f00 !important;
     }
   }
   .navbar-primary-inverse .navbar-collapse {
     border-color: #d23f00 !important;
   }
   .navbar-theme {
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
   }
   .navbar-theme-abs {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 999;
   }
   @media (max-width: 992px) {
     .navbar-theme-border .navbar-collapse {
       border: none;
       overflow-y: hidden;
     }
     .navbar-theme-border .navbar-nav {
       margin-bottom: 0;
     }
   }
   .navbar-theme-border .navbar-collapse {
     /* border-bottom: 1px solid rgba(255,255,255,0.3); */
   }
   .navbar-theme-border .navbar-nav {
     margin-bottom: -1px !important;
   }
   .navbar-theme-fixed {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     z-index: 999;
   }
   .navbar-theme-transparent {
     background: none;
   }
   .navbar-theme .navbar-nav > li:hover > a {
     color: #3e3e3e;
   }
   .navbar-theme .navbar-nav > li > a {
    font-size: 16px;
    font-weight: 400;
    margin: 0 15px;
   }
   .navbar-theme .navbar-nav > li:last-child > a {
     margin-right: 0;
   }
   .navbar-theme .navbar-nav > li.active > a {
     background: none;
   }
   .navbar-theme .navbar-nav > li.active > a:hover {
     background: none;
   }
   .navbar-theme .navbar-nav > li.active > a:focus {
     background: none;
   }
   .navbar-theme .navbar-nav > li.open > a,
   .navbar-theme .navbar-nav > li.open > a:hover,
   .navbar-theme .navbar-nav > li.open > a:focus {
     background: none;
   }
   .navbar-theme.navbar-inverse .navbar-nav > li:hover > a {
     color: var(--craneGreenLight);
   }
   .navbar-theme.navbar-inverse .navbar-nav .navbar-nav-item-user-icon {
     color: #fff;
   }
   .navbar-full .navbar-right {
     margin-right: 0;
   }
   .navbar-full .navbar-inner {
     padding: 0 15px;
   }
   .navbar-flag {
     height: 20px;
   }
   .navbar-theme-transparent .navbar-nav > li > a {
     color: var(--craneGreenDark);
   }
   .navbar-theme-transparent .navbar-nav > li.active > a {
     border-bottom: 3px solid var(--craneGreenDark)

   }
   @media (min-width: 992px) {
     .navbar-theme .dropdown:hover .dropdown-menu {
       display: block;
     }
   }
   .navbar-theme .navbar-inner {
     position: relative;
   }
   .navbar-theme .dropdown-menu {
     border-radius: 0;
     padding: 0;
     border: none;
     border-top: 5px solid transparent;
   }
   .navbar-theme .dropdown-menu > li > a {
     padding: 12px 20px;
   }
   .navbar-theme .dropdown-menu > li > a:hover {
     background: #0093d2;
     color: #4d4d4d;
   }
   .navbar-theme .dropdown-menu > li.active > a {
     background: #0093d2;
     color: #fff;
   }
   .navbar-theme .dropdown-menu-xl,
   .navbar-theme .dropdown-menu-xxl,
   .navbar-theme .dropdown-menu-lg,
   .navbar-theme .dropdown-menu-full {
     padding: 15px;
   }
   .dropdown-menu-xl {
     width: 600px;
   }
   .dropdown-menu-lg {
     width: 450px;
   }
   .dropdown-menu-xxl {
     width: 800px;
   }
   .dropdown-full {
     position: static !important;
   }
   .dropdown-menu-full {
     width: 100%;
   }
   .navbar-nav-item-user > a {
     padding-left: 30px !important;
   }
   .navbar-nav-item-user-icon,
   .navbar-nav-item-user-img {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     display: block;
     font-size: 20px;
     position: absolute;
     top: 50%;
     left: 0;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
     margin-top: -1px;
   }
   .navbar-nav-item-user-img {
     width: 20px;
     border-radius: 50%;
   }
   li:hover .navbar-nav-item-user-icon,
   li.active .navbar-nav-item-user-icon,
   li:hover .navbar-nav-item-user-img,
   li.active .navbar-nav-item-user-img {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .navbar-theme-border-def .navbar-collapse {
     border-bottom: 1px solid #b3b3b3;
   }
   .navbar-theme-border-def .navbar-nav > li.active > a {
     border-bottom: 1px solid #808080;
   }
   .navbar-theme .navbar-toggle {
     margin-right: 0;
   }
   @media (max-width: 992px) {
     .navbar-theme .navbar-brand {
       padding-left: 0;
     }
     .navbar-theme .navbar-nav {
       margin: 0;
     }
     .navbar-theme .navbar-nav > li {
       border-bottom: 1px solid #e6e6e6;
     }
     .navbar-theme .navbar-nav > li > a {
       margin: 0;
     }
     .navbar-theme .navbar-nav > li > a.dropdown-toggle:before {
       font-family: 'FontAwesome';
       content: '\f107';
       position: absolute;
       top: 50%;
       right: 3px;
       opacity: 0.33;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
       filter: alpha(opacity=33);
       -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
       -o-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);
       font-size: 18px;
     }
     .navbar-theme .navbar-collapse {
       padding: 0;
     }
     .navbar-theme .dropdown-menu {
       padding: 0;
       margin-left: 10px;
       overflow: hidden;
     }
     .navbar-theme .dropdown-meganav-list-items {
       padding-bottom: 20px;
     }
     .navbar-theme.navbar-inverse .navbar-nav > li {
       border-bottom: 1px solid #4d4d4d;
     }
     .navbar-theme.navbar-inverse .dropdown-meganav-list-title,
     .navbar-theme.navbar-inverse .dropdown-meganav-select-list-title {
       color: #4d4d4d;
     }
     .navbar-theme.navbar-inverse .dropdown-meganav-list-items > li > a {
       color: #4d4d4d;
     }
     .navbar-theme.navbar-theme-transparent {
       background: #fff;
     }
     .navbar-theme.navbar-theme-transparent li.active > a {
       border: none;
     }
     .navbar-theme.navbar-theme-abs {
       display: block;
       position: relative;
     }
   }
   .heading-theme {
     position: relative;
     padding: 20px 0;
   }
   .heading-theme-title {
     margin: 0;
   }
   .heading-theme-subtitle {
     margin-bottom: 0;
     margin-top: 10px;
   }
   .heading-theme-color {
     background: #0093d2;
   }
   .heading-theme-color .heading-theme-title,
   .heading-theme-color .heading-theme-subtitle {
     color: #fff;
   }
   .heading-theme-color .heading-theme-subtitle {
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   /*  /--BOOTSTRAP CUSTOMIZATION--  */
   /*  --HALF COULMN--  */
   .col-md-0-5,
   .col-md-1-5,
   .col-md-2-5,
   .col-md-3-5,
   .col-md-4-5,
   .col-md-5-5,
   .col-md-6-5,
   .col-md-7-5,
   .col-md-8-5,
   .col-md-9-5,
   .col-md-10-5,
   .col-md-11-5,
   .col-xs-0-5,
   .col-xs-1-5,
   .col-xs-2-5,
   .col-xs-3-5,
   .col-xs-4-5,
   .col-xs-5-5,
   .col-xs-6-5,
   .col-xs-7-5,
   .col-xs-8-5,
   .col-xs-9-5,
   .col-xs-10-5,
   .col-xs-11-5 {
     float: left;
     position: relative;
     min-height: 1px;
     padding-right: 15px;
     padding-left: 15px;
   }
   @media (max-width: 992px) {
     .col-md-0-5,
     .col-md-1-5,
     .col-md-2-5,
     .col-md-3-5,
     .col-md-4-5,
     .col-md-5-5,
     .col-md-6-5,
     .col-md-7-5,
     .col-md-8-5,
     .col-md-9-5,
     .col-md-10-5,
     .col-md-11-5 {
       width: 100% !important;
       float: none;
     }
   }
   .col-md-0-5,
   .col-xs-0-5 {
     width: 4.166666665%;
   }
   .col-md-1-5,
   .col-xs-1-5 {
     width: 12.499999995%;
   }
   .col-md-2-5,
   .col-xs-2-5 {
     width: 20%;
   }
   .col-md-3-5,
   .col-xs-3-5 {
     width: 29.166666665%;
   }
   .col-md-4-5,
   .col-xs-4-5 {
     width: 37.499999995%;
   }
   .col-md-5-5,
   .col-xs-5-5 {
     width: 45.833333335%;
   }
   .col-md-6-5,
   .col-xs-6-5 {
     width: 54.166666665%;
   }
   .col-md-7-5,
   .col-xs-7-5 {
     width: 62.499999995%;
   }
   .col-md-8-5,
   .col-xs-8-5 {
     width: 70.833333335%;
   }
   .col-md-9-5,
   .col-xs-9-5 {
     width: 79.166666665%;
   }
   .col-md-10-5,
   .col-xs-10-5 {
     width: 87.499999995%;
   }
   .col-md-11-5,
   .col-xs-11-5 {
     width: 95.833333335%;
   }
   /*  --/HALF COULMN--  */
   /*  --OWL CAROUSEL--  */
   .owl-carousel {
     display: none;
     width: 100%;
     -webkit-tap-highlight-color: transparent;
     position: relative;
     z-index: 1;
   }
   .owl-carousel .owl-stage {
     position: relative;
     -ms-touch-action: pan-Y;
   }
   .owl-carousel .owl-stage:after {
     content: ".";
     display: block;
     clear: both;
     visibility: hidden;
     line-height: 0;
     height: 0;
   }
   .owl-centered .owl-stage {
    display: table !important;
  }
  .owl-centered .owl-item {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
  .owl-centered .owl-item > div {
    text-align: center;
  }
   .owl-carousel .owl-stage-outer {
     position: relative;
     overflow: hidden;
     -webkit-transform: translate3d(0px, 0px, 0px);
   }
   .owl-carousel .owl-item {
     position: relative;
     min-height: 1px;
     float: left;
     -webkit-backface-visibility: hidden;
     -webkit-tap-highlight-color: transparent;
     -webkit-touch-callout: none;
     -webkit-transform: translateY(0);
     -moz-transform: translateY(0);
     -o-transform: translateY(0);
     -ms-transform: translateY(0);
     transform: translateY(0);
   }
   .owl-carousel .owl-item img {
     display: block;
     width: 100%;
     -webkit-transform-style: preserve-3d;
   }
   .owl-carousel .owl-nav.disabled,
   .owl-carousel .owl-dots.disabled {
     display: none;
   }
   .owl-carousel .owl-nav .owl-prev,
   .owl-carousel .owl-nav .owl-next,
   .owl-carousel .owl-dot {
     cursor: hand;
     -webkit-user-select: none;
     -khtml-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
   }
   .owl-carousel.owl-loaded {
     display: block;
   }
   .owl-carousel.owl-loading {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     display: block;
   }
   .owl-carousel.owl-hidden {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .owl-carousel.owl-refresh .owl-item {
     display: none;
   }
   .owl-carousel.owl-drag .owl-item {
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
   }
   .owl-carousel.owl-grab {
     cursor: grab;
   }
   .owl-carousel.owl-rtl {
     direction: rtl;
   }
   .owl-carousel.owl-rtl .owl-item {
     float: right;
   }
   .no-js .owl-carousel {
     display: block;
   }
   .owl-carousel .animated {
     -webkit-animation-duration: 1000ms;
     -moz-animation-duration: 1000ms;
     -o-animation-duration: 1000ms;
     -ms-animation-duration: 1000ms;
     animation-duration: 1000ms;
     -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
     -o-animation-fill-mode: both;
     -ms-animation-fill-mode: both;
     animation-fill-mode: both;
   }
   .owl-carousel .owl-animated-in {
     z-index: 0;
   }
   .owl-carousel .owl-animated-out {
     z-index: 1;
   }
   .owl-carousel .fadeOut {
     -webkit-animation-name: fadeOut;
     -moz-animation-name: fadeOut;
     -o-animation-name: fadeOut;
     -ms-animation-name: fadeOut;
     animation-name: fadeOut;
   }
   .owl-height {
     -webkit-transition: height 500ms ease-in-out;
     -moz-transition: height 500ms ease-in-out;
     -o-transition: height 500ms ease-in-out;
     -ms-transition: height 500ms ease-in-out;
     transition: height 500ms ease-in-out;
   }
   .owl-carousel .owl-item .owl-lazy {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     -webkit-transition: opacity 400ms ease;
     -moz-transition: opacity 400ms ease;
     -o-transition: opacity 400ms ease;
     -ms-transition: opacity 400ms ease;
     transition: opacity 400ms ease;
   }
   .owl-carousel .owl-item img.owl-lazy {
     -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
     -o-transform-style: preserve-3d;
     -ms-transform-style: preserve-3d;
     transform-style: preserve-3d;
   }
   .owl-carousel .owl-video-wrapper {
     position: relative;
     height: 100%;
     background: #000;
   }
   .owl-carousel .owl-video-play-icon {
     position: absolute;
     height: 80px;
     width: 80px;
     left: 50%;
     top: 50%;
     margin-left: -40px;
     margin-top: -40px;
     /* background: url("owl.video.play.png") no-repeat; */
     cursor: pointer;
     z-index: 1;
     -webkit-backface-visibility: hidden;
     -webkit-transition: -webkit-transform 100ms ease;
     -moz-transition: -moz-transform 100ms ease;
     -o-transition: -o-transform 100ms ease;
     -ms-transition: -ms-transform 100ms ease;
     transition: transform 100ms ease;
   }
   .owl-carousel .owl-video-play-icon:hover {
     -ms-transform: scale(1.3, 1.3);
     -webkit-transform: scale(1.3, 1.3);
     -moz-transform: scale(1.3, 1.3);
     -o-transform: scale(1.3, 1.3);
     -ms-transform: scale(1.3, 1.3);
     transform: scale(1.3, 1.3);
   }
   .owl-carousel .owl-video-playing .owl-video-tn,
   .owl-carousel .owl-video-playing .owl-video-play-icon {
     display: none;
   }
   .owl-carousel .owl-video-tn {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     height: 100%;
     background-position: center center;
     background-repeat: no-repeat;
     -webkit-background-size: contain;
     -moz-background-size: contain;
     background-size: contain;
     -webkit-transition: opacity 400ms ease;
     -moz-transition: opacity 400ms ease;
     -o-transition: opacity 400ms ease;
     -ms-transition: opacity 400ms ease;
     transition: opacity 400ms ease;
   }
   .owl-carousel .owl-video-frame {
     position: relative;
     z-index: 1;
     height: 100%;
     width: 100%;
   }
   .owl-theme .owl-nav {
     margin-top: 10px;
     text-align: center;
     -webkit-tap-highlight-color: transparent;
   }
   .owl-theme .owl-nav [class*='owl-'] {
     color: #fff;
     font-size: 14px;
     margin: 5px;
     padding: 4px 7px;
     background: #d6d6d6;
     display: inline-block;
     cursor: pointer;
     border-radius: 3px;
   }
   .owl-theme .owl-nav [class*='owl-']:hover {
     background: #869791;
     color: #fff;
     text-decoration: none;
   }
   .owl-theme .owl-nav .disabled {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     cursor: default;
   }
   .owl-theme .owl-nav.disabled + .owl-dots {
     margin-top: 10px;
   }
   .owl-theme .owl-dots {
     text-align: center;
     -webkit-tap-highlight-color: transparent;
   }
   .owl-theme .owl-dots .owl-dot {
     display: inline-block;
     zoom: 1;
     *display: inline;
   }
   .owl-theme .owl-dots .owl-dot span {
     width: 10px;
     height: 10px;
     margin: 5px 7px;
     background: #d6d6d6;
     display: block;
     -webkit-backface-visibility: visible;
     -webkit-transition: opacity 200ms ease;
     -moz-transition: opacity 200ms ease;
     -o-transition: opacity 200ms ease;
     -ms-transition: opacity 200ms ease;
     transition: opacity 200ms ease;
     border-radius: 30px;
   }
   .owl-theme .owl-dots .owl-dot.active span,
   .owl-theme .owl-dots .owl-dot:hover span {
     background: #869791;
   }
   .owl-prev,
   .owl-next {
     position: absolute;
     top: 50%;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
     color: #fff;
     display: block;
     width: 50px;
     height: 50px;
     line-height: 50px;
     text-align: center;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     cursor: pointer;
     font-size: 20px;
   }
   .owl-prev:hover,
   .owl-next:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .owl-prev {
     left: 30px;
   }
   .owl-next {
     right: 30px;
   }
   .owl-carousel-nav-white .owl-prev,
   .owl-carousel-nav-white .owl-next {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     color: #fff !important;
   }
   .owl-dots {
     text-align: center;
     -webkit-tap-highlight-color: transparent;
     margin-top: 15px;
   }
   .owl-dots .owl-dot {
     display: inline-block;
     zoom: 1;
     *display: inline;
   }
   .owl-dots .owl-dot span {
     width: 10px;
     height: 10px;
     margin: 5px 7px;
     background: #d6d6d6;
     display: block;
     -webkit-backface-visibility: visible;
     -webkit-transition: opacity 200ms ease;
     -moz-transition: opacity 200ms ease;
     -o-transition: opacity 200ms ease;
     -ms-transition: opacity 200ms ease;
     transition: opacity 200ms ease;
     border-radius: 30px;
   }
   .owl-dots .owl-dot.active span,
   .owl-dots .owl-dot:hover span {
     background: #869791;
   }
   .owl-carousel-no-nav .owl-dots {
     display: none;
   }
   .owl-carousel-no-nav .owl-nav {
     display: none;
   }
   @-moz-keyframes fadeOut {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     100% {
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
   }
   @-webkit-keyframes fadeOut {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     100% {
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
   }
   @-o-keyframes fadeOut {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     100% {
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
   }
   @keyframes fadeOut {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     100% {
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
   }
   /*  --/OWL CAROUSEL--  */
   /*  --BANNER--  */
   .banner {
     position: relative;
     overflow: hidden;
   }
   .banner-sqr {
     width: 100%;
     padding-top: 100%;
   }
   .banner-w-25 {
     height: 25vh;
   }
   .banner-w-33 {
     height: 33vh;
   }
   .banner-w-50 {
     height: 50vh;
   }
   .banner-w-63 {
     height: 63vh;
   }
   .banner-w-75 {
     height: 75vh;
   }
   .banner-w-80 {
     height: 80vh;
   }
   .banner-w-100 {
     height: 100vh;
   }
   .banner-bg {
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     background-repeat: no-repeat;
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 1;
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
   }
   .banner-link {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 6;
   }
   .banner-mask {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 2;
     background: #000;
     opacity: 0.33;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
     filter: alpha(opacity=33);
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
   }
   .banner-mask-half {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .banner-mask-dark {
     opacity: 0.66;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
     filter: alpha(opacity=66);
   }
   .banner-mask-light {
     opacity: 0.15;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
     filter: alpha(opacity=15);
   }
   .banner-border {
     border: 1px solid #fff;
     margin: -1px 0 0 -1px;
   }
   .banner-img {
     width: 100%;
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
   }
   .banner-inner-shadow {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 3;
     -webkit-box-shadow: inset 0 0 20px rgba(0,0,0,0.5);
     box-shadow: inset 0 0 20px rgba(0,0,0,0.5);
   }
   .banner-inner-shadow-lg {
     -webkit-box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
     box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
   }
   .banner-title {
     font-size: 19px;
     font-weight: normal;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     margin: 0;
   }
   .banner-subtitle {
     font-size: 13px;
     opacity: 0.66;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
     filter: alpha(opacity=66);
     font-weight: bold;
     margin-bottom: 0;
     margin-top: 10px;
   }
   .banner-caption {
     position: relative;
     z-index: 5;
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
     color: #fff;
     padding: 30px;
   }
   .banner-caption-dark {
     color: #4d4d4d;
   }
   .banner-caption-top {
     position: absolute;
     top: 0;
     left: 0;
     bottom: auto;
     right: auto;
   }
   .banner-caption-bottom {
     position: absolute;
     top: auto;
     left: 0;
     bottom: 0;
     right: auto;
   }
   .banner-caption-center {
     position: absolute;
     top: 50%;
     left: 0;
     bottom: auto;
     right: auto;
     transfrom: translateY(-50%);
   }
   .banner-caption-inner {
     z-index: 2;
   }
   .banner-caption-grad {
     background: -webkit-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -moz-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -o-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -ms-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: linear-gradient(to bottom, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
   }
   .banner-caption-grad-white {
     background: -webkit-linear-gradient(top, rgba(255,255,255,0.01), rgba(255,255,255,0.9));
     background: -moz-linear-gradient(top, rgba(255,255,255,0.01), rgba(255,255,255,0.9));
     background: -o-linear-gradient(top, rgba(255,255,255,0.01), rgba(255,255,255,0.9));
     background: -ms-linear-gradient(top, rgba(255,255,255,0.01), rgba(255,255,255,0.9));
     background: linear-gradient(to bottom, rgba(255,255,255,0.01), rgba(255,255,255,0.9));
   }
   .banner-caption-vert-center {
     position: absolute;
     top: 50%;
     left: 50%;
     -webkit-transform: translate3d(-50%, -50%, 0);
     -moz-transform: translate3d(-50%, -50%, 0);
     -o-transform: translate3d(-50%, -50%, 0);
     -ms-transform: translate3d(-50%, -50%, 0);
     transform: translate3d(-50%, -50%, 0);
     width: 100%;
   }
   .banner-caption-top {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
   }
   .banner-caption-bottom {
     position: absolute;
     top: auto;
     left: 0;
     bottom: 0;
     width: 100%;
   }
   .banner-animate {
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .banner-animate-slow {
     -webkit-transition: 0.6s;
     -moz-transition: 0.6s;
     -o-transition: 0.6s;
     -ms-transition: 0.6s;
     transition: 0.6s;
   }
   .banner-animate-slow.banner-animate-mask-in .banner-mask,
   .banner-animate-slow.banner-animate-zoom-in .banner-bg {
     -webkit-transition: 0.6s;
     -moz-transition: 0.6s;
     -o-transition: 0.6s;
     -ms-transition: 0.6s;
     transition: 0.6s;
   }
   .banner-animate-very-slow {
     -webkit-transition: 1.2s;
     -moz-transition: 1.2s;
     -o-transition: 1.2s;
     -ms-transition: 1.2s;
     transition: 1.2s;
   }
   .banner-animate-very-slow.banner-animate-mask-in .banner-mask,
   .banner-animate-very-slow.banner-animate-zoom-in .banner-bg {
     -webkit-transition: 1.2s;
     -moz-transition: 1.2s;
     -o-transition: 1.2s;
     -ms-transition: 1.2s;
     transition: 1.2s;
   }
   .banner-animate-super-slow {
     -webkit-transition: 2.2s;
     -moz-transition: 2.2s;
     -o-transition: 2.2s;
     -ms-transition: 2.2s;
     transition: 2.2s;
   }
   .banner-animate-super-slow.banner-animate-mask-in .banner-mask,
   .banner-animate-super-slow.banner-animate-zoom-in .banner-bg {
     -webkit-transition: 2.2s;
     -moz-transition: 2.2s;
     -o-transition: 2.2s;
     -ms-transition: 2.2s;
     transition: 2.2s;
   }
   @media (min-width: 992px) {
     .banner-animate-mask-in .banner-mask {
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
     .banner-animate-mask-in:hover .banner-mask {
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     .banner-animate-mask-out .banner-mask {
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     .banner-animate-mask-out:hover .banner-mask {
       opacity: 0;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
       filter: alpha(opacity=0);
     }
     .banner-animate-zoom-in .banner-bg,
     .banner-animate-zoom-in .banner-img {
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
       -webkit-transform: translateZ(0) scale(1);
       -moz-transform: translateZ(0) scale(1);
       -o-transform: translateZ(0) scale(1);
       -ms-transform: translateZ(0) scale(1);
       transform: translateZ(0) scale(1);
     }
     .banner-animate-zoom-in:hover .banner-bg,
     .banner-animate-zoom-in:hover .banner-img {
       -webkit-transform: translateZ(0) scale(1.1);
       -moz-transform: translateZ(0) scale(1.1);
       -o-transform: translateZ(0) scale(1.1);
       -ms-transform: translateZ(0) scale(1.1);
       transform: translateZ(0) scale(1.1);
     }
     .banner-animate-zoom-out .banner-bg,
     .banner-animate-zoom-out .banner-img {
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
       -webkit-transform: translateZ(0) scale(1.1);
       -moz-transform: translateZ(0) scale(1.1);
       -o-transform: translateZ(0) scale(1.1);
       -ms-transform: translateZ(0) scale(1.1);
       transform: translateZ(0) scale(1.1);
     }
     .banner-animate-zoom-out:hover .banner-bg,
     .banner-animate-zoom-out:hover .banner-img {
       -webkit-transform: translateZ(0) scale(1);
       -moz-transform: translateZ(0) scale(1);
       -o-transform: translateZ(0) scale(1);
       -ms-transform: translateZ(0) scale(1);
       transform: translateZ(0) scale(1);
     }
     .banner-animate-blur-in .banner-bg,
     .banner-animate-blur-in .banner-img {
       filter: blur(0);
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
     }
     .banner-animate-blur-in:hover .banner-bg,
     .banner-animate-blur-in:hover .banner-img {
       filter: blur(5px);
     }
     .banner-animate-blur-out .banner-bg {
       filter: blur(5px);
       -webkit-transition: 0.3s;
       -moz-transition: 0.3s;
       -o-transition: 0.3s;
       -ms-transition: 0.3s;
       transition: 0.3s;
     }
     .banner-animate-blur-out:hover .banner-bg {
       filter: blur(0);
     }
     .banner-animate-bsh:hover {
       -webkit-box-shadow: 0 5px 20px rgba(0,0,0,0.6);
       box-shadow: 0 5px 20px rgba(0,0,0,0.6);
     }
   }
   /*  --/BANNER--  */
   /*  --FEATURES--  */
   .feature-icon {
     display: block;
     line-height: 1em;
     font-size: 23px;
     margin-bottom: 20px;
   }
   .feature-icon-box {
     height: 60px;
     width: 60px;
     line-height: 60px;
     margin-bottom: 30px;
     text-align: center;
   }
   .feature-icon-round {
     border-radius: 50%;
   }
   .feature-icon-left {
     float: left;
     margin-right: 15px;
     margin-bottom: 0;
   }
   .feature-icon-lg {
     font-size: 30px;
   }
   .feature-icon-xl {
     font-size: 45px;
   }
   .feature-icon-sm.feature-icon-box {
     height: 40px;
     width: 40px;
     line-height: 40px;
     font-size: 16px;
     margin-bottom: 20px;
   }
   .feature-icon-xs.feature-icon-box {
     height: 30px;
     width: 30px;
     line-height: 30px;
     font-size: 14px;
     margin-bottom: 20px;
   }
   .feature-icon-primary {
     color: #0093d2;
   }
   .feature-icon-primary.feature-icon-box {
     color: #fff;
     background: #0093d2;
   }
   .feature-icon-primary-inverse {
     color: #ff6c2d;
   }
   .feature-icon-primary-inverse.feature-icon-box {
     color: #fff;
     background: #ff6c2d;
   }
   .feature-icon-gray.feature-icon-box {
     color: #4c4c4c;
     background: #ededed;
   }
   .feature-icon-white {
     color: #fff;
   }
   .feature-icon-white.feature-icon-box {
     color: #4c4c4c;
     background: #fff;
   }
   .feature-icon-line {
     background: none;
     border: 1px solid #595959;
     background: none !important;
   }
   .feature-icon-line.feature-icon-primary {
     border-color: #0093d2;
     color: #0093d2;
   }
   .feature-icon-line.feature-icon-white {
     border-color: #fff;
     color: #fff;
   }
   .feature-icon-line.feature-icon-primary-inverse {
     border-color: #ff6c2d;
     color: #ff6c2d;
   }
   .feature-caption {
     display: table;
   }
   .feature-title {
     margin-bottom: 15px;
     margin-top: 0;
     font-weight: normal;
     line-height: 1em;
     font-size: 18px;
   }
   .feature-subtitle {
     margin-bottom: 0;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     font-size: 14px;
     line-height: 1.6em;
   }
   .feature-center {
     text-align: center;
   }
   .feature-center .feature-caption {
     display: block;
   }
   .feature-center .feature-icon {
     margin-left: auto;
     margin-right: auto;
   }
   .feature-wrap-fade-white {
     padding: 35px 20px;
     background: rgba(255,255,255,0.2);
   }
   /*  --/FEATURES--  */
   /*  --BLUR AREA--  */
   .blur-area {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     background-repeat: no-repeat;
     z-index: 1;
   }
   @media (max-width: 992px) {
     .blur-area {
       display: none;
     }
   }
   /*  --BLUR AREA--  */
   /*  --ICHECK--  */
   .icheck-label {
     padding: 0 !important;
     display: block;
   }
   .icheck,
   .iradio {
     float: left;
     display: inline-block;
     width: 12px;
     height: 12px;
     border: none;
     cursor: pointer;
     background: none;
     border: 1px solid #8c8c8c;
     margin-right: 7px;
     overflow: hidden;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .icheck:before,
   .iradio:before {
     position: absolute;
     top: 0;
     left: 0;
     content: '\f00c';
     font-family: 'FontAwesome';
     font-size: 7px;
     width: 100%;
     height: 12px;
     line-height: 10px;
     text-align: center;
     display: block;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     color: #fff;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .icheck.hover:after {
     background: #0093d2;
   }
   .icheck.checked {
     background: #0093d2;
     border-color: #0093d2;
   }
   .icheck.checked:before {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .icheck.checked:after {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .icheck.disabled {
     background: #808080;
   }
   .icheck-title {
     display: table;
     margin-bottom: 0;
   }
   .icheck-sub-title {
     margin-bottom: 0;
     margin-top: 1px;
     font-size: 10px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     display: block;
   }
   /*  --/ICHECK--  */
   /*  --RANGE SLIDER--  */
   .irs {
     position: relative;
     display: block;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     height: 55px;
   }
   .irs-line {
     position: relative;
     display: block;
     overflow: hidden;
     outline: none !important;
     height: 10px;
     top: 33px;
     background: #eee;
     border-radius: 16px;
     border: 1px solid #ccc;
   }
   .irs-line-left,
   .irs-line-mid,
   .irs-line-right {
     position: absolute;
     top: 0;
     display: block;
     height: 8px;
   }
   .irs-line-left {
     left: 0;
     width: 11%;
   }
   .irs-line-mid {
     left: 9%;
     width: 82%;
   }
   .irs-line-right {
     right: 0;
     width: 11%;
   }
   .irs-bar {
     position: absolute;
     display: block;
     left: 0;
     width: 0;
     height: 10px;
     top: 33px;
     border-top: 1px solid #0093d2;
     border-bottom: 1px solid #0093d2;
     background: #0093d2;
   }
   .irs-bar-edge {
     position: absolute;
     display: block;
     top: 0;
     left: 0;
     height: 10px;
     top: 33px;
     border: 1px solid #0093d2;
     width: 14px;
     border-right: 0;
     background: #0093d2;
     border-radius: 16px 0 0 16px;
   }
   .irs-shadow {
     position: absolute;
     display: none;
     left: 0;
     width: 0;
     height: 2px;
     top: 38px;
     background: #000;
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
     border-radius: 5px;
   }
   .irs-slider {
     position: absolute;
     display: block;
     cursor: default;
     z-index: 1;
     top: 25px;
     width: 27px;
     height: 27px;
     border: 1px solid #aaa;
     background: #ddd;
     border-radius: 27px;
     cursor: pointer;
   }
   .irs-slider.type_last {
     z-index: 2;
   }
   .irs-min,
   .irs-max {
     color: #333;
     position: absolute;
     display: block;
     cursor: default;
     font-size: 12px;
     line-height: 1.333;
     top: 0;
     padding: 1px 5px;
     background: rgba(0,0,0,0.1);
     border-radius: 3px;
   }
   .irs-min {
     left: 0;
   }
   .irs-max {
     right: 0;
   }
   .irs-from,
   .irs-to,
   .irs-single {
     position: absolute;
     display: block;
     top: 0;
     left: 0;
     cursor: default;
     white-space: nowrap;
     color: #fff;
     font-size: 14px;
     line-height: 1.333;
     padding: 1px 5px;
     background: #0093d2;
     border-radius: 3px;
   }
   .irs-grid {
     position: absolute;
     display: none;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 20%;
     height: 27px;
   }
   .irs-widh-grid .irs-grid {
     display: block;
     height: 75px;
   }
   .irs-grid-pol {
     position: absolute;
     top: 0;
     left: 0;
     width: 1px;
     height: 8px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     background: #0093d2;
   }
   .irs-grid-pol.small {
     height: 4px;
     background: #999;
   }
   .irs-grid-text {
     position: absolute;
     bottom: 0;
     left: 0;
     white-space: nowrap;
     text-align: center;
     font-size: 9px;
     line-height: 9px;
     padding: 0 3px;
     color: #000;
     color: #0093d2;
     bottom: 5px;
   }
   .irs-disable-mask {
     position: absolute;
     display: block;
     top: 0;
     left: -1%;
     width: 102%;
     height: 100%;
     cursor: default;
     background: #000;
     z-index: 2;
   }
   .lt-ie9 .irs-disable-mask {
     background: #000;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     cursor: not-allowed;
   }
   .irs-disbled {
     opacity: 0.4;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
     filter: alpha(opacity=40);
   }
   .irs-hidden-input {
     position: absolute !important;
     display: block !important;
     top: 0 !important;
     left: 0 !important;
     width: 0 !important;
     height: 0 !important;
     font-size: 0 !important;
     line-height: 0 !important;
     padding: 0 !important;
     margin: 0 !important;
     overflow: hidden;
     outline: none !important;
     z-index: -9999 !important;
     background: none !important;
     border-style: solid !important;
     border-color: transparent !important;
   }
   /*  --/RANGE SLIDER--  */
   /*  --FOTORAMA IMAGE SLIDER--  */
   .fotorama__arr:focus:after,
   .fotorama__fullscreen-icon:focus:after,
   .fotorama__html,
   .fotorama__img,
   .fotorama__nav__frame:focus .fotorama__dot:after,
   .fotorama__nav__frame:focus .fotorama__thumb:after,
   .fotorama__stage__frame,
   .fotorama__stage__shaft,
   .fotorama__video iframe {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     right: 0;
     left: 0;
     bottom: 0;
   }
   .fotorama--fullscreen,
   .fotorama__img {
     max-width: 99999px !important;
     max-height: 99999px !important;
     min-width: 0 !important;
     min-height: 0 !important;
     border-radius: 0 !important;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     padding: 0 !important;
   }
   .fotorama__wrap .fotorama__grab {
     cursor: move;
     cursor: -webkit-grab;
     cursor: -o-grab;
     cursor: -ms-grab;
     cursor: grab;
   }
   .fotorama__grabbing * {
     cursor: move;
     cursor: -webkit-grabbing;
     cursor: -o-grabbing;
     cursor: -ms-grabbing;
     cursor: grabbing;
   }
   .fotorama__spinner {
     position: absolute !important;
     top: 50% !important;
     left: 50% !important;
   }
   .fotorama__wrap--css3 .fotorama__arr,
   .fotorama__wrap--css3 .fotorama__fullscreen-icon,
   .fotorama__wrap--css3 .fotorama__nav__shaft,
   .fotorama__wrap--css3 .fotorama__stage__shaft,
   .fotorama__wrap--css3 .fotorama__thumb-border,
   .fotorama__wrap--css3 .fotorama__video-close,
   .fotorama__wrap--css3 .fotorama__video-play {
     -webkit-transform: translate3d(0, 0, 0);
     -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
     -ms-transform: translate3d(0, 0, 0);
     transform: translate3d(0, 0, 0);
   }
   .fotorama__caption,
   .fotorama__nav:after,
   .fotorama__nav:before,
   .fotorama__stage:after,
   .fotorama__stage:before,
   .fotorama__wrap--css3 .fotorama__html,
   .fotorama__wrap--css3 .fotorama__nav,
   .fotorama__wrap--css3 .fotorama__spinner,
   .fotorama__wrap--css3 .fotorama__stage,
   .fotorama__wrap--css3 .fotorama__stage .fotorama__img,
   .fotorama__wrap--css3 .fotorama__stage__frame {
     -webkit-transform: translateZ(0);
     -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
     -o-transform: translateZ(0);
     -ms-transform: translateZ(0);
     transform: translateZ(0);
   }
   .fotorama__arr:focus,
   .fotorama__fullscreen-icon:focus,
   .fotorama__nav__frame {
     outline: 0;
   }
   .fotorama__arr:focus:after,
   .fotorama__fullscreen-icon:focus:after,
   .fotorama__nav__frame:focus .fotorama__dot:after,
   .fotorama__nav__frame:focus .fotorama__thumb:after {
     content: '';
     background-color: rgba(0,175,234,0.5);
   }
   .fotorama__wrap--video .fotorama__stage,
   .fotorama__wrap--video .fotorama__stage__frame--video,
   .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html,
   .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
   .fotorama__wrap--video .fotorama__stage__shaft {
     -webkit-transform: none !important;
     -webkit-transform: none !important;
     -moz-transform: none !important;
     -o-transform: none !important;
     -ms-transform: none !important;
     transform: none !important;
   }
   .fotorama__wrap--css3 .fotorama__nav__shaft,
   .fotorama__wrap--css3 .fotorama__stage__shaft,
   .fotorama__wrap--css3 .fotorama__thumb-border {
     -webkit-transition-property: -webkit--webkit-transform, width;
     -moz-transition-property: -webkit--moz-transform, width;
     -o-transition-property: -webkit--o-transform, width;
     -ms-transition-property: -webkit--ms-transform, width;
     transition-property: -webkit-transform, width;
     -webkit-transition-property: -webkit-transform, width;
     -moz-transition-property: -moz-transform, width;
     -o-transition-property: -o-transform, width;
     -ms-transition-property: -ms-transform, width;
     transition-property: transform, width;
     -webkit-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     -moz-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     -o-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     -ms-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
     -webkit-transition-duration: 0ms;
     -moz-transition-duration: 0ms;
     -o-transition-duration: 0ms;
     -ms-transition-duration: 0ms;
     transition-duration: 0ms;
   }
   .fotorama__arr,
   .fotorama__fullscreen-icon,
   .fotorama__no-select,
   .fotorama__video-close,
   .fotorama__video-play,
   .fotorama__wrap {
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
   }
   .fotorama__select {
     -webkit-user-select: text;
     -moz-user-select: text;
     -ms-user-select: text;
     -webkit-user-select: text;
     -moz-user-select: text;
     -ms-user-select: text;
     user-select: text;
   }
   .fotorama__nav,
   .fotorama__nav__frame {
     margin: auto;
     padding: 0;
   }
   .fotorama__caption__wrap,
   .fotorama__nav__frame,
   .fotorama__nav__shaft {
     -moz-box-orient: vertical;
     display: inline-block;
     vertical-align: middle;
     *display: inline;
     *zoom: 1;
   }
   .fotorama__nav__frame,
   .fotorama__thumb-border {
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box;
   }
   .fotorama__caption__wrap {
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
   }
   .fotorama--hidden,
   .fotorama__load {
     position: absolute;
     left: -99999px;
     top: -99999px;
     z-index: -1;
   }
   .fotorama__arr,
   .fotorama__fullscreen-icon,
   .fotorama__nav,
   .fotorama__nav__frame,
   .fotorama__nav__shaft,
   .fotorama__stage__frame,
   .fotorama__stage__shaft,
   .fotorama__video-close,
   .fotorama__video-play {
     -webkit-tap-highlight-color: transparent;
   }
   .fotorama__arr,
   .fotorama__fullscreen-icon,
   .fotorama__video-close,
   .fotorama__video-play {
     color: #fff;
     text-align: center;
   }
   .fotorama__arr:before,
   .fotorama__fullscreen-icon:before,
   .fotorama__video-close:before,
   .fotorama__video-play:before {
     font-family: 'FontAwesome';
   }
   @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 2dppx) {
     .fotorama__arr,
     .fotorama__fullscreen-icon,
     .fotorama__video-close,
     .fotorama__video-play {
       /* background: url("fotorama@2x.png") 0 0/96px 160px no-repeat; */
     }
   }
   .fotorama__thumb {
     background-color: #7f7f7f;
     background-color: rgba(127,127,127,0.2);
   }
   @media print {
     .fotorama__arr,
     .fotorama__fullscreen-icon,
     .fotorama__thumb-border,
     .fotorama__video-close,
     .fotorama__video-play {
       background: none !important;
     }
   }
   .fotorama {
     min-width: 1px;
     overflow: hidden;
   }
   .fotorama:not(.fotorama--unobtrusive)>*:not(:first-child) {
     display: none;
   }
   .fullscreen {
     width: 100% !important;
     height: 100% !important;
     max-width: 100% !important;
     max-height: 100% !important;
     margin: 0 !important;
     padding: 0 !important;
     overflow: hidden !important;
     background: #000;
   }
   .fotorama--fullscreen {
     position: absolute !important;
     top: 0 !important;
     left: 0 !important;
     right: 0 !important;
     bottom: 0 !important;
     float: none !important;
     z-index: 2147483647 !important;
     background: #000;
     width: 100% !important;
     height: 100% !important;
     margin: 0 !important;
   }
   .fotorama--fullscreen .fotorama__nav,
   .fotorama--fullscreen .fotorama__stage {
     background: #000;
   }
   .fotorama__wrap {
     -webkit-text-size-adjust: 100%;
     position: relative;
     direction: ltr;
     z-index: 0;
   }
   .fotorama__wrap--rtl .fotorama__stage__frame {
     direction: rtl;
   }
   .fotorama__nav,
   .fotorama__stage {
     overflow: hidden;
     position: relative;
     max-width: 100%;
   }
   .fotorama__wrap--pan-y {
     -ms-touch-action: pan-y;
   }
   .fotorama__wrap .fotorama__pointer {
     cursor: pointer;
   }
   .fotorama__wrap--slide .fotorama__stage__frame {
     opacity: 1 !important;
     -ms-filter: none !important;
     filter: none !important;
   }
   .fotorama__stage__frame {
     overflow: hidden;
   }
   .fotorama__stage__frame.fotorama__active {
     z-index: 8;
   }
   .fotorama__wrap--fade .fotorama__stage__frame {
     display: none;
   }
   .fotorama__wrap--fade .fotorama__fade-front,
   .fotorama__wrap--fade .fotorama__fade-rear,
   .fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
     display: block;
     left: 0;
     top: 0;
   }
   .fotorama__wrap--fade .fotorama__fade-front {
     z-index: 8;
   }
   .fotorama__wrap--fade .fotorama__fade-rear {
     z-index: 7;
   }
   .fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
     z-index: 9;
   }
   .fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
     display: none;
   }
   .fotorama__img {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     border: none !important;
   }
   .fotorama__error .fotorama__img,
   .fotorama__loaded .fotorama__img {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .fotorama--fullscreen .fotorama__loaded--full .fotorama__img,
   .fotorama__img--full {
     display: none;
   }
   .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
     display: block;
   }
   .fotorama__wrap--only-active .fotorama__nav,
   .fotorama__wrap--only-active .fotorama__stage {
     max-width: 99999px !important;
   }
   .fotorama__wrap--only-active .fotorama__stage__frame {
     visibility: hidden;
   }
   .fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
     visibility: visible;
   }
   .fotorama__nav {
     font-size: 0;
     line-height: 0;
     text-align: center;
     display: none;
     white-space: nowrap;
     z-index: 5;
   }
   .fotorama__nav__shaft {
     position: relative;
     left: 0;
     top: 0;
     text-align: left;
   }
   .fotorama__nav__frame {
     position: relative;
     cursor: pointer;
   }
   .fotorama__nav--dots {
     display: block;
   }
   .fotorama__nav--dots .fotorama__nav__frame {
     width: 18px;
     height: 30px;
   }
   .fotorama__nav--dots .fotorama__nav__frame--thumb,
   .fotorama__nav--dots .fotorama__thumb-border {
     display: none;
   }
   .fotorama__nav--thumbs {
     display: block;
   }
   .fotorama__nav--thumbs .fotorama__nav__frame {
     padding-left: 0 !important;
   }
   .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
     padding-right: 0 !important;
   }
   .fotorama__nav--thumbs .fotorama__nav__frame--dot {
     display: none;
   }
   .fotorama__dot {
     display: block;
     width: 4px;
     height: 4px;
     position: relative;
     top: 12px;
     left: 6px;
     border-radius: 6px;
     border: 1px solid #7f7f7f;
   }
   .fotorama__nav__frame:focus .fotorama__dot:after {
     padding: 1px;
     top: -1px;
     left: -1px;
   }
   .fotorama__nav__frame.fotorama__active .fotorama__dot {
     width: 0;
     height: 0;
     border-width: 3px;
   }
   .fotorama__nav__frame.fotorama__active .fotorama__dot:after {
     padding: 3px;
     top: -3px;
     left: -3px;
   }
   .fotorama__thumb {
     overflow: hidden;
     position: relative;
     width: 100%;
     height: 100%;
   }
   .fotorama__nav__frame:focus .fotorama__thumb {
     z-index: 2;
   }
   .fotorama__thumb-border {
     position: absolute;
     z-index: 9;
     top: 0;
     left: 0;
     border-style: solid;
     border-color: #0093d2;
     background-image: -webkit-linear-gradient(top left, rgba(255,255,255,0.25), rgba(64,64,64,0.1));
     background-image: -moz-linear-gradient(top left, rgba(255,255,255,0.25), rgba(64,64,64,0.1));
     background-image: -o-linear-gradient(top left, rgba(255,255,255,0.25), rgba(64,64,64,0.1));
     background-image: -ms-linear-gradient(top left, rgba(255,255,255,0.25), rgba(64,64,64,0.1));
     background-image: linear-gradient(to bottom right, rgba(255,255,255,0.25), rgba(64,64,64,0.1));
   }
   .fotorama__caption {
     position: absolute;
     z-index: 12;
     bottom: 0;
     left: 0;
     right: 0;
     font-family: 'Helvetica Neue', Arial, sans-serif;
     font-size: 14px;
     line-height: 1.5;
     color: #000;
   }
   .fotorama__caption a {
     text-decoration: none;
     color: #000;
     border-bottom: 1px solid;
     border-color: rgba(0,0,0,0.5);
   }
   .fotorama__caption a:hover {
     color: #333;
     border-color: rgba(51,51,51,0.5);
   }
   .fotorama__wrap--rtl .fotorama__caption {
     left: auto;
     right: 0;
   }
   .fotorama__wrap--no-captions .fotorama__caption,
   .fotorama__wrap--video .fotorama__caption {
     display: none;
   }
   .fotorama__caption__wrap {
     background-color: #fff;
     background-color: rgba(255,255,255,0.9);
     padding: 5px 10px;
   }
   .fotorama__wrap--css3 .fotorama__spinner {
     -webkit-animation: spinner 24s infinite linear;
     -webkit-animation: spinner 24s infinite linear;
     -moz-animation: spinner 24s infinite linear;
     -o-animation: spinner 24s infinite linear;
     -ms-animation: spinner 24s infinite linear;
     animation: spinner 24s infinite linear;
   }
   .fotorama__wrap--css3 .fotorama__html,
   .fotorama__wrap--css3 .fotorama__stage .fotorama__img {
     -webkit-transition-property: opacity;
     -moz-transition-property: opacity;
     -o-transition-property: opacity;
     -ms-transition-property: opacity;
     transition-property: opacity;
     -webkit-transition-timing-function: linear;
     -moz-transition-timing-function: linear;
     -o-transition-timing-function: linear;
     -ms-transition-timing-function: linear;
     transition-timing-function: linear;
     -webkit-transition-duration: 0.3s;
     -moz-transition-duration: 0.3s;
     -o-transition-duration: 0.3s;
     -ms-transition-duration: 0.3s;
     transition-duration: 0.3s;
   }
   .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html,
   .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .fotorama__select {
     cursor: auto;
   }
   .fotorama__video {
     top: 32px;
     right: 0;
     bottom: 0;
     left: 0;
     position: absolute;
     z-index: 10;
   }
   .fotorama__arr,
   .fotorama__fullscreen-icon,
   .fotorama__video-close,
   .fotorama__video-play {
     position: absolute;
     z-index: 11;
     cursor: pointer;
   }
   .fotorama__arr {
     position: absolute;
     width: 32px;
     height: 32px;
     line-height: 32px;
     top: 50%;
     margin-top: -16px;
   }
   .fotorama__arr--prev {
     left: 2px;
   }
   .fotorama__arr--prev:before {
     content: '\f053';
   }
   .fotorama__arr--next {
     right: 2px;
   }
   .fotorama__arr--next:before {
     content: '\f054';
   }
   .fotorama__arr--disabled {
     pointer-events: none;
     cursor: default;
     *display: none;
     opacity: 0.1;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
     filter: alpha(opacity=10);
   }
   .fotorama__fullscreen-icon {
     width: 32px;
     height: 32px;
     line-height: 32px;
     top: 2px;
     right: 2px;
     z-index: 20;
   }
   .fotorama__fullscreen-icon:before {
     content: '\f065';
   }
   .fotorama__arr:focus,
   .fotorama__fullscreen-icon:focus {
     border-radius: 50%;
   }
   .fotorama--fullscreen .fotorama__fullscreen-icon {
     background-position: -32px -32px;
   }
   .fotorama__video-play {
     width: 96px;
     height: 96px;
     left: 50%;
     top: 50%;
     margin-left: -48px;
     margin-top: -48px;
     background-position: 0 -64px;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .fotorama__wrap--css2 .fotorama__video-play,
   .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
     display: none;
   }
   .fotorama__error .fotorama__video-play,
   .fotorama__loaded .fotorama__video-play,
   .fotorama__nav__frame .fotorama__video-play {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     display: block;
   }
   .fotorama__nav__frame .fotorama__video-play {
     width: 32px;
     height: 32px;
     margin-left: -16px;
     margin-top: -16px;
     background-position: -64px -32px;
   }
   .fotorama__video-close {
     width: 32px;
     height: 32px;
     top: 0;
     right: 0;
     background-position: -64px 0;
     z-index: 20;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .fotorama__wrap--css2 .fotorama__video-close {
     display: none;
   }
   .fotorama__wrap--css3 .fotorama__video-close {
     -webkit-transform: translate3d(32px, -32px, 0);
     -webkit-transform: translate3d(32px, -32px, 0);
     -moz-transform: translate3d(32px, -32px, 0);
     -o-transform: translate3d(32px, -32px, 0);
     -ms-transform: translate3d(32px, -32px, 0);
     transform: translate3d(32px, -32px, 0);
   }
   .fotorama__wrap--video .fotorama__video-close {
     display: block;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .fotorama__wrap--css3.fotorama__wrap--video .fotorama__video-close {
     -webkit-transform: translate3d(0, 0, 0);
     -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
     -o-transform: translate3d(0, 0, 0);
     -ms-transform: translate3d(0, 0, 0);
     transform: translate3d(0, 0, 0);
   }
   .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
   .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
   .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .fotorama__wrap--video .fotorama__arr,
   .fotorama__wrap--video .fotorama__fullscreen-icon {
     opacity: 0 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
     filter: alpha(opacity=0) !important;
   }
   .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
   .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
     display: none;
   }
   .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
   .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
     display: block;
   }
   .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr,
   .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
     display: none !important;
   }
   .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
     -webkit-transform: translate3d(32px, -32px, 0);
     -webkit-transform: translate3d(32px, -32px, 0);
     -moz-transform: translate3d(32px, -32px, 0);
     -o-transform: translate3d(32px, -32px, 0);
     -ms-transform: translate3d(32px, -32px, 0);
     transform: translate3d(32px, -32px, 0);
   }
   .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
     -webkit-transform: translate3d(-48px, 0, 0);
     -webkit-transform: translate3d(-48px, 0, 0);
     -moz-transform: translate3d(-48px, 0, 0);
     -o-transform: translate3d(-48px, 0, 0);
     -ms-transform: translate3d(-48px, 0, 0);
     transform: translate3d(-48px, 0, 0);
   }
   .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
     -webkit-transform: translate3d(48px, 0, 0);
     -webkit-transform: translate3d(48px, 0, 0);
     -moz-transform: translate3d(48px, 0, 0);
     -o-transform: translate3d(48px, 0, 0);
     -ms-transform: translate3d(48px, 0, 0);
     transform: translate3d(48px, 0, 0);
   }
   .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
     -webkit-transform: translate3d(32px, -32px, 0) !important;
     -webkit-transform: translate3d(32px, -32px, 0) !important;
     -moz-transform: translate3d(32px, -32px, 0) !important;
     -o-transform: translate3d(32px, -32px, 0) !important;
     -ms-transform: translate3d(32px, -32px, 0) !important;
     transform: translate3d(32px, -32px, 0) !important;
   }
   .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
     -webkit-transform: translate3d(-48px, 0, 0) !important;
     -webkit-transform: translate3d(-48px, 0, 0) !important;
     -moz-transform: translate3d(-48px, 0, 0) !important;
     -o-transform: translate3d(-48px, 0, 0) !important;
     -ms-transform: translate3d(-48px, 0, 0) !important;
     transform: translate3d(-48px, 0, 0) !important;
   }
   .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
     -webkit-transform: translate3d(48px, 0, 0) !important;
     -webkit-transform: translate3d(48px, 0, 0) !important;
     -moz-transform: translate3d(48px, 0, 0) !important;
     -o-transform: translate3d(48px, 0, 0) !important;
     -ms-transform: translate3d(48px, 0, 0) !important;
     transform: translate3d(48px, 0, 0) !important;
   }
   .fotorama__wrap--css3 .fotorama__arr:not(:focus),
   .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus),
   .fotorama__wrap--css3 .fotorama__video-close:not(:focus),
   .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
     -webkit-transition-property: -webkit--webkit-transform, opacity;
     -moz-transition-property: -webkit--moz-transform, opacity;
     -o-transition-property: -webkit--o-transform, opacity;
     -ms-transition-property: -webkit--ms-transform, opacity;
     transition-property: -webkit-transform, opacity;
     -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property: -moz-transform, opacity;
     -o-transition-property: -o-transform, opacity;
     -ms-transition-property: -ms-transform, opacity;
     transition-property: transform, opacity;
     -webkit-transition-duration: 0.3s;
     -moz-transition-duration: 0.3s;
     -o-transition-duration: 0.3s;
     -ms-transition-duration: 0.3s;
     transition-duration: 0.3s;
   }
   .fotorama__nav:after,
   .fotorama__nav:before,
   .fotorama__stage:after,
   .fotorama__stage:before {
     content: "";
     display: block;
     position: absolute;
     text-decoration: none;
     top: 0;
     bottom: 0;
     width: 10px;
     height: auto;
     z-index: 10;
     pointer-events: none;
     background-repeat: no-repeat;
     -webkit-background-size: 1px 100%, 5px 100%;
     -moz-background-size: 1px 100%, 5px 100%;
     background-size: 1px 100%, 5px 100%;
   }
   .fotorama__nav:before,
   .fotorama__stage:before {
     background-image: -webkit-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -webkit-radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), transparent);
     background-image: -moz-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -moz-radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), transparent);
     background-image: -o-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -o-radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), transparent);
     background-image: -ms-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -ms-radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), transparent);
     background-image: linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), transparent);
     background-position: 0 0, 0 0;
     left: -10px;
   }
   .fotorama__nav.fotorama__shadows--left:before,
   .fotorama__stage.fotorama__shadows--left:before {
     left: 0;
   }
   .fotorama__nav:after,
   .fotorama__stage:after {
     background-image: -webkit-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -webkit-radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), transparent);
     background-image: -moz-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -moz-radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), transparent);
     background-image: -o-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -o-radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), transparent);
     background-image: -ms-linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), -ms-radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), transparent);
     background-image: linear-gradient(transparent, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), transparent);
     background-position: 100% 0, 100% 0;
     right: -10px;
   }
   .fotorama__nav.fotorama__shadows--right:after,
   .fotorama__stage.fotorama__shadows--right:after {
     right: 0;
   }
   .fotorama--fullscreen .fotorama__nav:after,
   .fotorama--fullscreen .fotorama__nav:before,
   .fotorama--fullscreen .fotorama__stage:after,
   .fotorama--fullscreen .fotorama__stage:before,
   .fotorama__wrap--fade .fotorama__stage:after,
   .fotorama__wrap--fade .fotorama__stage:before,
   .fotorama__wrap--no-shadows .fotorama__nav:after,
   .fotorama__wrap--no-shadows .fotorama__nav:before,
   .fotorama__wrap--no-shadows .fotorama__stage:after,
   .fotorama__wrap--no-shadows .fotorama__stage:before {
     display: none;
   }
   @-moz-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @-webkit-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @-o-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @-moz-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @-webkit-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @-o-keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @keyframes spinner {
     0% {
       -webkit-transform: rotate(0);
       -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
       -o-transform: rotate(0);
       -ms-transform: rotate(0);
       transform: rotate(0);
     }
     100% {
       -webkit-transform: rotate(360deg);
       -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
       -o-transform: rotate(360deg);
       -ms-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   /*  --/FOTORAMA IMAGE SLIDER--  */
   /*  --DATEPICKER--  */
   .bootstrap-datetimepicker-widget {
     list-style: none;
     color: #000;
   }
   .bootstrap-datetimepicker-widget.dropdown-menu {
     display: block;
     margin: 5px 0;
     padding: 12px 15px;
     width: 19em;
     border-radius: 0;
     border: none;
   }
   .bootstrap-datetimepicker-widget.dropdown-menu:before,
   .bootstrap-datetimepicker-widget.dropdown-menu:after {
     content: '';
     display: inline-block;
     position: absolute;
   }
   .bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
     left: auto;
     right: 6px;
   }
   .bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
     left: auto;
     right: 7px;
   }
   .bootstrap-datetimepicker-widget .list-unstyled {
     margin: 0;
   }
   .bootstrap-datetimepicker-widget a[data-action] {
     padding: 6px 0;
   }
   .bootstrap-datetimepicker-widget a[data-action]:active {
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   .bootstrap-datetimepicker-widget .timepicker-hour,
   .bootstrap-datetimepicker-widget .timepicker-minute,
   .bootstrap-datetimepicker-widget .timepicker-second {
     width: 54px;
     font-weight: bold;
     font-size: 1.2em;
     margin: 0;
   }
   .bootstrap-datetimepicker-widget button[data-action] {
     padding: 6px;
   }
   .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
   .bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
     position: absolute;
     width: 1px;
     height: 1px;
     margin: -1px;
     padding: 0;
     overflow: hidden;
     clip: rect(0, 0, 0, 0);
     border: 0;
   }
   .bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
     content: 'Increment Hours';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
     content: 'Increment Minutes';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
     content: 'Decrement Hours';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
     content: 'Decrement Minutes';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
     content: 'Show Hours';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
     content: 'Show Minutes';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
     content: 'Toggle AM/PM';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
     content: 'Clear the picker';
   }
   .bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
     content: 'Set the date to today';
   }
   .bootstrap-datetimepicker-widget .picker-switch {
     text-align: center;
   }
   .bootstrap-datetimepicker-widget .picker-switch::after {
     content: 'Toggle Date and Time Screens';
     position: absolute;
     width: 1px;
     height: 1px;
     margin: -1px;
     padding: 0;
     overflow: hidden;
     clip: rect(0, 0, 0, 0);
     border: 0;
   }
   .bootstrap-datetimepicker-widget .picker-switch td {
     padding: 0;
     margin: 0;
     height: auto;
     width: auto;
     line-height: inherit;
   }
   .bootstrap-datetimepicker-widget .picker-switch td span {
     line-height: 2.5;
     height: 2.5em;
     width: 100%;
   }
   .bootstrap-datetimepicker-widget table {
     width: 100%;
     margin: 0;
   }
   .bootstrap-datetimepicker-widget table td,
   .bootstrap-datetimepicker-widget table th {
     text-align: center;
     border-radius: 4px;
   }
   .bootstrap-datetimepicker-widget table th {
     height: 20px;
     line-height: 20px;
     width: 20px;
   }
   .bootstrap-datetimepicker-widget table th.picker-switch {
     width: 145px;
   }
   .bootstrap-datetimepicker-widget table th.disabled,
   .bootstrap-datetimepicker-widget table th.disabled:hover {
     background: none;
     color: #777;
     cursor: not-allowed;
   }
   .bootstrap-datetimepicker-widget table th.prev::after,
   .bootstrap-datetimepicker-widget table th.next::after {
     content: 'Previous Month';
     position: absolute;
     width: 1px;
     height: 1px;
     margin: -1px;
     padding: 0;
     overflow: hidden;
     clip: rect(0, 0, 0, 0);
     border: 0;
   }
   .bootstrap-datetimepicker-widget table th.next::after {
     content: 'Next Mounth';
   }
   .bootstrap-datetimepicker-widget table thead tr:first-child th {
     cursor: pointer;
   }
   .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
     background: #eee;
   }
   .bootstrap-datetimepicker-widget table td {
     height: 54px;
     line-height: 54px;
     width: 54px;
   }
   .bootstrap-datetimepicker-widget table td.cw {
     font-size: 0.8em;
     height: 20px;
     line-height: 20px;
     color: #777;
   }
   .bootstrap-datetimepicker-widget table td.day {
     height: 25px;
     line-height: 25px;
     width: 33px;
   }
   .bootstrap-datetimepicker-widget table td.day:hover,
   .bootstrap-datetimepicker-widget table td.hour:hover,
   .bootstrap-datetimepicker-widget table td.minute:hover,
   .bootstrap-datetimepicker-widget table td.second:hover {
     background: #eee;
     cursor: pointer;
   }
   .bootstrap-datetimepicker-widget table td.old,
   .bootstrap-datetimepicker-widget table td.new {
     color: #777;
   }
   .bootstrap-datetimepicker-widget table td.today {
     poisition: relative;
   }
   .bootstrap-datetimepicker-widget table td.today:before {
     content: '';
     display: inline-block;
     border: solid transparent;
     border-width: 0 0 7px 7px;
     border-bottom-color: #0093d2;
     border-top-color: rgba(0,0,0,0.2);
     position: absolute;
     bottom: 4px;
     right: 4px;
   }
   .bootstrap-datetimepicker-widget table td.active,
   .bootstrap-datetimepicker-widget table td.active:hover {
     background-color: #0093d2;
     color: #fff;
     text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
   }
   .bootstrap-datetimepicker-widget table td.active.today:before {
     border-bottom-color: #fff;
   }
   .bootstrap-datetimepicker-widget table td.disabled,
   .bootstrap-datetimepicker-widget table td.disabled:hover {
     background: none;
     color: #777;
     cursor: not-allowed;
   }
   .bootstrap-datetimepicker-widget table td span {
     display: inline-block;
     width: 54px;
     height: 54px;
     line-height: 54px;
     margin: 2px 1.54px;
     cursor: pointer;
     border-radius: 4px;
   }
   .bootstrap-datetimepicker-widget table td span:hover {
     backround: #eee;
   }
   .bootstrap-datetimepicker-widget table td span.active {
     background-color: #0093d2;
     color: #fff;
     text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
   }
   .bootstrap-datetimepicker-widget table td span.old {
     color: #777;
   }
   .bootstrap-datetimepicker-widget table td span.disabled,
   .bootstrap-datetimepicker-widget table td span.disabled:hover {
     background: none;
     color: #777;
     cursor: not-allowed;
   }
   .bootstrap-datetimepicker-widget.usetwentyfour td.hour {
     height: 27px;
     line-height: 27px;
   }
   .bootstrap-datetimepicker-widget.wider {
     width: 21em;
   }
   .bootstrap-datetimepicker-widget .datepicker-decades .decade {
     line-height: 1.8em !important;
   }
   .sr-only {
     position: absolute;
     width: 1px;
     height: 1px;
     margin: -1px;
     padding: 0;
     overflow: hidden;
     clip: rect(0, 0, 0, 0);
     border: 0;
   }
   /*  --/DATEPICKER--  */
   /*  --QTY SELECTOR--  */
   .quantity-selector.showme {
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .quantity-selector-box {
     display: none;
     padding: 15px;
     background: #fff;
     color: #595959;
     margin-top: 5px;
     -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
     box-shadow: 0 6px 12px rgba(0,0,0,0.175);
     overflow: hidden;
     position: absolute;
     width: 160px;
     z-index: 999;
   }
   .quantity-selector-box.show {
     display: block;
   }
   .quantity-selector-inner {
     position: relative;
   }
   .quantity-selector-title {
     margin-bottom: 0;
   }
   .quantity-selector-controls {
     position: absolute;
     top: 0;
     right: 0;
     margin: 0;
     padding: 0;
     list-style: none;
   }
   .quantity-selector-controls > li {
     float: left;
     margin-left: 7px;
     text-align: center;
     height: 20px;
     line-height: 18px;
     font-weight: bold;
     font-size: 18px;
   }
   .quantity-selector-controls > li > a {
     text-decoration: none !important;
     font-weight: normal;
     display: block;
     width: 20px;
     height: 20px;
     line-height: 18px;
     border-radius: 50%;
     background: #0093d2;
     color: #fff;
   }
   /*  --/QTY SELECTOR--  */
   /*  --AUTOCOMPLETE--  */
   .typeahead.dropdown-menu {
     border-radius: 0;
     border: none;
     border-top: 5px solid transparent;
     padding: 0;
     width: 100%;
   }
   .typeahead.dropdown-menu > li {
     border-bottom: 1px solid #e6e6e6;
   }
   .typeahead.dropdown-menu > li:last-child {
     border: none;
   }
   .typeahead.dropdown-menu > li.active > a {
     background: #0093d2;
   }
   .typeahead.dropdown-menu > li > a {
     padding: 15px;
   }
   .typeahead.dropdown-menu > li > a:hover {
     background: #0093d2;
   }
   /*  --/AUTOCOMPLETE--  */
   /*  --SPINNER--  */
   .spinner-grid-dots {
     position: relative;
     width: 135px;
     height: 135px;
   }
   .spinner-grid-dots div {
     position: absolute;
     width: 32px;
     height: 32px;
     border-radius: 50%;
     -webkit-animation: blink linear 1s infinite;
     -moz-animation: blink linear 1s infinite;
     -o-animation: blink linear 1s infinite;
     -ms-animation: blink linear 1s infinite;
     animation: blink linear 1s infinite;
     background: #fff;
   }
   .spinner-grid-dots div:nth-child(1),
   .spinner-grid-dots div:nth-child(6),
   .spinner-grid-dots div:nth-child(8) {
     -webkit-animation-delay: -0.666s;
     -moz-animation-delay: -0.666s;
     -o-animation-delay: -0.666s;
     -ms-animation-delay: -0.666s;
     animation-delay: -0.666s;
   }
   .spinner-grid-dots div:nth-child(2),
   .spinner-grid-dots div:nth-child(5),
   .spinner-grid-dots div:nth-child(7) {
     -webkit-animation-delay: -0.333s;
     -moz-animation-delay: -0.333s;
     -o-animation-delay: -0.333s;
     -ms-animation-delay: -0.333s;
     animation-delay: -0.333s;
   }
   .spinner-grid-dots div:nth-child(1),
   .spinner-grid-dots div:nth-child(2),
   .spinner-grid-dots div:nth-child(3) {
     top: 0;
   }
   .spinner-grid-dots div:nth-child(4),
   .spinner-grid-dots div:nth-child(5),
   .spinner-grid-dots div:nth-child(6) {
     top: 50px;
   }
   .spinner-grid-dots div:nth-child(7),
   .spinner-grid-dots div:nth-child(8),
   .spinner-grid-dots div:nth-child(9) {
     top: 100px;
   }
   .spinner-grid-dots div:nth-child(1),
   .spinner-grid-dots div:nth-child(4),
   .spinner-grid-dots div:nth-child(7) {
     left: 0;
   }
   .spinner-grid-dots div:nth-child(2),
   .spinner-grid-dots div:nth-child(5),
   .spinner-grid-dots div:nth-child(8) {
     left: 50px;
   }
   .spinner-grid-dots div:nth-child(3),
   .spinner-grid-dots div:nth-child(6),
   .spinner-grid-dots div:nth-child(9) {
     left: 100px;
   }
   @-moz-keyframes blink {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     50% {
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     100% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
   }
   @-webkit-keyframes blink {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     50% {
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     100% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
   }
   @-o-keyframes blink {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     50% {
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     100% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
   }
   @keyframes blink {
     0% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
     50% {
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
     100% {
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
   }
   /*  --/SPINNER--  */
   /*  --WINDOWSCROLL--  */
   /*  --/WINDOWSCROLL--  */
   .magnific-popup {
     width: 50%;
     background: #fff;
     margin: 0 auto;
     padding: 30px;
     position: relative;
     max-width: 800px;
   }
   @media (max-width: 992px) {
     .magnific-popup {
       width: 100% !important;
     }
   }
   .magnific-popup-sm {
     width: 30%;
   }
   .mfp-bg {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 1042;
     overflow: hidden;
     position: fixed;
     background: #0b0b0b;
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
   }
   .mfp-wrap {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 1043;
     position: fixed;
     outline: none !important;
     -webkit-backface-visibility: hidden;
   }
   .mfp-container {
     text-align: center;
     position: absolute;
     width: 100%;
     height: 100%;
     left: 0;
     top: 0;
     padding: 0 8px;
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
   }
   .mfp-container:before {
     content: '';
     display: inline-block;
     height: 100%;
     vertical-align: middle;
   }
   .mfp-align-top .mfp-container:before {
     display: none;
   }
   .mfp-content {
     position: relative;
     display: inline-block;
     vertical-align: middle;
     margin: 0 auto;
     text-align: left;
     z-index: 1045;
   }
   .mfp-inline-holder .mfp-content,
   .mfp-ajax-holder .mfp-content {
     width: 100%;
     cursor: auto;
   }
   .mfp-ajax-cur {
     cursor: progress;
   }
   .mfp-zoom-out-cur,
   .mfp-zoom-out-cur .mfp-mfp-image-holder .mfp-close {
     cursor: -moz-zoom-out;
     cursor: -webkit-zoom-out;
     cursor: zoom-out;
   }
   .mfp-zoom {
     cursor: pointer;
     cursor: -webkit-zoom-in;
     cursor: -moz-zoom-in;
     cursor: zoom-in;
   }
   .mfp-auto-cursor .mfp-content {
     cursor: auto;
   }
   .mfp-close,
   .mfp-arrow,
   .mfp-preloader,
   .mfp-counter {
     -webkit-user-select: none;
     -moz-user-select: none;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
   }
   .mfp-loading.mfp-figure {
     display: none;
   }
   .mfp-hide {
     display: none !important;
   }
   .mfp-preloader {
     color: #ccc;
     position: absolute;
     top: 50%;
     width: auto;
     text-align: center;
     margin-top: -0.8em;
     left: 8px;
     right: 8px;
     z-index: 1044;
   }
   .mfp-preloader a {
     color: #ccc;
   }
   .mfp-preloader a:hover {
     color: #fff;
   }
   .mfp-s-ready .mfp-preloader {
     display: none;
   }
   .mfp-s-error .mfp-content {
     display: none;
   }
   button.mfp-close,
   button.mfp-arrow {
     overflow: visible;
     cursor: pointer;
     background: transparent;
     border: 0;
     -webkit-appearance: none;
     display: block;
     outline: none;
     padding: 0;
     z-index: 1046;
   }
   button::-moz-focus-inner {
     padding: 0;
     border: 0;
   }
   .mfp-close {
     height: 44px;
     width: 44px;
     line-height: 44px;
     position: absolute;
     top: 0;
     right: 0;
     text-decoration: none;
     text-align: center;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     padding: 0 0 18px 10px;
     color: #fff;
     font-style: normal;
     font-size: 28px;
     font-family: Arial, Baskrville, monospace;
   }
   .mfp-close:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .mfp-close:active {
     top: 1px;
   }
   .mfp-close-btn-in .mfp-close {
     color: #333;
   }
   .mfp-image-holder .mfp-close,
   .mfp-iframe-holder .mfp-close {
     color: #fff;
     right: -6px;
     text-align: right;
     padding-right: 6px;
     width: 100%;
   }
   .mfp-counter {
     position: absolute;
     top: 0;
     right: 0;
     color: #ccc;
     font-size: 12px;
     line-height: 18px;
     white-space: nowrap;
   }
   .mfp-arrow {
     position: absolute;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin: 0;
     top: 50%;
     margin-top: -55px;
     padding: 0;
     width: 90px;
     height: 110px;
     -webkit-tap-highlight-color: transparent;
   }
   .mfp-arrow:active {
     margin-top: -54px;
   }
   .mfp-arrow:hover,
   .mfp-arrow:focus {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .mfp-arrow:before,
   .mfp-arrow:after {
     content: '';
     display: block;
     width: 0;
     height: 0;
     position: absolute;
     top: 0;
     left: 0;
     margin-top: 35px;
     margin-left: 35px;
     border: medium inset transparent;
   }
   .mfp-arrow:after {
     border-top-width: 13px;
     border-bottom-width: 13px;
     top: 8px;
   }
   .mfp-arrow:before {
     border-top-width: 21px;
     border-bottom-width: 21px;
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
   }
   .mfp-arrow-left {
     left: 0;
   }
   .mfp-arrow-left:after {
     border-right: 17px solid #fff;
     margin-left: 31px;
   }
   .mfp-arrow-left:before {
     margin-left: 25px;
     border-right: 27px solid #3f3f3f;
   }
   .mfp-arrow-right {
     right: 0;
   }
   .mfp-arrow-right:after {
     border-left: 17px solid #fff;
     margin-left: 39px;
   }
   .mfp-arrow-right:before {
     border-left: 27px solid #3f3f3f;
   }
   .mfp-iframe-holder {
     padding-top: 40px;
     padding-bottom: 40px;
   }
   .mfp-iframe-holder .mfp-content {
     line-height: 0;
     width: 100%;
     max-width: 900px;
   }
   .mfp-iframe-holder .mfp-close {
     top: -40px;
   }
   .mfp-iframe-scaler {
     width: 100%;
     height: 0;
     overflow: hidden;
     padding-top: 56.25%;
   }
   .mfp-iframe-scaler iframe {
     position: absolute;
     display: block;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
     box-shadow: 0 0 8px rgba(0,0,0,0.6);
     background: #000;
   }
   img.mfp-img {
     width: auto;
     max-width: 100%;
     height: auto;
     display: block;
     line-height: 0;
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
     padding: 40px 0 40px;
     margin: 0 auto;
   }
   .mfp-figure {
     line-height: 0;
   }
   .mfp-figure:after {
     content: '';
     position: absolute;
     left: 0;
     top: 40px;
     bottom: 40px;
     display: block;
     right: 0;
     width: auto;
     height: auto;
     z-index: -1;
     -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
     box-shadow: 0 0 8px rgba(0,0,0,0.6);
     background: #444;
   }
   .mfp-figure small {
     color: #bdbdbd;
     font-size: 12px;
     line-height: 14px;
   }
   .mfp-figure figure {
     margin: 0;
   }
   .mfp-bottom-bar {
     margin-top: -36px;
     position: absolute;
     top: 100%;
     left: 0;
     width: 100%;
     cursor: auto;
   }
   .mfp-title {
     text-align: left;
     line-height: 18px;
     color: #3f3f3f;
     word-wrap: break-word;
     padding-right: 36px;
   }
   .mfp-image-holder .mfp-content {
     max-width: 100%;
   }
   .mfp-galler .mfp-image-holder .mfp-figure {
     cursor: pointer;
   }
   @media screen and (max-width: 880px) and (orientation: landscape), screen and (max-height: 300px) {
     .mfp-img-mobile .mfp-image-holder {
       padding-left: 0;
       padding-right: 0;
     }
     .mfp-img-mobile img.mfp-img {
       padding: 0;
     }
     .mfp-img-mobile .mfp-figure:after {
       top: 0;
       bottom: 0;
     }
     .mfp-img-mobile .mfp-figure small {
       display: inline;
       margin-left: 5px;
     }
     .mfp-img-mobile .mfp-bottom-bar {
       background: rgba(0,0,0,0.6);
       bottom: 0;
       margin: 0;
       top: auto;
       padding: 3px 5px;
       position: fixed;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       box-sizing: border-box;
     }
     .mfp-img-mobile .mfp-bottom-bar:empty {
       padding: 0;
     }
     .mfp-img-mobile .mfp-counter {
       right: 5px;
       top: 3px;
     }
     .mfp-img-mobile .mfp-close {
       top: 0;
       right: 0;
       height: 35px;
       width: 35px;
       line-height: 35px;
       background: rgba(0,0,0,0.6);
       position: fixed;
       text-align: center;
       padding: 0;
     }
   }
   @media all and (max-width: 900px) {
     .mfp-arrow {
       -webkit-transform: scale(0.75);
       -moz-transform: scale(0.75);
       -o-transform: scale(0.75);
       -ms-transform: scale(0.75);
       transform: scale(0.75);
     }
     .mfp-arrow-left {
       -webkit-transform-origin: 0;
       -moz-transform-origin: 0;
       -o-transform-origin: 0;
       -ms-transform-origin: 0;
       transform-origin: 0;
     }
     .mfp-arrow-right {
       -webkit-transform-origin: 100%;
       -moz-transform-origin: 100%;
       -o-transform-origin: 100%;
       -ms-transform-origin: 100%;
       transform-origin: 100%;
     }
     .mfp-container {
       padding-left: 0;
       padding-right: 0;
     }
   }
   /*  --MEGA NAVIGATION--  */
   .dropdown-meganav-list-items {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
   }
   .dropdown-meganav-list-items > li {
     margin-bottom: 7px;
   }
   .dropdown-meganav-list-items > li:last-child {
     margin-bottom: 0;
   }
   .dropdown-meganav-list-items > li > a {
     color: #6a6a6a;
   }
   .dropdown-meganav-list-items > li > a:hover {
     color: #3e3e3e;
     text-decoration: none;
   }
   .dropdown-meganav-list-title {
     margin-top: 0;
     margin-bottom: 13px;
   }
   /*  --/MEGA NAVIGATION--  */
   /*  --NAV LANG & CURRENCY SELECT--  */
   .dropdown-meganav-select-list-title {
     margin-left: 5px;
     margin-top: 0;
   }
   .dropdown-meganav-select-list-currency,
   .dropdown-meganav-select-list-lang {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .dropdown-meganav-select-list-currency {
     font-size: 12px;
   }
   .dropdown-meganav-select-list-currency > li.active > a {
     color: #fff;
     background-color: #0093d2;
     cursor: default;
   }
   .dropdown-meganav-select-list-currency > li.active > a > span {
     color: #fff;
   }
   .dropdown-meganav-select-list-currency > li.active > a:hover {
     color: #fff;
     background: #0093d2;
   }
   .dropdown-meganav-select-list-currency > li > a {
     color: #7a7a7a;
     text-decoration: none !important;
     display: block;
     padding: 5px 5px;
   }
   .dropdown-meganav-select-list-currency > li > a:hover {
     color: #595959;
     background-color: #f2f2f2;
   }
   .dropdown-meganav-select-list-currency > li > a > span {
     display: inline-block;
     font-weight: bold;
     width: 30px;
     margin-right: 5px;
     font-size: 13px;
     color: #0093d2;
   }
   .dropdown-meganav-select-list-lang {
     font-size: 13px;
   }
   .dropdown-meganav-select-list-lang > li.active > a {
     background: #e6e6e6;
     cursor: default;
     color: #505050;
   }
   .dropdown-meganav-select-list-lang > li > a {
     padding: 7px 5px;
     display: block;
     color: #7a7a7a;
     text-decoration: none !important;
   }
   .dropdown-meganav-select-list-lang > li > a:hover {
     background: #e6e6e6;
     color: #505050;
   }
   .dropdown-meganav-select-list-lang > li > a > img {
     height: 17px;
     margin-right: 7px;
   }
   /*  --/NAV LANG & CURRENCY SELECT--  */
   /*  --PAGE SECTION--  */
   .theme-page-header-abs {
     color: #fff;
     position: absolute;
     bottom: 60px;
   }
   .theme-page-header-title {
     font-size: 40px;
     margin-top: 0;
     letter-spacing: 1px;
     font-weight: 300;
   }
   .theme-page-header-subtitle {
     font-size: 21px;
     margin-bottom: 0;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-page-header-lg .theme-page-header-title {
     font-size: 60px;
   }
   @media (max-width: 992px) {
     .theme-page-header-lg .theme-page-header-title {
       font-size: 40px;
     }
   }
   .theme-page-header-xl .theme-page-header-title {
     font-size: 100px;
   }
   .theme-page-body {
     margin-top: 45px;
     margin-bottom: 45px;
   }
   .theme-page-section {
     padding: 24px 0;
   }
   .theme-page-section-no-pad {
     padding: 0;
   }
   .theme-page-section-sm {
     padding: 20px 0;
   }
   .theme-page-section-lg {
     padding: 45px 0;
   }
   @media (max-width: 992px) {
     .theme-page-section-lg {
       padding: 30px 0;
     }
   }
   .theme-page-section-xl {
     padding: 60px 0;
   }
   @media (max-width: 992px) {
     .theme-page-section-xl {
       padding: 45px 0;
     }
   }
   .theme-page-section-xxl {
     padding: 90px 0;
   }
   @media (max-width: 992px) {
     .theme-page-section-xxl {
       padding: 60px 0;
     }
   }
   .theme-page-section-no-pad-bottom {
     padding-bottom: 0;
   }
   .theme-page-section-dark {
     background: #333;
   }
   .theme-page-section-gray {
     background: #e6e6e6;
   }
   .theme-page-section-white {
     background: #fff;
   }
   .theme-page-section-b-bottom,
   .theme-page-section-bb {
     border-bottom: 1px solid #d9d9d9;
   }
   .theme-page-section-header {
     text-align: center;
     margin-bottom: 30px;
     position: relative;
   }
   .theme-page-section-header-bb {
     margin-bottom: 20px;
     padding-bottom: 10px;
     border-bottom: 1px solid #e6e6e6;
   }
   .theme-page-section-header-white {
     color: #fff;
   }
   .theme-page-section-header-white .theme-page-section-title {
     color: #fff;
   }
   .theme-page-section-header-white .theme-page-section-title b {
     color: #fff;
   }
   .theme-page-section-header-sm {
     margin-bottom: 15px;
   }
   .theme-page-section-header-sm .theme-page-section-title {
     font-size: 18px;
     letter-spacing: 0;
   }
   .theme-page-section-header-sm .theme-page-section-subtitle {
     font-size: 13px;
     letter-spacing: 0;
     margin-top: 7px;
   }
   .theme-page-section-header-link {
     margin-top: 10px;
     display: inline-block;
     color: #595959;
     font-size: 17px;
     opacity: 0.45;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=45)";
     filter: alpha(opacity=45);
     text-transform: uppercase;
   }
   .theme-page-section-header-link:hover {
     text-decoration: none;
     color: #595959;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-page-section-header-link-rb {
     position: absolute;
     bottom: 0;
     right: 0;
   }
   @media (max-width: 992px) {
     .theme-page-section-header-link-rb {
       position: relative;
     }
   }
   .theme-page-section-subtitle {
     margin-bottom: 0;
     margin-top: 10px;
     font-size: 20px;
     opacity: 0.45;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=45)";
     filter: alpha(opacity=45);
   }
   .theme-page-section-subtitle-sm {
     font-size: 16px;
   }
   .theme-page-section-title {
     margin-top: 0;
     margin-bottom: 0;
     letter-spacing: 0.6px;
     font-size: 35px;
     font-weight: 300;
     color: #616161;
   }
   .theme-page-section-title b {
     color: #505050;
   }
   .theme-page-section-title-sm {
     font-size: 25px;
     letter-spacing: 0;
   }
   .theme-page-section-title-lg {
     font-size: 32px;
   }
   .theme-page-section-title-white {
     color: #fff;
     text-shadow: 1px 2px 10px rgba(0,0,0,0.2);
   }
   /*  --/PAGE SECTION--  */
   /*  --ACCOUNT PAGE--  */
   /*  --/ACCOUNT PAGE--  */
   /*  --BREADCRUMBS--  */
   .theme-breadcrumbs {
     list-style: none;
     margin: 0;
     padding: 0;
     overflow: hidden;
     margin-top: 30px;
     color: #fff;
   }
   .theme-breadcrumbs > li {
     float: left;
     padding-right: 40px;
     position: relative;
   }
   .theme-breadcrumbs > li:after {
     content: '\2192';
     color: rgba(255,255,255,0.8);
     position: absolute;
     top: 0;
     right: 12px;
   }
   .theme-breadcrumbs > li:last-child {
     padding-right: 0;
   }
   .theme-breadcrumbs > li:last-child:after {
     display: none;
   }
   @media (max-width: 992px) {
     .theme-breadcrumbs {
       overflow-x: auto;
       white-space: nowrap;
     }
     .theme-breadcrumbs > li {
       display: inline-block;
       float: none;
       vertical-align: top;
     }
   }
   .theme-breadcrumbs-center {
     display: table;
     margin-left: auto !important;
     margin-right: auto !important;
   }
   .theme-breadcrumbs-block {
     margin: 0;
     padding: 30px 0;
   }
   .theme-breadcrumbs-block.theme-breadcrumbs-sm {
     margin: 0;
     padding: 15px 0;
   }
   .theme-breadcrumbs-abs-bottom {
     position: absolute;
     width: 100%;
     bottom: 20px;
     z-index: 5;
   }
   .theme-breadcrumbs-item-title {
     margin-bottom: 3px;
     font-size: 15px;
   }
   .theme-breadcrumbs-item-title.active {
     font-weight: bold;
   }
   .theme-breadcrumbs-item-title > a {
     color: #fff;
     text-decoration: underline;
   }
   .theme-breadcrumbs-item-subtitle {
     font-size: 12px;
     margin-bottom: 0;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-breadcrumbs-sm {
     margin-top: 25px;
   }
   .theme-breadcrumbs-sm > li:after {
     top: -2px;
   }
   .theme-breadcrumbs-sm .theme-breadcrumbs-item-title {
     font-size: 13px;
   }
   .theme-breadcrumbs-sm .theme-breadcrumbs-item-title > a {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-breadcrumbs-sm .theme-breadcrumbs-item-title > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-breadcrumbs-default {
     color: #595959;
   }
   .theme-breadcrumbs-default .theme-breadcrumbs-item-title > a {
     color: #595959;
   }
   .theme-breadcrumbs-default > li:after {
     color: rgba(89,89,89,0.8);
   }
   .theme-breadcrumbs-top {
     margin-top: 0;
     margin-bottom: 30px;
   }
   /*  --/BREADCRUMBS--  */
   /*  --HERO AREA--  */
   .theme-hero-area {
     position: relative;
     z-index: 1;
   }
   .theme-hero-area-primary {
     z-index: 10;
   }
   .theme-hero-area-sm {
     height: 36vh;
   }
   .theme-hero-area-half {
     height: 50vh;
   }
   .theme-hero-area-lg {
     height: 64vh;
   }
   .theme-hero-area-xl {
     height: 80vh;
   }
   .theme-hero-area-full {
     height: 100vh;
   }
   .theme-hero-area-title {
     color: #fff;
     font-size: 125px;
     margin-top: 0;
     line-height: 1.15em;
     letter-spacing: -2px;
     text-shadow: 0px 10px 15px rgba(0,0,0,0.1);
     font-family: helvetica, Arial, sans-serif;
     font-weight: 100;
   }
   .theme-hero-area-subtitle {
     font-size: 25px;
     color: #fff;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-hero-area-bg-wrap {
     position: absolute;
     height: 100%;
     width: 100%;
     overflow: hidden;
     z-index: 4;
   }
   .theme-hero-area-bg,
   .theme-hero-area-bg-color,
   .theme-hero-area-mask,
   .theme-hero-area-inner-shadow,
   .theme-hero-area-grad-mask,
   .theme-hero-area-bg-parallax,
   .theme-hero-area-bg-pattern,
   .theme-hero-area-bg-video {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
   }
   .theme-hero-area-bg-video {
     min-height: 100%;
     min-width: 100%;
     width: auto;
     height: auto;
     z-index: 2;
     top: 50%;
     left: 50%;
     -webkit-transform: translate3d(-50%, -50%, 0);
     -moz-transform: translate3d(-50%, -50%, 0);
     -o-transform: translate3d(-50%, -50%, 0);
     -ms-transform: translate3d(-50%, -50%, 0);
     transform: translate3d(-50%, -50%, 0);
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
     -webkit-perspective: 1000;
     -moz-perspective: 1000;
     -ms-perspective: 1000;
     perspective: 1000;
   }
   @media (max-width: 992px) {
     .theme-hero-area-bg-video {
       display: none;
     }
   }
   .theme-hero-area-bg-video-blur,
   .theme-hero-area-bg-video-blur-strong {
     -webkit-transform: translate3d(-50%, -50%, 0) scale(1.2);
     -moz-transform: translate3d(-50%, -50%, 0) scale(1.2);
     -o-transform: translate3d(-50%, -50%, 0) scale(1.2);
     -ms-transform: translate3d(-50%, -50%, 0) scale(1.2);
     transform: translate3d(-50%, -50%, 0) scale(1.2);
     filter: blur(8px);
   }
   .theme-hero-area-bg-video-blur-strong {
     filter: blur(16px);
   }
   .theme-hero-area-bg,
   .theme-hero-area-bg-parallax {
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     z-index: 1;
     background-repeat: no-repeat;
   }
   .theme-hero-area-bg-blur {
     -webkit-transform: translateZ(0) scale(1.2);
     -moz-transform: translateZ(0) scale(1.2);
     -o-transform: translateZ(0) scale(1.2);
     -ms-transform: translateZ(0) scale(1.2);
     transform: translateZ(0) scale(1.2);
     filter: blur(8px);
   }
   .bg-fixed {
     background-attachment: fixed;
   }
   .theme-hero-area-bg-color-dark {
     background: #333;
   }
   .theme-hero-area-bg-pattern {
     background-repeat: repeat;
     z-index: 4;
   }
   .theme-hero-area-bg-pattern[data-parallax="true"] {
     -webkit-background-size: auto;
     -moz-background-size: auto;
     background-size: auto;
     background-position: unset;
   }
   .theme-hero-area-bg-pattern-animate {
     width: 300%;
     height: 300%;
     right: 0;
     left: auto;
     -webkit-animation: patternMove 600s linear infinite;
     -moz-animation: patternMove 600s linear infinite;
     -o-animation: patternMove 600s linear infinite;
     -ms-animation: patternMove 600s linear infinite;
     animation: patternMove 600s linear infinite;
   }
   .theme-hero-area-bg-pattern-half {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-hero-area-bg-pattern-light {
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
   }
   .theme-hero-area-bg-pattern-mega-light {
     opacity: 0.15;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
     filter: alpha(opacity=15);
   }
   .theme-hero-area-bg-pattern-ultra-light {
     opacity: 0.03;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=3)";
     filter: alpha(opacity=3);
   }
   .theme-hero-area-bg-pattern-strong {
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
   }
   .theme-hero-area-bg-pattern-bold {
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
   }
   .theme-hero-area-grad-mask {
     background: -webkit-linear-gradient(135deg, #00587e, #0093d2);
     background: -moz-linear-gradient(135deg, #00587e, #0093d2);
     background: -o-linear-gradient(135deg, #00587e, #0093d2);
     background: -ms-linear-gradient(135deg, #00587e, #0093d2);
     background: linear-gradient(-45deg, #00587e, #0093d2);
     z-index: 3;
   }
   .theme-hero-area-grad-mask-i {
     background: -webkit-linear-gradient(135deg, #b43600, #ff6c2d);
     background: -moz-linear-gradient(135deg, #b43600, #ff6c2d);
     background: -o-linear-gradient(135deg, #b43600, #ff6c2d);
     background: -ms-linear-gradient(135deg, #b43600, #ff6c2d);
     background: linear-gradient(-45deg, #b43600, #ff6c2d);
   }
   .theme-hero-area-grad-mask-half {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-hero-area-grad-mask-light {
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
   }
   .theme-hero-area-grad-mask-strong {
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
   }
   .theme-hero-area-grad-mask-bold {
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
   }
   .theme-hero-area-mask {
     background: #000;
     z-index: 2;
     opacity: 0.33;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
     filter: alpha(opacity=33);
   }
   .theme-hero-area-mask-white {
     background: #fff;
   }
   .theme-hero-area-mask-half {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-hero-area-mask-strong {
     opacity: 0.46;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
     filter: alpha(opacity=66);
   }
   .theme-hero-area-mask-bold {
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
   }
   .theme-hero-area-mask-black {
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
   }
   .theme-hero-area-inner-shadow {
     z-index: 5;
     -webkit-box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
     box-shadow: inset 0 0 100px rgba(0,0,0,0.5);
   }
   .theme-hero-area-inner-shadow-strong {
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
   }
   .theme-hero-area-inner-shadow-medium {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-hero-area-inner-shadow-light {
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
   }
   .theme-hero-area-front-mask {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     z-index: 6;
     background: #fff;
   }
   .theme-hero-area-front-mask-gray {
     background: #e6e6e6;
   }
   .theme-hero-area-front-mask-color-inverse {
     background: #ff6c2d;
   }
   .theme-hero-area-front-mask-color {
     background: #0093d2;
   }
   .theme-hero-area-front-mask-right {
     left: auto;
     right: 0;
     -webkit-box-shadow: -10px 0 20px rgba(0,0,0,0.2);
     box-shadow: -10px 0 20px rgba(0,0,0,0.2);
   }
   .theme-hero-area-body {
     position: relative;
     z-index: 6;
     height: 100%;
   }
   .theme-hero-area-body > .container {
     position: relative;
     height: 100%;
   }
   .theme-hero-area-body-vert-center {
     position: absolute;
     top: 50%;
     height: auto;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
     width: 100%;
   }
   .theme-hero-area-body-bottom {
     position: absolute;
     bottom: 0;
     top: auto;
     height: auto;
     width: 100%;
   }
   .theme-hero-area-sm .theme-hero-area-slider-item {
     height: 36vh;
   }
   .theme-hero-area-half .theme-hero-area-slider-item {
     height: 50vh;
   }
   .theme-hero-area-lg .theme-hero-area-slider-item {
     height: 64vh;
   }
   .theme-hero-area-xl .theme-hero-area-slider-item {
     height: 80vh;
   }
   .theme-hero-area-full .theme-hero-area-slider-item {
     height: 100vh;
   }
   .theme-hero-area-slider {
     position: relative;
     z-index: 3;
   }
   .theme-hero-area-slider-item {
     position: relative;
     overflow: hidden;
   }
   .theme-hero-area-slider-item-bg,
   .theme-hero-area-slider-item-mask {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
   }
   .theme-hero-area-slider-item-bg {
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     z-index: 1;
     background-repeat: no-repeat;
   }
   .theme-hero-area-slider-item-mask {
     z-index: 2;
     background: #000;
     opacity: 0.66;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
     filter: alpha(opacity=66);
   }
   .theme-hero-area-slider-item-caption {
     z-index: 3;
     position: relative;
     text-align: center;
     padding-top: 200px;
     color: #fff;
   }
   .theme-hero-area-slider-item-title {
     text-transform: uppercase;
     font-size: 130px;
     letter-spacing: 10px;
     margin-top: 0;
     margin-bottom: 20px;
     text-shadow: 0 5px 15px rgba(0,0,0,0.2);
     line-height: 1em;
   }
   .theme-hero-area-slider-item-subtitle {
     font-size: 19px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     display: table;
     width: 50%;
     margin: 0 auto;
     margin-bottom: 20px;
   }
   @-moz-keyframes 'patternMove' {
     0% {
       -webkit-transform: translate3d(0, -100%, 0);
       -moz-transform: translate3d(0, -100%, 0);
       -o-transform: translate3d(0, -100%, 0);
       -ms-transform: translate3d(0, -100%, 0);
       transform: translate3d(0, -100%, 0);
     }
     100% {
       -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
       -o-transform: translate3d(100%, 0, 0);
       -ms-transform: translate3d(100%, 0, 0);
       transform: translate3d(100%, 0, 0);
     }
   }
   @-webkit-keyframes 'patternMove' {
     0% {
       -webkit-transform: translate3d(0, -100%, 0);
       -moz-transform: translate3d(0, -100%, 0);
       -o-transform: translate3d(0, -100%, 0);
       -ms-transform: translate3d(0, -100%, 0);
       transform: translate3d(0, -100%, 0);
     }
     100% {
       -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
       -o-transform: translate3d(100%, 0, 0);
       -ms-transform: translate3d(100%, 0, 0);
       transform: translate3d(100%, 0, 0);
     }
   }
   @-o-keyframes 'patternMove' {
     0% {
       -webkit-transform: translate3d(0, -100%, 0);
       -moz-transform: translate3d(0, -100%, 0);
       -o-transform: translate3d(0, -100%, 0);
       -ms-transform: translate3d(0, -100%, 0);
       transform: translate3d(0, -100%, 0);
     }
     100% {
       -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
       -o-transform: translate3d(100%, 0, 0);
       -ms-transform: translate3d(100%, 0, 0);
       transform: translate3d(100%, 0, 0);
     }
   }
   @keyframes 'patternMove' {
     0% {
       -webkit-transform: translate3d(0, -100%, 0);
       -moz-transform: translate3d(0, -100%, 0);
       -o-transform: translate3d(0, -100%, 0);
       -ms-transform: translate3d(0, -100%, 0);
       transform: translate3d(0, -100%, 0);
     }
     100% {
       -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
       -o-transform: translate3d(100%, 0, 0);
       -ms-transform: translate3d(100%, 0, 0);
       transform: translate3d(100%, 0, 0);
     }
   }
   /*  --/HERO AREA--  */
   /*  --HERO TEXT--  */
   .theme-hero-text-center {
     text-align: center;
   }
   .theme-hero-text-white {
     color: #fff;
   }
   .theme-hero-text-title {
     font-size: 50px;
     margin-bottom: 30px;
     font-weight: 200;
     margin-top: 0;
     line-height: 1em;
     letter-spacing: 0;
   }
   .theme-hero-text-title-lg {
     font-size: 60px;
     line-height: 1.15em;
   }
   .theme-hero-text-title-xl {
     font-size: 100px;
     line-height: 1em;
     letter-spacing: 1px;
   }
   @media (max-width: 992px) {
     .theme-hero-text-title-xl {
       font-size: 40px;
     }
   }
   .theme-hero-text-title-xxl {
     font-size: 150px;
     line-height: 1em;
     letter-spacing: 1.5px;
   }
   @media (max-width: 992px) {
     .theme-hero-text-title-xxl {
       font-size: 50px;
     }
   }
   .theme-hero-text-title-sm {
     font-size: 30px;
   }
   .theme-hero-text-mb {
     margin-bottom: 30px;
   }
   .theme-hero-text-subtitle {
     font-size: 20px;
     margin-bottom: 0;
     opacity: 0.6;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
     filter: alpha(opacity=60);
     line-height: 1.6em;
   }
   .theme-hero-text-subtitle-sm {
     font-size: 16px;
   }
   .theme-hero-text-body {
     margin-top: 30px;
     font-size: 18px;
   }
   .theme-hero-text-body > p {
     line-height: 1.5em;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-hero-text-video {
     -webkit-box-shadow: 0 3px 10px rgba(0,0,0,0.3);
     box-shadow: 0 3px 10px rgba(0,0,0,0.3);
   }
   .theme-hero-text-btn {
     margin-top: 30px;
     padding-left: 27px;
     padding-right: 27px;
   }
   .theme-hero-text-btn.btn-primary-inverse {
     -webkit-box-shadow: 0 5px 15px rgba(90,27,0,0.25);
     box-shadow: 0 5px 15px rgba(90,27,0,0.25);
   }
   .theme-hero-text-btn.btn-primary {
     -webkit-box-shadow: 0 5px 15px rgba(0,44,63,0.25);
     box-shadow: 0 5px 15px rgba(0,44,63,0.25);
   }
   .theme-hero-text-sm .theme-hero-text-title {
     font-size: 43px;
     margin-bottom: 15px;
   }
   .theme-hero-text-sm .theme-hero-text-subtitle {
     font-size: 20px;
     line-height: 1.6em;
   }
   .theme-hero-text-xs .theme-hero-text-title {
     font-size: 30px;
     margin-bottom: 15px;
   }
   .theme-hero-text-xs .theme-hero-text-subtitle {
     font-size: 17px;
     line-height: 1.4em;
   }
   /*  --/HERO TEXT--  */
   /*  --SEARCH AREA--  */
   .theme-search-area {
     color: rgba(0,0,0,.87);
   }
   @media (min-width: 992px) {
     .theme-search-area-stacked .theme-search-area-section-inner {
       border-right: none;
     }
   }
   .theme-search-area-stacked .theme-search-area-section-curved .theme-search-area-section-inner {
     border-radius: 0;
   }
   @media (max-width: 992px) {
     .theme-search-area-stacked .theme-search-area-section-curved .theme-search-area-section-inner {
       border-radius: 3px !important;
     }
   }
   .theme-search-area-stacked .theme-search-area-section-curved.first .theme-search-area-section-inner {
     border-radius: 3px 0 0 3px;
   }
   .theme-search-area-stacked .theme-search-area-submit-curved {
     border-radius: 0 3px 3px 0;
   }
   @media (max-width: 992px) {
     .theme-search-area-stacked .theme-search-area-submit-curved {
       border-radius: 3px;
     }
   }
   .theme-search-area-header {
     margin-bottom: 30px;
   }
   .theme-search-area-header-sm {
     margin-bottom: 0;
   }
   .theme-search-area-header-sm .theme-search-area-title {
     letter-spacing: 0;
     font-size: 18px;
   }
   .theme-search-area-header-sm .theme-search-area-subtitle {
     margin-top: 2px;
     font-size: 12px;
   }
   .theme-search-area-title {
     margin: 0;
     font-weight: 200;
   }
   .theme-search-area-title-lg {
     font-size: 45px;
   }
   .theme-search-area-title-sm {
     font-size: 27px;
     letter-spacing: 0.33px;
   }
   .theme-search-area-subtitle {
     margin-top: 8px;
     margin-bottom: 0;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     font-size: 16px;
   }
   .theme-search-area-subtitle-sm {
     font-size: 13px;
   }
   .theme-search-area-title-line {
     padding-bottom: 15px;
     border-bottom: 1px solid #d9d9d9;
   }
   .theme-search-area-vert .theme-search-area-section {
     margin-bottom: 20px;
   }
   .theme-search-area-tabs-header {
     margin-bottom: 40px;
   }
   .theme-search-area-tabs-title {
     margin-top: 0;
     line-height: 1em;
     margin-bottom: 0;
     font-size: 60px;
     font-weight: 300;
   }
   .theme-search-area-tabs-title-lg {
     font-size: 80px;
   }
   .theme-search-area-tabs-title-sm {
     font-size: 40px;
   }
   .theme-search-area-tabs-title-xs {
     font-size: 30px;
     font-weight: 400;
   }
   .theme-search-area-tabs-subtitle {
     font-size: 25px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-top: 10px;
     letter-spacing: 1px;
     margin-bottom: 0;
   }
   .theme-search-area-tabs-subtitle-sm {
     font-size: 18px;
     letter-spacing: 0;
   }
   .theme-search-area-section {
     position: relative;
   }
   @media (max-width: 992px) {
     .theme-search-area-section {
       margin-bottom: 20px;
     }
   }
   .theme-search-area-section-inner {
     position: relative;
     border: 1px solid #d9d9d9;
   }
   .theme-search-area-section-input {
     display: block;
     width: 100%;
     border: none !important;
     outline: none !important;
     height: 40px;
     padding-left: 42.30769230769231px;
     font-size: 16px;
     background: none;
   }
   @media (max-width: 992px) {
     .theme-search-area-section-input {
       line-height: 55px;
     }
   }
   .theme-search-area-section-icon {
     position: absolute;
     top: 0;
     left: 0;
     width: 42.30769230769231px;
     height: 40px;
     line-height: 40px;
     text-align: center;
     display: block;
     font-size: 20px;
     opacity: 0.86;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=86)";
     filter: alpha(opacity=86);
   }
   .theme-search-area-section-label {
     margin-bottom: 0px;
     line-height: 1em;
     font-size: 12px;
     display: block;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
     font-weight: normal;
   }
   .theme-search-area-section-submit {
     margin-top: 26.5px;
     display: block;
     height: 57px;
     line-height: 57px;
     text-align: center;
     padding: 0;
     width: 100%;
     color: #fff;
     background: #ff6c2d;
     border: none;
     font-weight: bold;
     letter-spacing: 1px;
   }
   .theme-search-area-section-sm .theme-search-area-section-input {
     height: 45px;
     font-size: 14px;
     padding-left: 37.5px;
   }
   @media (max-width: 992px) {
     .theme-search-area-section-sm .theme-search-area-section-input {
       line-height: 45px;
     }
   }
   .theme-search-area-section-sm .theme-search-area-section-icon {
     height: 40px;
     line-height: 40px;
     font-size: 15px;
     width: 34.61538461538461px;
   }
   .theme-search-area-section-sm .theme-search-area-section-label {
     font-size: 12px;
     margin-bottom: 8px;
   }
   .theme-search-area-section-sm.theme-search-area-section-line .theme-search-area-section-inner {
     border-width: 1px;
   }
   .theme-search-area-section-curved .theme-search-area-section-inner {
     border-radius: 3px;
   }
   .theme-search-area-section-curved .theme-search-area-section-input {
     border-radius: 3px;
   }
   .theme-search-area-section-fade-white .theme-search-area-section-inner {
     background: rgba(255,255,255,0.2);
   }
   .theme-search-area-section-fade-black .theme-search-area-section-inner {
     background: rgba(0,0,0,0.2);
   }
   .theme-search-area-section-no-border .theme-search-area-section-inner {
     border: none;
   }
   .theme-search-area-section-line .theme-search-area-section-inner {
     border: none;
     border-bottom: .5px solid #989898;
   }
   .theme-search-area-section-line .theme-search-area-section-icon {
     text-align: left;
     width: 30.555555555555554px;
   }
   .theme-search-area-section-line .theme-search-area-section-input {
     padding-left: 30.555555555555554px;
   }
   .theme-search-area-section-bg-white .theme-search-area-section-inner {
     background: #fff;
   }
   .theme-search-area-section-bg-fade-white .theme-search-area-section-inner {
     background: rgba(255,255,255,0.65);
   }
   .theme-search-area-section-mr {
     margin-right: 2px;
   }
   .theme-search-area-section-border-primary .theme-search-area-section-inner {
     border-color: #0093d2;
   }
   .theme-search-area-section-border-light .theme-search-area-section-inner {
     border-color: #e4e4e4;
   }
   .theme-search-area-section-no-icon .theme-search-area-section-input {
     padding-left: 15px;
   }
   .theme-search-area-submit {
     margin-top: 18px;
     display: block;
     height: 40px;
     line-height: 20px;
     text-align: center;
     width: 100%;
     color: #fff;
     background: var(--craneGreenDark);
     border: none;
     font-weight: 600;
     font-size: 14px !important;
     letter-spacing: 1px;
     position: relative;
     padding: 10px 14px;
     border-radius: 2px;
     box-shadow: 1px 1px 3px rgba(0,0,0,.3);

   }
   .theme-search-area-submit-sm {
     height: 47px;
     line-height: 47px;
     margin-top: 20px;
     font-size: 12px;
   }
   .theme-search-area-submit-sm.theme-search-area-submit-no-border {
     height: 45px;
     line-height: 45px;
   }
   .theme-search-area-submit-sm .theme-search-area-submit-icon {
     font-size: 1.3em;
   }
   .theme-search-area-submit-white {
     background: #fff;
     color: #ff6c2d;
   }
   .theme-search-area-submit-primary {
     background: var(--craneGreenLight);
     color:var(--periskaWwhite);
   }
   .theme-search-area-submit-periska{
     background: var(--sooresWhite);
     color:var(--craneGreenLight);
   }
   .theme-search-area-submit-primary.theme-search-area-submit-white {
     color: #0093d2;
     background: #fff;
   }
   .theme-search-area-submit-no-border {
     height: 40px;
     line-height: 40px;
   }
   .theme-search-area-submit-curved {
     border-radius: 3px;
   }
   .theme-search-area-submit-icon {
     font-size: 2em;
     position: absolute;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     display: block;
   }
   @media (max-width: 992px) {
     .theme-search-area-submit-icon {
       display: none;
     }
   }
   .theme-search-area-submit-icon-sm {
     font-size: 1.5em;
   }
   .theme-search-area-submit-glow {
     box-shadow: 0 2px 30px var(--craneGreenDark);
   }
   .theme-search-area-submit-glow.theme-search-area-submit-primary {
     -webkit-box-shadow: 0 2px 30px rgba(30,187,255,0.65);
     box-shadow: 0 2px 30px rgba(30,187,255,0.65);
   }
   .theme-search-area-options {
     margin-top: 15px;
     display: table;
     height: 30px;
     overflow: hidden;
   }
   @media (max-width: 992px) {
     .theme-search-area-options {
       margin-left: auto;
       margin-right: auto;
     }
     .theme-search-area-options .theme-search-area-options-list .btn:last-child {
       margin-right: 0;
     }
   }
   .theme-search-area-options-dot-primary-inverse .theme-search-area-options-list .btn.active:before,
   .theme-search-area-options-dot-primary-inverse .theme-search-area-options-list .btn:focus:before,
   .theme-search-area-options-dot-primary-inverse .theme-search-area-options-list .btn:active:before {
     background: #ff6c2d;
     -webkit-box-shadow: 0 0 0 1px #ff6c2d !important;
     box-shadow: 0 0 0 1px #ff6c2d !important;
   }
   .theme-search-area-options-dot-white .theme-search-area-options-list .btn.active:before,
   .theme-search-area-options-dot-white .theme-search-area-options-list .btn:focus:before,
   .theme-search-area-options-dot-white .theme-search-area-options-list .btn:active:before {
     background: #fff;
     -webkit-box-shadow: 0 0 0 1px #fff !important;
     box-shadow: 0 0 0 1px #fff !important;
   }
   .theme-search-area-options-center {
     margin-left: auto;
     margin-right: auto;
   }
   .theme-search-area-options-center .theme-search-area-options-list .btn:last-child {
     margin-right: 0;
   }
   .theme-search-area-options-title {
     display: table;
     height: 30px;
     line-height: 30px;
     float: left;
     margin: 0;
     margin-right: 10px;
     font-weight: 400;
     font-size: 14px;
   }
   .theme-search-area-options-list {
     margin-right: -5px;
   }
   .theme-search-area-options-list .btn {
     color: #595959;
     letter-spacing: 0;
     font-size: 14px;
     font-weight: 400;
     margin-right: 5px;
     background: none;
     border: none;
     padding: 0 10px;
     padding-left: 15px;
     height: 30px;
     line-height: 30px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
     outline: none !important;
     border-radius: 3px !important;
     position: relative;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
   }
   .theme-search-area-options-list .btn:before {
     content: '';
     position: absolute;
     border-radius: 50%;
     -webkit-box-shadow: 0 0 0 1px #595959;
     box-shadow: 0 0 0 1px #595959;
     width: 6px;
     height: 6px;
     top: 50%;
     margin-top: -3px;
     left: 3px;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-search-area-options-list .btn.active,
   .theme-search-area-options-list .btn:focus,
   .theme-search-area-options-list .btn:active {
     color: #595959;
     opacity: 1;
     -ms-filter: none;
     filter: none;
     background: none;
   }
   .theme-search-area-options-list .btn.active:before,
   .theme-search-area-options-list .btn:focus:before,
   .theme-search-area-options-list .btn:active:before {
     background: #0093d2;
     -webkit-box-shadow: 0 0 0 1px #0093d2;
     box-shadow: 0 0 0 1px #0093d2;
   }
   .theme-search-area-white {
     color: #fff;
   }
   .theme-search-area-white .theme-search-area-section-inner {
     border-color: rgba(255,255,255,0.5);
   }
   .theme-search-area-white .theme-search-area-section-input::placeholder {
     color: #fff;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-search-area-white .theme-search-area-section-line .theme-search-area-section-inner {
     border-color: #fff;
   }
   .theme-search-area-white .theme-search-area-options-list .btn,
   .theme-search-area-options-white .btn {
     color: #fff;
   }
   .theme-search-area-white .theme-search-area-options-list .btn:before,
   .theme-search-area-options-white .btn:before {
     -webkit-box-shadow: 0 0 0 1px #fff;
     box-shadow: 0 0 0 1px #fff;
   }
   .theme-search-area-white .theme-search-area-options-list .btn.active,
   .theme-search-area-options-white .btn.active,
   .theme-search-area-white .theme-search-area-options-list .btn:focus,
   .theme-search-area-options-white .btn:focus,
   .theme-search-area-white .theme-search-area-options-list .btn:active,
   .theme-search-area-options-white .btn:active {
     color: #fff;
   }
   .theme-search-area-white .theme-search-area-options-list .btn.active:before,
   .theme-search-area-options-white .btn.active:before,
   .theme-search-area-white .theme-search-area-options-list .btn:focus:before,
   .theme-search-area-options-white .btn:focus:before,
   .theme-search-area-white .theme-search-area-options-list .btn:active:before,
   .theme-search-area-options-white .btn:active:before {
     -webkit-box-shadow: 0 0 0 1px #0093d2;
     box-shadow: 0 0 0 1px #0093d2;
   }
   .theme-search-area-options-white .theme-search-area-options-title {
     color: #fff;
   }
   .theme-search-area-white .theme-search-area-section-border-light .theme-search-area-section-inner {
     border-color: rgba(255,255,255,0.25);
   }
   .theme-search-area-inline-white .theme-search-area-inline-title,
   .theme-search-area-inline-white .theme-search-area-inline-details,
   .theme-search-area-inline-white .theme-search-area-inline-link,
   .theme-search-area-inline-white .theme-search-area-inline-link:hover {
     color: #fff;
   }
   .theme-search-area-inline-title {
     margin-top: 0;
     font-size: 20px;
   }
   .theme-search-area-inline-link {
     color: #595959;
     text-decoration: none !important;
     outline: none !important;
   }
   .theme-search-area-inline-link:hover {
     color: #505050;
   }
   .theme-search-area-inline-link .fa {
     margin-right: 5px;
   }
   /*  --/SEARCH AREA--  */
   /*  --BLOG--  */
   .theme-blog-post-title {
     margin-bottom: 5px;
     margin-top: 30px;
     font-size: 22px;
     line-height: 1.6em;
   }
   .theme-blog-post > p {
     margin-bottom: 30px;
     font-size: 21px;
     color: #555;
     line-height: 1.6em;
   }
   .theme-blog-slider-item {
     height: 600px;
     height: 64vh;
     position: relative;
     color: #fff;
   }
   .theme-blog-slider-item .banner-caption {
     width: 100%;
     position: absolute;
     bottom: 60px;
     left: 0;
   }
   .theme-blog-slider-item-post {
     height: 50vh;
   }
   .theme-blog-slider-item-post .banner-caption {
     bottom: 30px;
   }
   .theme-blog-slider-item-caption-title {
     font-size: 50px;
     line-height: 1em;
   }
   .theme-blog-slider-item-caption-title > a {
     color: #fff;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-blog-slider-item-caption-title > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     color: #fff;
   }
   .theme-blog-slider-item-caption-meta {
     list-style: none;
     margin: 0;
     padding: 0;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     font-size: 14px;
   }
   .theme-blog-slider-item-caption-meta > li {
     display: inline-block;
     margin-right: 15px;
   }
   .theme-blog-item {
     position: relative;
     overflow: hidden;
   }
   .theme-blog-item .banner-caption {
     padding-top: 60px;
     padding: 20px;
     padding-bottom: 22px;
   }
   .theme-blog-item-center {
     text-align: center;
   }
   .theme-blog-item-center .theme-blog-item-title {
     margin-left: auto;
     margin-right: auto;
     padding-left: 15px;
     padding-right: 15px;
   }
   .theme-blog-item-center .theme-blog-item-desc {
     margin: 0 auto;
     padding: 0 15px;
   }
   .theme-blog-item-time {
     line-height: 1em;
     font-size: 12px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     font-style: italic;
   }
   .theme-blog-item-link {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 6;
     display: block;
   }
   .theme-blog-item-white .banner-caption {
     background: #fff;
     color: #595959;
     padding-top: 20px;
   }
   .theme-blog-item-white .banner-bg {
     height: calc(100% - 154px);
   }
   .theme-blog-item-white .theme-blog-item-title {
     border-color: #d9d9d9;
   }
   .theme-blog-item-desc {
     margin-bottom: 0;
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
     height: 42px;
     overflow: hidden;
     max-width: 330px;
     font-size: 14px;
   }
   .theme-blog-item-title {
     margin-top: 0;
     font-size: 17px;
     line-height: 1.25em;
     display: table;
     height: 19px;
     font-weight: normal;
     overflow: hidden;
     margin-bottom: 15px;
     padding-bottom: 15px;
     padding-right: 20px;
     border-bottom: 1px solid rgba(255,255,255,0.3);
     font-family: 'Roboto', helvetica, Arial, sans-serif;
   }
   /*  --/BLOG--  */
   /*  --404--  */
   .theme-page-404 {
     color: #fff;
   }
   .theme-page-404-title {
     font-size: 270px;
     margin-top: 0;
     line-height: 1em;
     margin-bottom: 0;
     letter-spacing: 15px;
     text-shadow: 1px 3px 15px rgba(0,0,0,0.4);
     font-weight: 300;
   }
   @media (max-width: 992px) {
     .theme-page-404-title {
       font-size: 10em;
     }
   }
   .theme-page-404-subtitle {
     font-size: 33px;
     margin-bottom: 45px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   @media (max-width: 992px) {
     .theme-page-404-subtitle {
       font-size: 20px;
     }
   }
   /*  --/404--  */
   /*  --ABOUT US--  */
   .theme-about-us > .theme-about-us-section:first-child {
     margin-top: 0;
     padding-top: 0;
     border: none;
   }
   .theme-about-us-section {
     margin-top: 35px;
     padding-top: 35px;
     border-top: 3px solid #d9d9d9;
   }
   .theme-about-us-section-title {
     font-size: 70px;
     line-height: 1em;
     margin-top: 0;
     margin-bottom: 0;
     font-weight: 300;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   @media (max-width: 992px) {
     .theme-about-us-section-title {
       font-size: 30px;
       margin-bottom: 15px;
     }
   }
   @media (min-width: 992px) {
     .is_stuck .theme-about-us-section-title {
       margin-top: 15px;
       opacity: 0.5;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
       filter: alpha(opacity=50);
     }
   }
   .theme-about-us-section-body {
     margin-bottom: -10px;
   }
   .theme-about-us-section-body > p {
     font-size: 19px;
     line-height: 1.6em;
     margin-bottom: 20px;
   }
   .theme-about-us-section-gallery {
     margin-bottom: 0;
   }
   .theme-about-us-section-gallery-img {
     height: 160px;
     margin-bottom: 10px;
   }
   .theme-about-us-section-gallery-img-lg {
     height: 200px;
   }
   .theme-about-us-section-gallery-img-xl {
     height: 260px;
   }
   .theme-about-us-section-team {
     margin-bottom: 10px;
   }
   .theme-about-us-section-team-member {
     height: 272px;
     margin-bottom: 10px;
   }
   .theme-about-us-section-team-member .banner-caption {
     position: absolute;
     left: 0;
     bottom: 30px;
   }
   .theme-about-us-section-team-member-caption {
     padding: 12px;
     background: #fff;
     display: table;
     -webkit-box-shadow: 2px 2px 7px rgba(0,0,0,0.25);
     box-shadow: 2px 2px 7px rgba(0,0,0,0.25);
   }
   .theme-about-us-section-team-member-name {
     font-weight: bold;
     margin-bottom: 5px;
   }
   .theme-about-us-section-team-member-position {
     margin-bottom: 0;
     font-size: 13px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   /*  --/ABOUT US--  */
   /*  --CONTACT--  */
   .theme-contact-map {
     height: 290px;
     width: 100%;
   }
   .theme-contact-form-group .form-control {
     height: 50px;
     border-radius: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
     outline: none !important;
     border: 1px solid #d1d1d1;
     font-size: 17px;
     padding: 15px;
     margin-bottom: 20px;
   }
   .theme-contact-form-group textarea.form-control {
     height: auto;
   }
   .theme-contact-title {
     margin-top: 0;
     margin-bottom: 30px;
     color: #595959;
   }
   .theme-contact-info {
     font-size: 16px;
     margin-top: 20px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-contact-address {
     margin-bottom: 0;
     line-height: 33px;
   }
   .theme-contact-info-list {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-contact-info-list > li {
     line-height: 33px;
   }
   .theme-contact-info-list > li .fa {
     margin-right: 5px;
   }
   .theme-contact-info-list > li > a {
     color: #fff;
   }
   .theme-contact-info-list > li > a:hover {
     color: whtie;
   }
   /*  --/CONTACT--  */
   /*  --LOGIN--  */
   .theme-login-white .theme-login-title {
     color: #fff;
   }
   .theme-login-white .theme-login-subtitle {
     color: rgba(255,255,255,0.6);
   }
   .theme-login-white .theme-login-terms {
     color: rgba(255,255,255,0.85);
   }
   .theme-login-white .theme-login-terms a {
     color: #fff;
     font-weight: bold;
   }
   .theme-login-header {
     padding: 0 30px;
     margin-bottom: 15px;
   }
   .theme-login-title {
     margin-bottom: 0;
     margin-top: 0;
     color: #505050;
     line-height: 1em;
     font-weight: 300;
   }
   .theme-login-subtitle {
     margin-top: 7px;
     margin-bottom: 0;
     color: #616161;
   }
   .theme-login-box {
     background: #fff;
     -webkit-box-shadow: 0 10px 45px rgba(0,0,0,0.15) , 0 2px 4px rgba(0,0,0,0.1);
     box-shadow: 0 10px 45px rgba(0,0,0,0.15) , 0 2px 4px rgba(0,0,0,0.1);
     border-radius: 2px;
     overflow: hidden;
   }
   .theme-login-box-inner {
     padding: 35px 30px;
     padding-bottom: 20px;
   }
   .theme-login-box-alt {
     padding: 20px 30px;
     border-top: 1px solid #e6e6e6;
     background: #f9f9f9;
   }
   .theme-login-box-alt p {
     margin-bottom: 0;
     font-size: 14px;
     color: #6a6a6a;
   }
   .theme-login-terms {
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-top: 15px;
     font-size: 13px;
     margin-bottom: 0;
     padding: 0 30px;
     color: #727272;
   }
   .theme-login-terms a {
     color: #595959;
     font-weight: bold;
   }
   .theme-login-form {
     margin-bottom: 20px;
   }
   .theme-login-form-group {
     margin-bottom: 20px;
   }
   .theme-login-form-group .form-control {
     height: 50px;
     border-radius: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
     font-size: 16px;
     border-color: #dedede;
   }
   .theme-login-form-group .form-control:focus {
     border-color: #0093d2;
   }
   .theme-login-form-group .help-block {
     font-size: 13px;
     margin-top: 10px;
   }
   .theme-login-form-group .help-block > a {
     color: #7a7a7a;
   }
   .theme-login-form-group .help-block > a:hover {
     color: #595959;
   }
   .theme-login-social-login-facebook,
   .theme-login-social-login-google {
     display: block;
     padding: 10px 12px;
     color: #fff;
     overflow: hidden;
     border-radius: 3px;
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   @media (max-width: 992px) {
     .theme-login-social-login-facebook,
     .theme-login-social-login-google {
       padding: 13px 18px;
       text-align: center;
     }
   }
   .theme-login-social-login-facebook:hover,
   .theme-login-social-login-google:hover {
     color: #fff;
   }
   .theme-login-social-login-facebook .fa,
   .theme-login-social-login-google .fa {
     display: block;
     float: left;
     margin-right: 15px;
     font-size: 30px;
     text-shadow: 2px 3px 5px rgba(0,0,0,0.15);
   }
   @media (max-width: 992px) {
     .theme-login-social-login-facebook .fa,
     .theme-login-social-login-google .fa {
       float: none;
       margin-bottom: 10px;
       margin-right: 0;
     }
   }
   .theme-login-social-login-facebook span,
   .theme-login-social-login-google span {
     display: table;
     font-size: 12px;
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
   }
   @media (max-width: 992px) {
     .theme-login-social-login-facebook span,
     .theme-login-social-login-google span {
       display: block;
     }
   }
   .theme-login-social-login-facebook {
     background: #3b5998;
   }
   .theme-login-social-login-facebook:hover {
     background: #324c81;
   }
   .theme-login-social-login-google {
     background: #db4437;
   }
   .theme-login-social-login-google:hover {
     background: #c53024;
   }
   .theme-login-social-separator {
     margin-bottom: 20px;
     position: relative;
   }
   .theme-login-social-separator:before {
     content: '';
     height: 1px;
     width: 100%;
     position: absolute;
     left: 0;
     top: 50%;
     background: #000;
     z-index: 1;
     opacity: 0.17;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=17)";
     filter: alpha(opacity=17);
   }
   .theme-login-social-separator > p {
     display: table;
     margin: 0 auto;
     background: #fff;
     position: relative;
     z-index: 2;
     padding: 0 7px;
     color: #8c8c8c;
     font-size: 14px;
   }
   .theme-login-pwd-reset-text {
     margin-bottom: 20px;
     font-size: 16px;
     line-height: 1.6em;
     color: #727272;
   }
   .theme-login-pwd-reset-back {
     margin-top: 20px;
     display: block;
     color: #616161;
     font-size: 14px;
   }
   .theme-login-pwd-reset-back:hover {
     color: #4c4c4c;
   }
   .theme-login-checkbox .icheck {
     top: 3px;
     width: 16px;
     height: 16px;
     border: 1px solid #666;
     background: none;
   }
   .theme-login-checkbox .icheck.checked {
     background: #0093d2;
     border-color: #0093d2;
   }
   .theme-login-checkbox .icheck:after {
     display: none;
   }
   .theme-login-checkbox .icheck:before {
     height: 16px;
     line-height: 14px;
   }
   .theme-login-checkbox .icheck-title {
     font-weight: normal;
     font-size: 15px;
   }
   .theme-login-checkbox .icheck-sub-title {
     font-size: 13px;
   }
   /*  --/LOGIN--  */
   /*  --PAYMENT--  */
   .theme-payment-success-header {
     text-align: center;
     padding: 0 30px;
     margin-bottom: 20px;
   }
   .theme-payment-success-title {
     font-size: 32px;
     line-height: 1em;
     margin-top: 0;
     margin-bottom: 5px;
     font-weight: 300;
   }
   .theme-payment-success-subtitle {
     margin-bottom: 0;
     color: #8c8c8c;
   }
   .theme-payment-success-header-icon {
     font-size: 70px;
     display: block;
     line-height: 1em;
     margin-bottom: 15px;
     color: var(--craneGreenLight);
     text-shadow: 2px 5px 10px rgba(210,63,0,0.3);
   }
   .theme-payment-success-box {
     -webkit-box-shadow: 0 10px 45px rgba(0,0,0,0.15) , 0 2px 4px rgba(0,0,0,0.1);
     box-shadow: 0 10px 45px rgba(0,0,0,0.15) , 0 2px 4px rgba(0,0,0,0.1);
     background: #fff;
     padding: 35px 30px;
   }
   .theme-payment-success-summary {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
   }
   .theme-payment-success-summary > li {
     padding-bottom: 10px;
     margin-bottom: 10px;
     border-bottom: 1px dashed #d9d9d9;
     position: relative;
     color: #808080;
   }
   .theme-payment-success-summary > li > span {
     color: #4d4d4d;
     position: absolute;
     top: 0;
     right: 0;
     font-weight: bold;
   }
   .theme-payment-success-check-order {
     font-size: 12px;
     margin-top: 30px;
     margin-bottom: 0;
     color: #999;
   }
   .theme-payment-success-actions {
     list-style: none;
     margin: 0 auto;
     margin-top: 20px;
     padding: 0;
     overflow: hidden;
     display: table;
   }
   .theme-payment-success-actions > li {
     float: left;
     margin-right: 30px;
   }
   .theme-payment-success-actions > li:last-child {
     margin-right: 0;
   }
   .theme-payment-success-actions > li > a {
     display: block;
     text-decoration: none !important;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     color: #595959;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-payment-success-actions > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-payment-success-actions > li .fa {
     float: left;
     margin-right: 10px;
     font-size: 30px;
     display: block;
     line-height: 1em;
   }
   .theme-payment-success-actions > li p {
     display: table;
     margin-bottom: 0;
     font-size: 12px;
     line-height: 1.15em;
     position: relative;
     top: 3px;
   }
   /*  --/PAYMENT--  */
   /*  --LOADING--  */
   .theme-loading {
     color: #fff;
   }
   .theme-loading-spinner > div {
     margin: 0 auto;
   }
   .theme-loading-body {
     text-align: center;
   }
   .theme-loading-title {
     font-size: 60px;
     margin: 30px 0;
     font-weight: 300;
   }
   @media (max-width: 992px) {
     .theme-loading-title {
       font-size: 30px;
     }
   }
   .theme-loading-subtitle {
     font-size: 30px;
     margin-bottom: 0;
     line-height: 1.2em;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   @media (max-width: 992px) {
     .theme-loading-subtitle {
       font-size: 20px;
     }
   }
   /*  --/LOADING--  */
   /*  --ELEMENTS--  */
   html,
   body {
     overflow-x: hidden;
   }
   .theme-reviews-more {
     color: #595959;
     font-size: 13px;
     font-weight: 700;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-reviews-more:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     text-decoration: none;
     color: #595959;
   }
   .theme-reviews-list-scored {
     margin-top: 50px;
   }
   .theme-reviews-item {
     margin-bottom: 20px;
   }
   .theme-reviews-item-body {
     padding-bottom: 20px;
     border-bottom: 1px solid #f2f2f2;
   }
   .theme-reviews-item-date {
     font-size: 11px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 4px;
   }
   .theme-reviews-item-author {
     font-size: 11px;
   }
   .theme-reviews-item-avatar {
     width: 60px;
     display: block;
     margin-bottom: 15px;
     border-radius: 50%;
   }
   .theme-reviews-item-text {
     font-size: 14px;
     line-height: 1.65em;
     margin-bottom: 0;
     color: #616161;
   }
   .theme-reviews-rating-bar {
     height: 4px;
     background: #ccc;
     margin-top: 7px;
     width: 140px;
     margin-bottom: 15px;
   }
   .theme-reviews-rating-bar > div {
     height: 100%;
     background: #0093d2;
   }
   .theme-reviews-rating-num {
     font-weight: 700;
     font-size: 20px;
     margin-bottom: 1px;
     line-height: 1em;
   }
   .theme-reviews-rating-title {
     margin-left: 5px;
     font-size: 11px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     line-height: 1em;
   }
   .theme-reviews-rating-stars {
     list-style: none;
     padding: 0;
     margin: 0;
     margin-right: 3px;
     margin-bottom: 3px;
     font-size: 12px;
   }
   .theme-reviews-rating-stars > li {
     display: inline-block;
     margin-right: 2px;
     color: #ff6c2d;
   }
   .theme-reviews-score-hor {
     margin-bottom: 10px;
   }
   .theme-reviews-score-hor .theme-reviews-score-list {
     margin-top: 40px;
   }
   .is_stuck .theme-reviews-score {
     margin-top: 10px;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-reviews-score-header {
     margin-bottom: 10px;
     text-align: center;
   }
   .theme-reviews-score-title {
     margin-top: 0;
     margin-bottom: 3px;
     letter-spacing: 0;
     font-size: 15px;
   }
   .theme-reviews-score-subtitle {
     font-size: 13px;
     color: #727272;
     margin-bottom: 0;
   }
   .theme-reviews-score-total {
     width: 100%;
     padding-top: 100%;
     background: #666;
     position: relative;
     border-radius: 10px;
     margin-bottom: 30px;
   }
   .theme-reviews-score-total > p {
     color: #fff;
     position: absolute;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     font-size: 70px;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
   }
   .theme-reviews-score-list {
     margin-top: 20px;
   }
   .theme-reviews-score-item {
     margin-bottom: 20px;
   }
   .theme-reviews-score-item-header {
     position: relative;
     margin-bottom: 5px;
   }
   .theme-reviews-score-item-title {
     margin: 0;
     font-size: 14px;
   }
   .theme-reviews-score-item-num {
     font-size: 13px;
     position: absolute;
     top: 0;
     right: 0;
     font-weight: bold;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-reviews-score-item-bar {
     height: 6px;
     position: relative;
     background-color: #e6e6e6;
   }
   .theme-reviews-score-item-bar > div {
     position: absolute;
     height: 100%;
     display: block;
     background-color: #ff6c2d;
     top: 0;
     left: 0;
   }
   .theme-tab-slider > img {
     display: none;
   }
   .theme-tab-slider > img.active {
     display: block;
   }
   .theme-mobile-app-section {
     position: relative;
   }
   @media (min-width: 992px) {
     .theme-mobile-app-section {
       height: 66vh;
     }
   }
   .theme-mobile-app-img {
     padding-top: 40px;
     position: absolute;
     bottom: 0;
     left: 0;
     display: block;
     max-height: 100%;
     max-width: 100%;
   }
   @media (max-width: 992px) {
     .theme-mobile-app-img {
       display: none;
     }
   }
   .theme-mobile-app-body {
     color: #fff;
   }
   @media (min-width: 992px) {
     .theme-mobile-app-body {
       width: 100%;
       top: 50%;
       position: absolute;
       left: 0;
       -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
       -o-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);
     }
   }
   @media (max-width: 992px) {
     .theme-mobile-app-body {
       padding: 50px 0;
     }
   }
   .theme-mobile-app-header {
     margin-bottom: 30px;
   }
   .theme-mobile-app-title {
     font-size: 40px;
     margin-top: 0;
     margin-bottom: 10px;
     font-weight: 300;
   }
   .theme-mobile-app-subtitle {
     font-size: 19px;
     line-height: 1.6em;
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
     margin-bottom: 0;
   }
   .theme-mobile-app-btn-list {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-mobile-app-btn-list > li {
     display: inline-block;
     margin-right: 15px;
   }
   .theme-mobile-app-btn-list > li:last-child {
     margin-right: 0;
   }
   @media (max-width: 992px) {
     .theme-mobile-app-btn-list > li {
       margin-bottom: 10px;
     }
     .theme-mobile-app-btn-list > li:last-child {
       margin-bottom: 0;
     }
   }
   .theme-mobile-app-logo {
     width: 30px;
     display: block;
     float: left;
     margin-right: 17px;
     height: 40px;
     position: relative;
   }
   .theme-mobile-app-logo > img {
     position: absolute;
     width: 100%;
     display: block;
     top: 50%;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
   }
   .theme-mobile-app-btn {
     text-align: left;
     padding: 12px 17px;
     border: none;
     background: #2b2b2b;
     border-radius: 5px;
     -webkit-box-shadow: 0 2px 7px rgba(0,0,0,0.1);
     box-shadow: 0 2px 7px rgba(0,0,0,0.1);
   }
   .theme-mobile-app-btn > span {
     display: table;
     margin-top: 3px;
     font-weight: normal;
     color: rgba(255,255,255,0.8);
   }
   .theme-mobile-app-btn > span > span {
     font-weight: bold;
     color: #fff;
   }
   .theme-inline-slider .owl-prev,
   .theme-inline-slider .owl-next {
     color: #666;
   }
   .theme-inline-slider .owl-prev {
     left: -45px;
   }
   .theme-inline-slider .owl-next {
     right: -45px;
   }
   .theme-inline-slider-item {
     padding-left: 15px;
     padding-right: 15px;
   }
   .row[data-gutter="10"] .theme-inline-slider-item {
     padding-left: 5px;
     padding-right: 5px;
   }
   .row[data-gutter="20"] .theme-inline-slider-item {
     padding-left: 10px;
     padding-right: 10px;
   }
   .row[data-gutter="0"] .theme-inline-slider-item {
     padding-left: 0;
     padding-right: 0;
   }
   .theme-inline-slider-nav-white .owl-prev,
   .theme-inline-slider-nav-white .owl-next {
     color: #fff;
   }
   .theme-abs-logo {
     position: absolute;
     z-index: 6;
     top: 30px;
     left: 50%;
     -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
     -o-transform: translateX(-50%);
     -ms-transform: translateX(-50%);
     transform: translateX(-50%);
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-abs-logo:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-abs-logo > img {
     max-height: 60px;
     max-width: 140px;
   }
   .theme-social-list {
     list-style: none;
     margin: 0;
     padding: 0;
     position: absolute;
     z-index: 6;
     bottom: 30px;
     width: 100%;
     text-align: center;
   }
   .theme-social-list > li {
     display: inline-block;
     margin: 0 10px;
   }
   .theme-social-list > li > .fa {
     display: block;
     height: 35px;
     width: 35px;
     line-height: 35px;
     background: rgba(255,255,255,0.1);
     border-radius: 50%;
     color: #fff;
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     text-shadow: 1px 2px 5px rgba(0,0,0,0.35);
   }
   .theme-social-list > li > .fa:hover {
     background: #0093d2;
   }
   .theme-search-results-map-layout-btn {
     display: block;
     background: #f7f7f7;
     text-align: center;
     padding: 18px 0;
     text-transform: uppercase;
     font-weight: bold;
     color: #7a7a7a;
     text-decoration: none !important;
     font-size: 11px;
     letter-spacing: 0.5px;
     line-height: 1em;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-search-results-map-layout-btn:active,
   .theme-search-results-map-layout-btn:focus {
     color: #7a7a7a;
   }
   .theme-search-results-map-layout-btn:hover {
     background: #0093d2;
     color: #fff;
   }
   .theme-search-results-map-layout-block {
     overflow-y: scroll;
     -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.2);
     box-shadow: 0 0 30px rgba(0,0,0,0.2);
     position: relative;
     z-index: 5;
   }
   .theme-search-results-map-layout-block .theme-search-results-item {
     border-left: none;
     border-right: none;
   }
   .theme-search-results-map-layout-block .theme-search-results-sort {
     padding: 10px 15px;
     margin: 0;
     border: none;
     background: #f7f7f7;
   }
   .theme-search-results-map-layout-block .theme-search-results-sort-alt > a {
     font-weight: bold;
   }
   .theme-search-results-sort {
     padding: 10px 20px;
     margin-bottom: 10px;
     z-index: 15;
     border-radius: 2px;
     border: 1px solid #d9d9d9;
     background: #fff;
   }
   .theme-search-results-sort-title {
     font-size: 13px;
     margin: 0;
     margin-right: 14px;
     float: left;
     font-weight: 400;
     letter-spacing: 0;
     color: #4c4c4c;
   }
   .theme-search-results-sort-list {
     float: left;
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-search-results-sort-list > li {
     float: left;
     border-left: 1px solid #f2f2f2;
   }
   .theme-search-results-sort-list > li:last-child {
     border-right: 1px solid #f2f2f2;
   }
   .theme-search-results-sort-list > li > a {
     padding: 0 17px;
     display: block;
     font-size: 13px;
     color: #737373;
     margin-bottom: 2px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-search-results-sort-list > li > a > span {
     display: block;
     opacity: 0.45;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=45)";
     filter: alpha(opacity=45);
     font-size: 9px;
   }
   .theme-search-results-sort-list > li > a:hover {
     color: #262626;
     text-decoration: none;
   }
   .theme-search-results-sort-list > li.active > a,
   .theme-search-results-sort-list > li.active > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     color: #0093d2;
   }
   .theme-search-results-sort-list > li.active > a > span,
   .theme-search-results-sort-list > li.active > a:hover > span {
     color: #404040;
     font-weight: 400;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-sort-alt {
     float: right;
     font-size: 13px;
   }
   .theme-search-results-sort-alt > a {
     color: #595959;
     text-decoration: none !important;
   }
   .theme-search-results-sort-alt > a .caret {
     margin-top: -1px;
     opacity: 0.55;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=55)";
     filter: alpha(opacity=55);
   }
   .theme-search-results-sort-alt .dropdown-menu {
     left: auto;
     right: -21px;
     top: 25px;
     border-radius: 0;
     border: none;
     min-width: 0;
     -webkit-box-shadow: 0 1px 20px rgba(0,0,0,0.15);
     box-shadow: 0 1px 20px rgba(0,0,0,0.15);
   }
   .theme-search-results-sort-alt .dropdown-menu > li > a {
     font-size: 12px;
     color: #8c8c8c;
     padding: 7px 20px;
   }
   .theme-search-results-sort-alt .dropdown-menu > li > a:hover {
     background: none;
     color: #666;
   }
   .theme-search-results-sort-select {
     position: relative;
     margin-bottom: 20px;
   }
   .theme-search-results-sort-select:after {
     content: '\f107';
     font-family: 'FontAwesome';
     position: absolute;
     top: 50%;
     right: 15px;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
   }
   .theme-search-results-sort-select > select {
     width: 100%;
     display: block;
     background: #fff;
     padding: 15px;
     border: 1px solid #b3b3b3;
     outline: none !important;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
   }
   .theme-search-results-sign-in {
     padding: 25px 20px;
     position: relative;
     background: #0093d2;
     border: none;
     margin-bottom: 10px;
     border-radius: 2px;
   }
   .theme-search-results-sign-in-title {
     margin: 0;
     line-height: 1em;
     font-weight: 400;
     font-size: 12px;
     color: #fff;
     padding-left: 37px;
   }
   .theme-search-results-sign-in-btn {
     position: absolute;
     display: block;
     top: 14px;
     right: 20px;
     font-size: 11px;
     padding: 7px 10px;
     text-transform: uppercase;
   }
   .theme-search-results-sign-in-icon {
     height: 25px;
     width: 25px;
     line-height: 25px;
     display: block;
     color: #fff;
     text-align: center;
     border: 1px solid #fff;
     border-radius: 50%;
     font-size: 12px;
     position: absolute;
     top: 18px;
     left: 20px;
   }
   .theme-search-results-item {
     margin-bottom: -1px;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     z-index: 1;
   }
   .theme-search-results-item.active .theme-search-results-item-extend {
     display: block;
   }
   .theme-search-results-item.active,
   .theme-search-results-item:hover {
     -webkit-box-shadow: 0 2px 20px rgba(0,0,0,0.07);
     box-shadow: 0 2px 20px rgba(0,0,0,0.07);
   }
   .theme-search-results-item.active {
     border-color: #bababa;
     z-index: 2;
   }
   .theme-search-results-item-rounded {
     border-radius: 3px;
   }
   .theme-search-results-item-rounded .theme-search-results-item-img-wrap {
     border-radius: 3px;
     overflow: hidden;
   }
   .theme-search-results-item-rounded .theme-search-results-item-tabs {
     border-radius: 3px;
   }
   .theme-search-results-item-preview {
     padding: 23px 20px;
     position: relative;
   }
   .theme-search-results-item-mask-link {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     display: block;
     z-index: 5;
   }
   .theme-search-results-item-discount-label {
     position: absolute;
     top: 7px;
     left: -3px;
     padding: 7px;
     line-height: 1em;
     font-weight: 600;
     font-size: 12px;
     background: #0093d2;
     color: #fff;
     -webkit-box-shadow: 1px 2px 4px rgba(0,0,0,0.3);
     box-shadow: 1px 2px 4px rgba(0,0,0,0.3);
   }
   .theme-search-results-item-title,
   .theme-search-results-item-title-sm {
     font-weight: 400;
     margin-top: 0;
     letter-spacing: 0;
     font-size: 18px;
     margin-bottom: 7px;
     line-height: 1.3em;
     color: #000;
   }
   .theme-search-results-item-title-sm {
     font-size: 16px;
   }
   .theme-search-results-item-desc {
     font-size: 12px;
     color: #8c8c8c;
     margin-bottom: 0;
   }
   .theme-search-results-item-location {
     font-size: 13px;
     margin-bottom: 7px;
   }
   .theme-search-results-item-location .fa {
     margin-right: 3px;
   }
   .theme-search-results-item-book {
     position: relative;
     height: 100%;
   }
   .theme-search-results-item-bs {
     -webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.1);
     box-shadow: 0 3px 5px rgba(0,0,0,0.1);
   }
   .theme-search-results-item-bs:hover {
     -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.15);
     box-shadow: 0 10px 20px rgba(0,0,0,0.15);
   }
   .theme-search-results-item-lift:hover {
     -webkit-transform: translateY(-2px);
     -moz-transform: translateY(-2px);
     -o-transform: translateY(-2px);
     -ms-transform: translateY(-2px);
     transform: translateY(-2px);
   }
   .theme-search-results-item-category {
     font-size: 10px;
     text-transform: uppercase;
     color: #737373;
     margin-bottom: 10px;
     line-height: 1em;
     letter-spacing: 0.5px;
   }
   .theme-search-results-item-full {
     border: none;
   }
   .theme-search-results-item-full.active {
     border-color: #bababa;
   }
   .theme-search-results-item-full.active .theme-search-results-item-body {
     background: #fafafa;
   }
   .theme-search-results-item-full .theme-search-results-item-preview {
     padding: 0;
   }
   .theme-search-results-item-full .theme-search-results-item-tabs {
     margin-top: 15px;
   }
   .theme-search-results-item-full .theme-search-results-item-title {
     height: 46px;
     overflow: hidden;
     margin-bottom: 20px;
   }
   .theme-search-results-item-img-full {
     height: 100%;
   }
   .theme-search-results-item-body {
     padding: 25px 23px;
     -webkit-transition: 0.2s;
     -moz-transition: 0.2s;
     -o-transition: 0.2s;
     -ms-transition: 0.2s;
     transition: 0.2s;
   }
   .theme-search-results-item-sm {
     padding: 15px;
   }
   .theme-search-results-item-sm .theme-search-results-item-title {
     font-size: 13px;
     line-height: 1.3em;
     margin-bottom: 4px;
   }
   .theme-search-results-item-sm .theme-search-results-item-hotel-rating {
     font-size: 10px;
     margin-bottom: 0;
   }
   .theme-search-results-item-sm .theme-search-results-item-hotel-stars > li {
     font-size: 6px;
     margin-right: 1.5px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-item-sm .theme-search-results-item-price {
     text-align: left;
     margin-bottom: 0;
     line-height: 1em;
   }
   .theme-search-results-item-sm .theme-search-results-item-price-tag {
     display: inline;
     font-size: 11px;
     margin-right: 3px;
   }
   .theme-search-results-item-sm .theme-search-results-item-price-sign {
     display: inline;
     font-size: 10px;
   }
   .theme-search-results-item-sm .theme-search-results-item-car-company-logo {
     max-width: 40px;
   }
   .theme-search-results-item-sm .theme-search-results-item-rating {
     font-size: 10px;
     margin-bottom: 3px;
     margin-top: 2px;
   }
   .theme-search-results-item-sm .theme-search-results-item-rating .theme-search-results-item-stars > li {
     font-size: 10px;
     margin-right: 2px;
   }
   .theme-search-results-item-sm .theme-search-results-item-rating .theme-search-results-item-stars > li.active {
     color: #4d4d4d;
   }
   .theme-search-results-item-rating {
     font-size: 10px;
     margin-bottom: 0;
     margin-top: 2px;
   }
   .theme-search-results-item-rating .theme-search-results-item-stars > li {
     font-size: 10px;
     margin-right: 2px;
   }
   .theme-search-results-item-rating .theme-search-results-item-stars > li.active {
     color: #4d4d4d;
   }
   .theme-search-results-item-rating .theme-search-results-item-car-company-logo {
     max-width: 30px;
     margin-bottom: 5px;
   }
   .theme-search-results-item-stars {
     list-style: none;
     margin: 0;
     padding: 0;
     float: left;
     margin-right: 10px;
   }
   .theme-search-results-item-stars > li {
     display: inline-block;
     margin-right: 3px;
     font-size: 11px;
     color: #ccc;
   }
   .theme-search-results-item-stars > li.active {
     color: #595959;
   }
   .theme-search-results-item-rating-sm {
     font-size: 11px;
     margin-bottom: 7px;
     overflow: hidden;
   }
   .theme-search-results-item-rating-sm .theme-search-results-item-stars {
     margin-right: 4px;
   }
   .theme-search-results-item-rating-sm .theme-search-results-item-stars > li.active {
     color: #0093d2;
   }
   .theme-search-results-item-rating {
     font-size: 11px;
     margin-bottom: 7px;
     overflow: hidden;
   }
   .theme-search-results-item-rating-title {
     margin-bottom: 0;
     font-weight: bold;
   }
   .theme-search-results-item-rating-stars {
     list-style: none;
     margin: 0;
     margin-right: 4px;
     padding: 0;
     float: left;
   }
   .theme-search-results-item-rating-stars > li {
     display: inline-block;
     margin-right: 3px;
     font-size: 11px;
     color: #ccc;
   }
   .theme-search-results-item-rating-stars > li.active {
     color: #0093d2;
   }
   .theme-search-results-item-rating-sm-title {
     margin-bottom: 0;
     font-weight: bold;
   }
   .theme-search-results-item-bookmark {
     position: relative;
     z-index: 15;
     color: #8c8c8c;
     text-align: center;
     margin-top: 10px;
     display: block;
     -webkit-transition: 0.2s;
     -moz-transition: 0.2s;
     -o-transition: 0.2s;
     -ms-transition: 0.2s;
     transition: 0.2s;
   }
   .theme-search-results-item-bookmark:hover {
     text-decoration: none;
     color: #0093d2;
   }
   .theme-search-results-item-bookmark:active,
   .theme-search-results-item-bookmark:focus {
     text-decoration: none;
     color: #8c8c8c;
   }
   .theme-search-results-item-bookmark .fa {
     margin-right: 5px;
     font-size: 15px;
   }
   .theme-search-results-item-bookmark span {
     font-size: 10px;
     position: relative;
     top: -1.5px;
   }
   .theme-search-results-item-bookmark.active {
     color: #0093d2;
     text-decoration: none;
   }
   .theme-search-results-item-bookmark-bottom {
     position: absolute;
     bottom: 0;
     width: 100%;
   }
   .theme-search-results-item-bookmark-top {
     position: absolute;
     margin-top: 0;
     top: 0px;
     left: 5px;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .theme-search-results-item-bookmark-top:hover {
     color: #ff6c2d;
   }
   .theme-search-results-item-bookmark-top .fa {
     font-size: 15px;
   }
   .theme-search-results-item-bookmark-top span {
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
     top: -1px;
     font-size: 10px;
   }
   .theme-search-results-item-bookmark-top.active {
     color: #ff6c2d;
     top: -6px;
     opacity: 1;
     -ms-filter: none;
     filter: none;
     text-shadow: 0 1px 2px rgba(0,0,0,0.2);
   }
   .theme-search-results-item-bookmark-top.active span {
     display: none;
   }
   .theme-search-results-item:hover .theme-search-results-item-bookmark-top {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-bookmark-footer {
     padding: 15px 27px;
     border-top: 1px solid #ededed;
     background: #fafafa;
     font-size: 11px;
   }
   .theme-search-results-item-bookmark-footer-title {
     margin-bottom: 0;
     color: #727272;
   }
   .theme-search-results-item-bookmark-footer-title > b {
     color: #595959;
   }
   .theme-search-results-item-bookmark-actions {
     list-style: none;
     margin: 0;
     padding: 0;
     text-align: right;
   }
   .theme-search-results-item-bookmark-actions > li {
     display: inline-block;
     margin-left: 15px;
   }
   .theme-search-results-item-bookmark-actions > li > a {
     text-decoration: none !important;
     color: #595959;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-search-results-item-bookmark-actions > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-bookmark-actions > li > a > .fa {
     margin-right: 5px;
   }
   .theme-search-results-item-extend {
     position: relative;
   }
   .theme-search-results-item-extend-inner {
     padding: 23px 27px;
     padding-top: 30px;
   }
   .theme-search-results-item-extend-close {
     position: absolute;
     top: 0;
     right: 0;
     text-align: center;
     display: block;
     padding: 0 23px;
     color: #595959;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     font-family: Arial, Baskrville, monospace;
   }
   .theme-search-results-item-extend-close:hover {
     text-decoration: none;
     color: #595959;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-img {
     width: 100%;
   }
   .theme-search-results-item-img-wrap {
     position: relative;
   }
   .theme-search-results-item-img-wrap-inner {
     position: relative;
     margin: 10px 20px;
   }
   .theme-search-results-item-img-wrap-inner > img {
     position: absolute;
     max-width: 100%;
     max-height: 100%;
     top: 50%;
     left: 50%;
     -webkit-transform: translate3d(-50%, -50%, 0);
     -moz-transform: translate3d(-50%, -50%, 0);
     -o-transform: translate3d(-50%, -50%, 0);
     -ms-transform: translate3d(-50%, -50%, 0);
     transform: translate3d(-50%, -50%, 0);
     width: auto;
   }
   .theme-search-results-item-price {
     text-align: center;
     margin-bottom: 10px;
   }
   .theme-search-results-item-price-tag {
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     font-size: 22px;
     margin-bottom: 0;
     line-height: 1em;
   }
   .theme-search-results-item-price-sign {
     font-size: 10px;
     color: #b3b3b3;
     margin-bottom: 0;
     margin-top: 2px;
   }
   .theme-search-results-item-price-btn {
     padding: 7px 0;
     border-radius: 2px;
     font-weight: 700;
     font-size: 11px;
     position: relative;
     z-index: 10;
   }
   .theme-search-results-item-grid {
     border: none;
     overflow: hidden;
   }
   .theme-search-results-item-grid .theme-search-results-item-title {
     font-size: 13px;
     height: 34px;
     overflow: hidden;
     margin-bottom: 0;
     font-weight: bold;
   }
   .theme-search-results-item-grid .theme-search-results-item-hotel-rating {
     font-size: 11px;
     margin-bottom: 0;
   }
   .theme-search-results-item-grid .theme-search-results-item-hotel-rating b {
     font-size: 13px;
   }
   .theme-search-results-item-grid .theme-search-results-item-price {
     margin-bottom: 0;
     text-align: right;
   }
   .theme-search-results-item-grid .theme-search-results-item-price-body {
     text-align: center;
   }
   .theme-search-results-item-grid .theme-search-results-item-location {
     margin-bottom: 0;
     font-size: 11px;
     color: #8c8c8c;
   }
   .theme-search-results-item-grid .theme-search-results-item-price-tag {
     font-size: 15px;
     line-height: 1em;
     font-weight: normal;
   }
   .theme-search-results-item-grid .theme-search-results-item-price-sign {
     font-size: 9px;
   }
   .theme-search-results-item-grid .theme-search-results-item-hotel-stars {
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 2px;
   }
   .theme-search-results-item-grid .theme-search-results-item-car-location {
     margin-top: 0;
   }
   .theme-search-results-item-grid .theme-search-results-item-stars {
     float: none;
     margin-right: 0;
   }
   .theme-search-results-item-grid .theme-search-results-item-room-feature-list {
     font-size: 12px;
   }
   .theme-search-results-item-grid .theme-search-results-item-room-feature-list > li {
     margin-right: 0;
     display: block;
     margin-bottom: 1px;
   }
   .theme-search-results-item-grid .theme-search-results-item-room-feature-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-search-results-item-grid .theme-search-results-item-room-feature-list > li:after {
     display: none;
   }
   .theme-search-results-item-grid .theme-search-results-item-room-feature-list-icon {
     display: inline-block;
     width: 13px;
     margin-right: 5px;
   }
   .theme-search-results-item-grid-caption {
     margin-top: 7px;
     padding-top: 12px;
     border-top: 1px solid #ededed;
   }
   .theme-search-results-item-grid-caption .theme-search-results-item-rating {
     margin: 0;
   }
   .theme-search-results-item-grid-caption .theme-search-results-item-rating-title {
     font-weight: normal;
   }
   .theme-search-results-item-grid-caption .theme-search-results-item-rating-stars {
     float: none;
     margin-right: 0;
     margin-bottom: 1px;
   }
   .theme-search-results-item-grid-caption .theme-search-results-item-rating-stars > li {
     font-size: 10px;
   }
   .theme-search-results-item-grid-img {
     height: 18vh;
   }
   .theme-search-results-item-grid-body {
     padding: 20px;
   }
   .theme-search-results-item-grid-body-car {
     padding-top: 0;
   }
   .theme-search-results-item-grid-body-car .theme-search-results-item-title {
     padding-right: 60px;
     position: relative;
   }
   .theme-search-results-item-grid-body-car .theme-search-results-item-car-company-logo {
     position: absolute;
     right: 0;
     top: 2px;
     max-width: 50px;
     max-height: 100%;
   }
   .theme-search-results-item-grid-header-logo {
     position: relative;
   }
   .theme-search-results-item-grid-header-logo .theme-search-results-item-title {
     padding-right: 60px;
     position: relative;
   }
   .theme-search-results-item-grid-header-logo .theme-search-results-item-car-company-logo {
     position: absolute;
     right: 0;
     top: 0;
     max-width: 50px;
     max-height: 100%;
   }
   .theme-search-results-item-grid-car-img-wrap {
     height: 18vh;
     position: relative;
     margin: 0 20px;
   }
   .theme-search-results-item-grid-car-img-wrap > img {
     position: absolute;
     max-width: 100%;
     max-height: 100%;
     top: 50%;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
   }
   .theme-search-results-item-grid-exp-time {
     font-size: 12px;
     margin-bottom: 7px;
   }
   .theme-search-results-item-grid-inline {
     background: none;
   }
   .theme-search-results-item-grid-inline:hover {
     -webkit-box-shadow: none;
     box-shadow: none;
   }
   .theme-search-results-item-grid-inline:hover .theme-search-results-item-grid-body-car .theme-search-results-item-car-company-logo {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-img {
     border-radius: 2px;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-body {
     margin-top: 15px;
     padding: 0;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-title {
     height: auto;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-caption {
     border: none;
     padding: 0;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-stars {
     float: left;
     margin-right: 4px;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-car-img-wrap {
     margin: 0 10px;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-body-car .theme-search-results-item-car-feature-list {
     margin-top: 5px;
   }
   .theme-search-results-item-grid-inline .theme-search-results-item-grid-body-car .theme-search-results-item-car-company-logo {
     opacity: 0.33;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
     filter: alpha(opacity=33);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     right: 10px;
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .banner-caption {
     padding-top: 120px;
     background: -webkit-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -moz-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -o-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: -ms-linear-gradient(top, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
     background: linear-gradient(to bottom, rgba(0,0,0,0.01), rgba(0,0,0,0.7));
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-title {
     color: #fff;
     height: auto;
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-hotel-stars {
     color: #fff;
     opacity: 0.86;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=86)";
     filter: alpha(opacity=86);
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-grid-caption {
     border-color: rgba(255,255,255,0.3);
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-hotel-rating {
     color: rgba(255,255,255,0.4);
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-hotel-rating-title {
     color: #fff;
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-hotel-rating-title > b {
     color: #0093d2;
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-price-tag {
     color: #fff;
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-rating-stars > li {
     color: #fff;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-item-grid .theme-search-results-item-grid-body-full .theme-search-results-item-rating-stars > li.active {
     color: #1ebbff;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-tabs {
     background: #fff;
   }
   .theme-search-results-item-tabs .nav-tabs {
     padding: 0 20px;
     border-color: #f0f0f0;
   }
   .theme-search-results-item-tabs .nav-tabs > li {
     margin: 0 10px;
   }
   .theme-search-results-item-tabs .nav-tabs > li:first-child {
     margin-left: 0;
   }
   .theme-search-results-item-tabs .nav-tabs > li:last-child {
     margin-right: 0;
   }
   .theme-search-results-item-tabs .nav-tabs > li > a {
     border: none !important;
     outline: none !important;
     background: none !important;
     font-size: 13px;
     color: #595959;
     padding: 0;
     padding-bottom: 7px;
     display: block;
     position: relative;
   }
   .theme-search-results-item-tabs .nav-tabs > li.active > a {
     background: none;
     color: #0093d2;
   }
   .theme-search-results-item-tabs .nav-tabs > li.active > a:after {
     content: '';
     position: absolute;
     bottom: -1px;
     left: 0;
     width: 100%;
     height: 2px;
     background: #0093d2;
   }
   .theme-search-results-item-tabs .tab-content {
     padding: 20px 20px;
   }
   .theme-search-results-item-tabs-gallery {
     list-style: none;
     margin: 0;
     padding: 0;
     overflow: hidden;
     margin-right: -5px;
     margin-bottom: -5px;
   }
   .theme-search-results-item-tabs-gallery > li {
     float: left;
     width: 25%;
   }
   .theme-search-results-item-tabs-gallery > li > a {
     display: block;
     padding-right: 5px;
     padding-bottom: 5px;
   }
   .theme-search-results-item-tabs-gallery > li > a > img {
     width: 100%;
   }
   .theme-search-results-item-tabs-details-desc {
     font-size: 13px;
     line-height: 1.6em;
     margin-bottom: 20px;
   }
   .theme-search-results-item-tabs-details-table {
     width: 100%;
     font-size: 13px;
   }
   .theme-search-results-item-tabs-details-table th {
     height: 16px;
     line-height: 16px;
     font-weight: 400;
     padding-bottom: 10px;
     padding-right: 10px;
   }
   .theme-search-results-item-tabs-details-table-title {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-search-results-item-tabs-details-table-value {
     width: 50%;
     height: 16px;
   }
   .theme-search-results-item-tabs-details-table-value-bar {
     background: #e6e6e6;
     width: 100%;
     height: 10px;
     margin-top: 3px;
   }
   .theme-search-results-item-tabs-details-table-value-bar > div {
     height: 100%;
     background: #0093d2;
   }
   .theme-search-results-item-tabs-map {
     border: 1px solid #ccc;
   }
   .theme-search-results-item-tabs-map-title {
     margin-top: 0;
     font-size: 16px;
   }
   .theme-search-results-item-tabs-map-phone {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     font-size: 14px;
     margin-bottom: 30px;
   }
   .theme-search-results-item-tabs-map-rates {
     list-style: none;
     margin: 0;
     padding: 0;
     margin-bottom: -20px;
   }
   .theme-search-results-item-tabs-map-rates > li {
     margin-bottom: 20px;
   }
   .theme-search-results-item-tabs-map-rates-icon {
     float: left;
     width: 35px;
     height: 35px;
     line-height: 35px;
     text-align: center;
     background: #e6e6e6;
     color: #0093d2;
     border-radius: 50%;
     margin-right: 10px;
   }
   .theme-search-results-item-tabs-map-rates-title {
     font-weight: 400;
     margin-top: 0;
     margin-bottom: 2px;
   }
   .theme-search-results-item-tabs-map-rates-sign {
     margin-bottom: 0;
     font-size: 11px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-search-results-item-tabs-similar-item {
     position: relative;
     background: #fff;
     z-index: 1;
     border: 1px solid #d9d9d9;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-search-results-item-tabs-similar-item:hover {
     -webkit-box-shadow: 0 1px 10px rgba(0,0,0,0.15);
     box-shadow: 0 1px 10px rgba(0,0,0,0.15);
   }
   .theme-search-results-item-tabs-similar-item-link {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     display: block;
     z-index: 2;
   }
   .theme-search-results-item-tabs-similar-item-img {
     width: 100%;
   }
   .theme-search-results-item-tabs-similar-item-caption {
     padding: 10px 15px;
   }
   .theme-search-results-item-tabs-similar-item-price {
     margin-bottom: 0;
     font-weight: 700;
     font-size: 17px;
   }
   .theme-search-results-item-tabs-similar-item-title {
     margin-top: 0;
     margin-bottom: 10px;
     font-size: 12px;
     font-weight: 400;
     height: 27px;
     overflow: hidden;
   }
   .theme-search-results-item-tabs-similar-item-book {
     font-size: 11px;
   }
   .theme-search-results-item-tabs-similar-item-stars {
     list-style: none;
     margin: 0;
     padding: 0;
     overflow: hidden;
     margin-bottom: 8px;
   }
   .theme-search-results-item-tabs-similar-item-stars > li {
     float: left;
     margin-right: 3px;
     font-size: 10px;
     opacity: 0.33;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
     filter: alpha(opacity=33);
   }
   .theme-search-results-item-tabs-similar-item-stars > li.active {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-search-results-item-flight-airline-title {
     font-family: helvetica, Arial, sans-serif;
     font-size: 11px;
     margin-top: 0;
     font-weight: 400;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     margin-bottom: 15px;
   }
   .theme-search-results-item-flight-airline-logo {
     display: block;
     width: 100%;
   }
   .theme-search-results-item-flight-sections .theme-search-results-item-flight-section {
     margin-bottom: 20px;
     padding-bottom: 20px;
     border-bottom: 1px dashed #e6e6e6;
   }
   @media (min-width: 992px) {
     .theme-search-results-item-flight-sections .theme-search-results-item-flight-section:last-child {
       margin-bottom: 0;
       padding-bottom: 0;
       border: none;
     }
   }
   .theme-search-results-item-flight-section-airline-logo-wrap {
     position: relative;
     height: 100%;
   }
   .theme-search-results-item-flight-section-airline-logo {
     display: block;
     max-width: 67%;
     max-height: 50px;
     top: 50%;
     left: 50%;
     position: absolute;
     -webkit-transform: translate3d(-50%, -50%, 0);
     -moz-transform: translate3d(-50%, -50%, 0);
     -o-transform: translate3d(-50%, -50%, 0);
     -ms-transform: translate3d(-50%, -50%, 0);
     transform: translate3d(-50%, -50%, 0);
   }
   @media (max-width: 992px) {
     .theme-search-results-item-flight-section-airline-logo {
       position: relative;
       width: 50px;
       max-height: none;
       margin-right: auto;
       margin-left: auto;
       margin-bottom: 20px;
       top: 0;
       left: 0;
       -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
       -o-transform: translate3d(0, 0, 0);
       -ms-transform: translate3d(0, 0, 0);
       transform: translate3d(0, 0, 0);
     }
   }
   @media (max-width: 992px) {
     .theme-search-results-item-flight-section-meta {
       display: table;
       margin: 0 auto;
       margin-bottom: 20px;
     }
     .theme-search-results-item-flight-section-meta > p {
       display: inline-block;
       margin: 0 7px;
     }
   }
   .theme-search-results-item-flight-section-item {
     padding: 0 15px;
   }
   .theme-search-results-item-flight-section-airline-title {
     font-family: helvetica, Arial, sans-serif;
     font-size: 10px;
     font-weight: 400;
     opacity: 0.4;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
     filter: alpha(opacity=40);
     margin-bottom: 0;
     margin-top: 15px;
   }
   @media (max-width: 992px) {
     .theme-search-results-item-flight-section-airline-title {
       text-align: center;
       margin-top: 10px;
       font-size: 12px;
       opacity: 0.65;
       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
       filter: alpha(opacity=65);
     }
   }
   .theme-search-results-item-flight-section-meta-time {
     font-size: 17px;
     font-weight: 700;
     margin-bottom: 6px;
     line-height: 1em;
     text-transform: uppercase;
   }
   .theme-search-results-item-flight-section-meta-time > span {
     font-weight: 400;
     font-size: 10px;
     margin-left: 2px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     line-height: 1em;
   }
   .theme-search-results-item-flight-section-meta-city {
     font-size: 13px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 2px;
   }
   .theme-search-results-item-flight-section-meta-date {
     font-size: 10px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 0;
   }
   .theme-search-results-item-flight-section-path {
     position: relative;
     height: 60px;
     margin: 0 5px;
   }
   @media (max-width: 992px) {
     .theme-search-results-item-flight-section-path {
       margin-bottom: 20px;
     }
   }
   .theme-search-results-item-flight-section-path-line {
     position: absolute;
     top: 30px;
     left: 0;
     width: 100%;
     height: 2px;
     background: #dedede;
   }
   .theme-search-results-item-flight-section-path-icon {
     margin-bottom: 9px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
   }
   .theme-search-results-item-flight-section-path-line-start,
   .theme-search-results-item-flight-section-path-line-end,
   .theme-search-results-item-flight-section-path-line-middle,
   .theme-search-results-item-flight-section-path-line-middle-1,
   .theme-search-results-item-flight-section-path-line-middle-2 {
     position: absolute;
     left: -15px;
     text-align: center;
     width: 30px;
   }
   .theme-search-results-item-flight-section-path-line-end {
     right: -15px;
     left: auto;
   }
   .theme-search-results-item-flight-section-path-line-end .theme-search-results-item-flight-section-path-icon {
     -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
     -o-transform: rotate(90deg);
     -ms-transform: rotate(90deg);
     transform: rotate(90deg);
   }
   .theme-search-results-item-flight-section-path-line-middle,
   .theme-search-results-item-flight-section-path-line-middle-1,
   .theme-search-results-item-flight-section-path-line-middle-2 {
     left: 50%;
     -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
     -o-transform: translateX(-50%);
     -ms-transform: translateX(-50%);
     transform: translateX(-50%);
   }
   .theme-search-results-item-flight-section-path-line-middle .theme-search-results-item-flight-section-path-line-dot,
   .theme-search-results-item-flight-section-path-line-middle-1 .theme-search-results-item-flight-section-path-line-dot,
   .theme-search-results-item-flight-section-path-line-middle-2 .theme-search-results-item-flight-section-path-line-dot {
     background: #999;
   }
   .theme-search-results-item-flight-section-path-line-middle .theme-search-results-item-flight-section-path-icon,
   .theme-search-results-item-flight-section-path-line-middle-1 .theme-search-results-item-flight-section-path-icon,
   .theme-search-results-item-flight-section-path-line-middle-2 .theme-search-results-item-flight-section-path-icon {
     visibility: hidden;
   }
   .theme-search-results-item-flight-section-path-line-middle-1 {
     left: 33%;
     -webkit-transform: traslateX(-33%);
     -moz-transform: traslateX(-33%);
     -o-transform: traslateX(-33%);
     -ms-transform: traslateX(-33%);
     transform: traslateX(-33%);
   }
   .theme-search-results-item-flight-section-path-line-middle-2 {
     left: 66%;
     transfrom: translateX(-66%);
   }
   .theme-search-results-item-flight-section-path-line-dot {
     width: 8px;
     height: 8px;
     border-radius: 50%;
     background-color: #0093d2;
     margin-bottom: 10px;
     margin-left: 10px;
     -webkit-box-shadow: 0 0 0 2px #fff;
     box-shadow: 0 0 0 2px #fff;
   }
   .theme-search-results-item-flight-section-path-line-title {
     font-size: 9px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-search-results-item-flight-section-path-fly-time {
     text-align: center;
     width: 100%;
     position: absolute;
     top: 0;
     left: 0;
   }
   .theme-search-results-item-flight-section-path-fly-time > p {
     font-size: 12px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-search-results-item-flight-section-path-stops-count {
     position: absolute;
     width: 100%;
     text-align: center;
     top: 13px;
     font-size: 9px;
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
   }
   .theme-search-results-item-flight-detail-items .theme-search-results-item-flight-details {
     margin-bottom: 30px;
   }
   .theme-search-results-item-flight-detail-items .theme-search-results-item-flight-details:last-child {
     margin-bottom: 0;
   }
   @media (max-width: 992px) {
     .theme-search-results-item-flight-details-info {
       margin-bottom: 15px;
     }
   }
   .theme-search-results-item-flight-details-info-title {
     text-transform: uppercase;
     letter-spacing: 1.5px;
     font-size: 10px;
     color: #999;
     margin-top: 0;
     margin-bottom: 7px;
   }
   .theme-search-results-item-flight-details-info-date {
     font-weight: bold;
     font-size: 16px;
     margin-bottom: 3px;
     line-height: 1em;
   }
   .theme-search-results-item-flight-details-info-cities {
     font-size: 10px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 10px;
   }
   .theme-search-results-item-flight-details-info-fly-time {
     font-size: 16px;
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
     margin-bottom: 0;
   }
   .theme-search-results-item-flight-details-info-stops {
     font-size: 12px;
     margin-bottom: 0;
     opacity: 0.4;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
     filter: alpha(opacity=40);
   }
   .theme-search-results-item-flight-details-schedule-list {
     list-style: none;
     margin: 0;
     padding-left: 30px;
     position: relative;
   }
   .theme-search-results-item-flight-details-schedule-list:before {
     content: '';
     width: 2px;
     height: 100%;
     background-color: #d9d9d9;
     top: 0;
     left: 0;
     position: absolute;
   }
   .theme-search-results-item-flight-details-schedule-list > li {
     margin-bottom: 30px;
     position: relative;
   }
   .theme-search-results-item-flight-details-schedule-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-search-results-item-flight-details-schedule-icon {
     position: absolute;
     top: 16px;
     left: -42px;
     width: 26px;
     height: 26px;
     line-height: 26px;
     text-align: center;
     background-color: #fff;
     -webkit-box-shadow: 0 0 0 1px #d9d9d9;
     box-shadow: 0 0 0 1px #d9d9d9;
     color: #0093d2;
     border-radius: 50%;
     display: block;
     font-size: 12px;
   }
   .theme-search-results-item-flight-details-schedule-icon.fa-plane {
     -webkit-transform: rotate(-45deg);
     -moz-transform: rotate(-45deg);
     -o-transform: rotate(-45deg);
     -ms-transform: rotate(-45deg);
     transform: rotate(-45deg);
   }
   .theme-search-results-item-flight-details-schedule-icon.fa-plane:before {
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     -ms-backface-visibility: hidden;
     backface-visibility: hidden;
     position: relative;
     top: 1px;
     margin-left: -1px;
   }
   .theme-search-results-item-flight-details-schedule-dots {
     position: absolute;
     top: 29px;
     left: 0;
     width: 100%;
     border-top: 2px dotted #000;
     opacity: 0.1;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
     filter: alpha(opacity=10);
   }
   .theme-search-results-item-flight-details-schedule-date {
     font-size: 10px;
     margin-bottom: 7px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-search-results-item-flight-details-schedule-time {
     font-size: 16px;
     margin-bottom: 7px;
     line-height: 1em;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     background: #fff;
     position: relative;
     display: table;
     z-index: 2;
     padding-right: 10px;
   }
   .theme-search-results-item-flight-details-schedule-time-item {
     font-weight: bold;
     text-transform: uppercase;
   }
   .theme-search-results-item-flight-details-schedule-time-item > span {
     font-weight: normal;
     font-size: 10px;
     margin-left: 1.5px;
   }
   .theme-search-results-item-flight-details-schedule-time-separator {
     padding: 0 6px;
     opacity: 0.3;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
     filter: alpha(opacity=30);
   }
   .theme-search-results-item-flight-details-schedule-fly-time {
     position: absolute;
     top: 20px;
     right: 0;
     font-size: 12px;
     color: #999;
     background-color: #fff;
     padding-left: 10px;
     z-index: 2;
   }
   .theme-search-results-item-flight-details-schedule-transfer {
     font-size: 12px;
     margin-bottom: 2px;
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
   }
   .theme-search-results-item-flight-details-list-body {
     position: relative;
   }
   .theme-search-results-item-flight-details-schedule-destination {
     margin-bottom: 10px;
     overflow: hidden;
   }
   .theme-search-results-item-flight-details-schedule-destination-item,
   .theme-search-results-item-flight-details-schedule-destination-separator {
     float: left;
   }
   .theme-search-results-item-flight-details-schedule-destination-separator {
     margin: 0 10px;
   }
   .theme-search-results-item-flight-details-schedule-destination-separator > span {
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     display: block;
     line-height: 1em;
   }
   .theme-search-results-item-flight-details-schedule-destination-title {
     font-size: 12px;
     margin-bottom: 0;
   }
   .theme-search-results-item-flight-details-schedule-destination-city {
     font-size: 10px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     margin-bottom: 0;
   }
   .theme-search-results-item-flight-details-schedule-features {
     font-size: 12px;
     list-style: none;
     padding: 0;
     margin: 0;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-item-flight-details-schedule-features > li {
     display: inline-block;
     margin-right: 10px;
   }
   .theme-search-results-item-flight-payment-airline {
     margin-bottom: 5px;
     font-size: 12px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-search-results-item-flight-payment-info {
     font-size: 14px;
     margin-bottom: 15px;
   }
   .theme-search-results-item-flight-payment-dates {
     list-style: none;
     margin: 0;
     padding: 0;
     margin-bottom: 15px;
     font-size: 14px;
   }
   .theme-search-results-item-flight-payment-details-link {
     text-transform: uppercase;
     text-decoration: none !important;
     font-size: 12px;
   }
   .theme-search-results-item-hotel-feature-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 12px;
     color: #6a6a6a;
   }
   .theme-search-results-item-hotel-feature-list > li {
     margin-bottom: 4px;
     hieght: 17px;
     line-height: 17px;
     color: #0093d2;
   }
   .theme-search-results-item-hotel-feature-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-search-results-item-hotel-feature-list > li > span {
     display: inline-block;
     font-weight: 700;
     width: 13px;
     text-align: center;
     background: #0093d2;
     color: #fff;
     text-shadow: 0 1px 1px rgba(0,0,0,0.15);
     border-radius: 50%;
     height: 13px;
     line-height: 14px;
     font-size: 8px;
     position: relative;
     top: -2px;
     margin-right: 4px;
   }
   .theme-search-results-item-hotel-features {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 12px;
     color: #6a6a6a;
   }
   .theme-search-results-item-hotel-features > li {
     margin-bottom: 4px;
     hieght: 17px;
     line-height: 17px;
     color: #ff6c2d;
   }
   .theme-search-results-item-hotel-features > li:last-child {
     margin-bottom: 0;
   }
   .theme-search-results-item-hotel-features > li > span {
     display: inline-block;
     font-weight: 700;
     width: 13px;
     text-align: center;
     background: #ff6c2d;
     color: #fff;
     text-shadow: 0 1px 1px rgba(0,0,0,0.15);
     border-radius: 50%;
     height: 13px;
     line-height: 14px;
     font-size: 8px;
     position: relative;
     top: -2px;
     margin-right: 4px;
   }
   .theme-search-results-item-hotel-book-count {
     font-size: 11px;
     margin-bottom: 12px;
     color: #7a7a7a;
   }
   .theme-search-results-item-hotel-rating {
     overflow: hidden;
     font-size: 13px;
     margin-bottom: 7px;
   }
   .theme-search-results-item-hotel-rating-title {
     margin-bottom: 0;
   }
   .theme-search-results-item-hotel-rating-title > b {
     color: #0093d2;
   }
   .theme-search-results-item-hotel-stars {
     list-style: none;
     margin: 0;
     padding: 0;
     overflow: hidden;
     margin-bottom: 1px;
   }
   .theme-search-results-item-hotel-stars > li {
     float: left;
     font-size: 8px;
     opacity: 0.8;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
     filter: alpha(opacity=80);
     margin-right: 3px;
   }
   .theme-search-results-item-full .theme-search-results-item-hotel-book-count {
     margin-bottom: 0;
   }
   .theme-search-results-item-hotel-room-features,
   .theme-search-results-item-hotel-room-options {
     list-style: none;
     margin: 0;
     padding: 0;
     margin-top: 10px;
     font-size: 14px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-search-results-item-hotel-room-features > li,
   .theme-search-results-item-hotel-room-options > li {
     margin-bottom: 5px;
   }
   .theme-search-results-item-hotel-room-features > li:last-child,
   .theme-search-results-item-hotel-room-options > li:last-child {
     margin-bottom: 0;
   }
   @media (max-width: 992px) {
     .theme-search-results-item-hotel-room-options {
       margin-bottom: 15px;
     }
   }
   .theme-search-results-item-hotel-room-features > li > .fa {
     text-align: center;
     margin-right: 7px;
     width: 20px;
     display: inline-block;
   }
   .theme-search-results-item-room-feature-list {
     list-style: none;
     padding: 0;
     font-size: 13px;
     margin-bottom: 7px;
   }
   .theme-search-results-item-room-feature-list > li {
     display: inline-block;
     margin-right: 15px;
     position: relative;
   }
   .theme-search-results-item-room-feature-list > li:after {
     content: '';
     width: 2px;
     height: 2px;
     background: #000;
     position: absolute;
     top: 50%;
     right: -7.5px;
     margin-top: -1px;
     opacity: 0.4;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
     filter: alpha(opacity=40);
   }
   .theme-search-results-item-room-feature-list > li:last-child {
     margin-right: 0;
   }
   .theme-search-results-item-room-feature-list > li:last-child:after {
     display: none;
   }
   .theme-search-results-item-room-feature-list-icon {
     margin-right: 3px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     font-size: 11px;
     position: relative;
     top: -1px;
   }
   .theme-search-results-item-car-feature-list {
     list-style: none;
     margin: 0 auto;
     display: table;
     margin-top: 10px;
     padding: 0;
   }
   .theme-search-results-item-car-feature-list > li {
     display: inline-block;
     margin-right: 14px;
     text-align: center;
   }
   .theme-search-results-item-car-feature-list > li:last-child {
     margin-right: 0;
   }
   .theme-search-results-item-car-feature-list > li .fa {
     display: block;
     font-size: 19px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     margin-bottom: 2px;
   }
   .theme-search-results-item-car-feature-list > li span {
     font-size: 9px;
     display: block;
   }
   .theme-search-results-item-car-location {
     overflow: hidden;
     margin-top: 15px;
   }
   .theme-search-results-item-car-location-icon {
     display: block;
     float: left;
     font-size: 27px;
     margin-right: 10px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-search-results-item-car-location-body {
     display: table;
   }
   .theme-search-results-item-car-location-title {
     font-size: 12px;
     line-height: 1em;
     margin-bottom: 4px;
   }
   .theme-search-results-item-car-location-subtitle {
     font-size: 11px;
     line-height: 1em;
     margin-bottom: 0;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-item-car-company {
     margin-bottom: 5px;
   }
   .theme-search-results-item-car-company-title {
     font-size: 11px;
     margin-bottom: 4px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-item-car-company-logo {
     max-height: 20px;
     max-width: 60px;
   }
   .theme-search-results-item-car-list {
     list-style: none;
     margin: 0;
     padding: 0;
     margin-top: 15px;
     font-size: 12px;
   }
   .theme-search-results-item-car-list > li {
     margin-bottom: 3px;
   }
   .theme-search-results-item-car-list > li .fa {
     margin-right: 5px;
     color: #0093d2;
   }
   .theme-search-results-item-exp-time {
     font-size: 13px;
     margin-bottom: 7px;
   }
   .theme-search-results-mobile-filters {
     position: fixed;
     bottom: 0;
     width: 100%;
     left: 0;
     z-index: 99;
     -webkit-transition: 0.5s;
     -moz-transition: 0.5s;
     -o-transition: 0.5s;
     -ms-transition: 0.5s;
     transition: 0.5s;
     -webkit-transform: translateY(100%);
     -moz-transform: translateY(100%);
     -o-transform: translateY(100%);
     -ms-transform: translateY(100%);
     transform: translateY(100%);
   }
   .theme-search-results-mobile-filters.active {
     -webkit-transform: translateY(0);
     -moz-transform: translateY(0);
     -o-transform: translateY(0);
     -ms-transform: translateY(0);
     transform: translateY(0);
   }
   @media (min-width: 992px) {
     .theme-search-results-mobile-filters {
       display: none;
     }
   }
   .theme-search-results-mobile-filters-btn {
     -webkit-box-shadow: 0 -3px 10px rgba(0,0,0,0.2);
     box-shadow: 0 -3px 10px rgba(0,0,0,0.2);
     background: #0093d2;
     color: #fff !important;
     outline: none !important;
     text-decoration: none !important;
     display: block;
     text-align: center;
     padding: 15px;
     text-transform: uppercase;
     letter-spacing: 0.5px;
     margin: 0 15px;
     font-size: 14px;
   }
   .theme-search-results-mobile-filters-btn .fa {
     margin-right: 7px;
   }
   .theme-search-results-sidebar-map-view {
     position: relative;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
     background-position: center center;
     background-repeat: no-repeat;
     color: #fff;
     padding: 17px 25px;
     border-radius: 2px;
     overflow: hidden;
     margin-bottom: 30px;
   }
   .theme-search-results-sidebar-map-view:hover .theme-search-results-sidebar-map-view-mask {
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-search-results-sidebar-map-view-primary .theme-search-results-sidebar-map-view-mask {
     background: #0093d2;
   }
   .theme-search-results-sidebar-map-view-body {
     position: relative;
     z-index: 2;
     display: table;
     margin: 0 auto;
   }
   .theme-search-results-sidebar-map-view-mask {
     z-index: 1;
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     top: 0;
     left: 0;
     background: #404040;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     -webkit-box-shadow: inset 0 0 30px rgba(0,0,0,0.1);
     box-shadow: inset 0 0 30px rgba(0,0,0,0.1);
     -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
     -o-transform: translateZ(0);
     -ms-transform: translateZ(0);
     transform: translateZ(0);
     -webkit-transition: 0.2s;
     -moz-transition: 0.2s;
     -o-transition: 0.2s;
     -ms-transition: 0.2s;
     transition: 0.2s;
   }
   .theme-search-results-sidebar-map-view-icon {
     display: block;
     margin-right: 10px;
     font-size: 18px;
     text-shadow: 0 1px 2px rgba(0,0,0,0.1);
     float: left;
   }
   .theme-search-results-sidebar-map-view-sign {
     display: table;
     margin-bottom: 0;
     font-size: 11px;
     letter-spacing: 1.5px;
     text-transform: uppercase;
     font-weight: bold;
     position: relative;
     top: 2px;
   }
   .theme-search-results-sidebar-map-view-link {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 3;
     top: 0;
     left: 0;
     display: block;
   }
   .theme-search-results-sidebar-section {
     margin-bottom: 20px;
     padding-bottom: 20px;
     border-bottom: 1px solid;
     border-color: #d9d9d9;
   }
   .theme-search-results-sidebar-section-title {
     margin-top: 0;
     font-size: 13px;
     margin-bottom: 15px;
     letter-spacing: 0.33px;
     text-transform: uppercase;
     color: #555;
   }
   .theme-search-results-sidebar-section-checkbox-list-items {
     margin: 0;
   }
   .theme-search-results-sidebar-section-checkbox-list-item {
     margin: 0;
     padding-bottom: 8px;
   }
   .theme-search-results-sidebar-section-checkbox-list-item label {
     font-size: 14px;
     color: #6a6a6a;
   }
   .theme-search-results-sidebar-section-checkbox-list-item label .icheck {
     margin-bottom: -10px;
     width: 18px;
     height: 18px;
     margin-right: 10px;
   }
   .theme-search-results-sidebar-section-checkbox-list-item label .icheck:before {
     height: 18px;
     line-height: 16px;
   }
   .theme-search-results-sidebar-section-checkbox-list-item + .theme-search-results-sidebar-section-checkbox-list-item {
     margin: 0;
   }
   .theme-search-results-sidebar-section-checkbox-list-items .theme-search-results-sidebar-section-checkbox-list-item:last-child {
     padding-bottom: 0;
   }
   .theme-search-results-sidebar-section-checkbox-list-amount {
     position: absolute;
     top: 0;
     right: 0;
     height: 22px;
     line-height: 22px;
     display: block;
     font-size: 11px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-search-results-sidebar-section-checkbox-list-items-expand {
     padding-top: 8px;
   }
   .theme-search-results-sidebar-section-checkbox-list-expand-link {
     font-size: 11px;
     text-transform: uppercase;
     margin-top: 15px;
     display: block;
     text-decoration: none !important;
     position: relative;
     color: #595959 !important;
   }
   .theme-search-results-sidebar-section-checkbox-list-expand-link .fa {
     font-size: 15px;
     position: absolute;
     right: 0;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     transform: rotate(0deg);
   }
   .theme-search-results-sidebar-section-checkbox-list-expand-link[aria-expanded="true"] .fa {
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -o-transform: rotate(180deg);
     -ms-transform: rotate(180deg);
     transform: rotate(180deg);
   }
   .theme-search-results-sidebar-section-price .irs {
     height: 47px;
     margin-top: -3px;
   }
   .theme-search-results-sidebar-section-price .irs-line,
   .theme-search-results-sidebar-section-price .irs-bar {
     height: 5px;
     -webkit-transition: 0.15s;
     -moz-transition: 0.15s;
     -o-transition: 0.15s;
     -ms-transition: 0.15s;
     transition: 0.15s;
   }
   .theme-search-results-sidebar-section-price .irs-line {
     border: none;
     background: #c7c7c7;
   }
   .theme-search-results-sidebar-section-price .irs-slider {
     height: 20px;
     width: 20px;
     border-radius: 50%;
     border: none;
     background: #fff;
     -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.35);
     box-shadow: 0 1px 5px rgba(0,0,0,0.35);
     -webkit-transition: 0.15s;
     -moz-transition: 0.15s;
     -o-transition: 0.15s;
     -ms-transition: 0.15s;
     transition: 0.15s;
   }
   .theme-search-results-sidebar-section-price .irs-from,
   .theme-search-results-sidebar-section-price .irs-to,
   .theme-search-results-sidebar-section-price .irs-single {
     background: none;
     color: #595959;
     font-size: 13px;
     top: 3px;
     padding: 0;
   }
   .theme-search-results-sidebar-section-price .irs-from {
     margin-left: 4px;
   }
   .theme-search-results-sidebar-section-price .irs-to {
     margin-left: -4px;
   }
   .theme-search-results-sidebar-section-price .irs-min,
   .theme-search-results-sidebar-section-price .irs-max {
     font-size: 12px;
     padding: 0;
     background: none;
     top: 4px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-search-results-sidebar-section-price .irs-slider.state_hover,
   .theme-search-results-sidebar-section-price .irs-slider:hover {
     -webkit-box-shadow: 0 0 0 2px #0093d2;
     box-shadow: 0 0 0 2px #0093d2;
   }
   .theme-search-results-sidebar-section-search {
     position: relative;
     overflow: hidden;
   }
   .theme-search-results-sidebar-section-search-input {
     outline: none !important;
     padding-left: 10px;
     padding-right: 50px;
     display: block;
     width: 100%;
     height: 40px;
     line-height: 40px;
     border: 1px solid #ccc;
     font-size: 14px;
     border-radius: 3px 0 0 3px;
   }
   .theme-search-results-sidebar-section-search-input:focus {
     border-color: #0093d2;
   }
   .theme-search-results-sidebar-section-search-btn {
     height: 40px;
     width: 40px;
     line-height: 40px;
     display: block;
     position: absolute;
     top: 0;
     right: 0;
     background: #0093d2;
     color: #fff;
     text-align: center;
     font-size: 11px;
     border-radius: 0 3px 3px 0;
   }
   .theme-search-results-sidebar-section-search-btn:hover {
     text-decoration: none;
     color: #fff;
   }
   .theme-search-results-sidebar-sections-white-wrap {
     padding: 20px 0;
     background: #fff;
     border: 1px solid #d9d9d9;
   }
   .theme-search-results-sidebar-sections-white-wrap .theme-search-results-sidebar-section {
     border-color: #ededed;
     padding-left: 20px;
     padding-right: 20px;
   }
   .theme-search-results-sidebar-sections-white-wrap .theme-search-results-sidebar-section:last-child {
     margin-bottom: 0;
     padding-bottom: 0;
     border-bottom: none;
   }
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section {
     border-color: rgba(255,255,255,0.2);
   }
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section-checkbox-list-amount {
     color: #fff;
   }
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section-title {
     color: #fff;
   }
   .theme-search-results-sidebar-sections-white .icheck {
     border-color: rgba(255,255,255,0.55);
   }
   .theme-search-results-sidebar-sections-white .icheck.checked {
     border-color: #0093d2;
   }
   .theme-search-results-sidebar-sections-white .icheck-title {
     color: rgba(255,255,255,0.85);
   }
   .theme-search-results-sidebar-sections-white .icheck-sub-title {
     color: #fff;
   }
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section-price .irs-from,
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section-price .irs-to,
   .theme-search-results-sidebar-sections-white .theme-search-results-sidebar-section-price .irs-single {
     color: rgba(255,255,255,0.85);
   }
   .theme-sidebar-section {
     background: #fff;
     padding: 25px 30px;
     border: 1px solid #e6e6e6;
     border-radius: 2px;
   }
   .theme-sidebar-section-title {
     font-size: 14px;
     margin-top: 0;
     margin-bottom: 15px;
   }
   .theme-sidebar-section-weather-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
   }
   .theme-sidebar-section-weather-list > li {
     position: relative;
     margin-bottom: 8px;
     padding-bottom: 8px;
     border-bottom: 1px solid #ededed;
   }
   .theme-sidebar-section-weather-date,
   .theme-sidebar-section-weather-temp {
     margin-bottom: 0;
   }
   .theme-sidebar-section-weather-date {
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-sidebar-section-weather-temp {
     position: absolute;
     top: 0;
     right: 0;
   }
   .theme-sidebar-section-weather-icon {
     margin-right: 7px;
   }
   .theme-sidebar-section-weather-forecast {
     margin-bottom: 0;
     text-align: right;
     font-size: 12px;
   }
   .theme-sidebar-section-weather-forecast > a {
     color: #7a7a7a;
   }
   .theme-sidebar-section-weather-forecast > a:hover,
   .theme-sidebar-section-weather-forecast > a:active,
   .theme-sidebar-section-weather-forecast > a:focus {
     color: #595959;
     text-decoration: none;
   }
   .theme-sidebar-section-summary-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
     color: #808080;
   }
   .theme-sidebar-section-summary-list > li {
     margin-bottom: 5px;
   }
   .theme-sidebar-section-summary-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-sidebar-section-features-list {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-sidebar-section-features-list > li {
     margin-bottom: 22px;
     position: relative;
     padding-left: 20px;
   }
   .theme-sidebar-section-features-list > li:before {
     line-height: 1.4em;
     font-size: 13px;
     position: absolute;
     top: 0;
     left: 0;
     content: '\f00c';
     font-family: 'FontAwesome';
     color: #ff6c2d;
   }
   .theme-sidebar-section-features-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-sidebar-section-features-list-title {
     font-size: 13px;
     margin-bottom: 5px;
     color: #4c4c4c;
     margin-top: 0;
     line-height: 1.4em;
   }
   .theme-sidebar-section-features-list-body {
     font-size: 12px;
     margin: 0;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-sidebar-section-hotel-thumbnail-img {
     float: left;
     margin-right: 15px;
   }
   .theme-sidebar-section-hotel-thumbnail-caption {
     display: table;
   }
   .theme-sidebar-section-hotel-thumbnail-rating {
     line-height: 1em;
     font-size: 13px;
     margin-bottom: 5px;
   }
   .theme-sidebar-section-hotel-thumbnail-address {
     font-size: 12px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 0;
   }
   .theme-sidebar-section-charges-total {
     margin-bottom: 0;
     position: relative;
     padding-top: 10px;
     border-top: 1px solid #d9d9d9;
     font-size: 13px;
   }
   .theme-sidebar-section-charges-total > span {
     position: absolute;
     bottom: 0;
     right: 0;
     font-weight: bold;
   }
   .theme-sidebar-section-charges-list {
     list-style: none;
     margin: 0;
     padding: 0;
     padding-bottom: 10px;
   }
   .theme-sidebar-section-charges-list > li {
     position: relative;
     margin-top: 10px;
     padding-top: 10px;
     border-top: 1px dashed #e6e6e6;
   }
   .theme-sidebar-section-charges-list > li:first-child {
     border-top: none;
     margin-top: 0;
     padding-top: 0;
   }
   .theme-sidebar-section-charges-item-title {
     margin-top: 0;
     margin-bottom: 2px;
     font-weight: normal;
     letter-spacing: 0;
     font-size: 13px;
   }
   .theme-sidebar-section-charges-item-subtitle {
     margin-bottom: 0;
     font-size: 11px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-sidebar-section-charges-item-price {
     position: absolute;
     top: 10px;
     right: 0;
     margin-bottom: 0;
     font-size: 12px;
     font-weight: bold;
     line-height: 1em;
   }
   .theme-ad {
     padding: 5px;
     background: #fff;
     border: 1px solid #e6e6e6;
     border-radius: 2px;
     margin-bottom: 10px;
     position: relative;
     z-index: 1;
   }
   .theme-ad-dark {
     border: none;
     background: #808080;
   }
   .theme-ad-dark .theme-ad-sign {
     color: rgba(255,255,255,0.7);
   }
   .theme-ad-link {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 2;
   }
   .theme-ad-img {
     width: 100%;
   }
   .theme-ad-sign {
     font-size: 9px;
     margin-bottom: 5px;
     color: #a6a6a6;
     line-height: 1em;
   }
   .theme-item-page-desc > p {
     font-size: 16px;
     line-height: 1.6em;
     opacity: 0.64;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=64)";
     filter: alpha(opacity=64);
   }
   .theme-item-page-desc > p:last-child {
     margin-bottom: 0;
   }
   .theme-item-page-header {
     margin-top: 50px;
   }
   .theme-item-page-header-center .theme-item-page-header-title {
     text-align: center;
   }
   .theme-item-page-header-center .theme-item-page-header-stars {
     text-align: center;
   }
   .theme-item-page-header-center .theme-item-page-header-stars > li {
     margin-left: 3.5px;
     margin-right: 3.5px;
   }
   .theme-item-page-header-center .theme-item-page-header-summary {
     text-align: center;
   }
   .theme-item-page-header-center .theme-item-page-header-rating,
   .theme-item-page-header-center .theme-item-page-header-features {
     margin-left: auto;
     margin-right: auto;
     display: table;
   }
   .theme-item-page-header-center .theme-item-page-header-features {
     text-align: center;
   }
   .theme-item-page-header-body {
     position: relative;
   }
   .theme-item-page-header-title {
     margin: 0;
     font-weight: 200;
   }
   .theme-item-page-header-title-sm {
     font-size: 27px;
   }
   .theme-item-page-header-title-xs {
     font-size: 22px;
     line-height: 1.3em;
   }
   .theme-item-page-header-stars {
     list-style: none;
     padding: 0;
     font-size: 10px;
     margin-bottom: 5px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-item-page-header-stars > li {
     display: inline-block;
     margin-right: 5px;
   }
   .theme-item-page-header-summary {
     margin-top: 20px;
     margin-bottom: 0;
     font-size: 17px;
   }
   .theme-item-page-header-summary > a {
     margin-left: 7px;
     font-weight: bold;
   }
   .theme-item-page-header-white .theme-item-page-header-title,
   .theme-item-page-header-white .theme-item-page-header-stars,
   .theme-item-page-header-white .theme-item-page-header-reserve-price,
   .theme-item-page-header-white .theme-item-page-header-price-body,
   .theme-item-page-header-white .theme-item-page-header-summary,
   .theme-item-page-header-white .theme-item-page-header-features,
   .theme-item-page-header-white .theme-item-page-header-rating-title {
     color: #fff;
   }
   .theme-item-page-header-white .theme-item-page-header-features > li {
     border-color: rgba(255,255,255,0.15);
   }
   .theme-item-page-header-features {
     list-style: none;
     margin: 0;
     padding: 0;
     margin-bottom: 30px;
   }
   .theme-item-page-header-features > li {
     border-right: 1px solid #595959;
     display: inline-block;
     margin-right: 20px;
     padding-right: 20px;
     text-align: center;
   }
   .theme-item-page-header-features > li:last-child {
     border: none;
   }
   .theme-item-page-header-features-title {
     margin-bottom: 0;
     font-size: 14px;
   }
   .theme-item-page-header-features-icon {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     font-size: 25px;
     margin-bottom: 7px;
   }
   .theme-item-page-header-rating {
     overflow: hidden;
     margin-bottom: 7px;
   }
   .theme-item-page-header-rating-stars {
     list-style: none;
     padding: 0;
     margin: 0;
     float: left;
     margin-right: 7px;
   }
   .theme-item-page-header-rating-stars > li {
     display: inline-block;
     margin-right: 3px;
     color: #ff6c2d;
   }
   .theme-item-page-header-rating-stars > li > .fa {
     text-shadow: 0 0 5px rgba(255,182,150,0.3);
   }
   .theme-item-page-header-rating-stars > li:last-child {
     margin-right: 0;
   }
   .theme-item-page-header-rating-title {
     margin-bottom: 0;
     display: inline-block;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-item-page-header-price {
     position: absolute;
     bottom: 0;
     right: 0;
   }
   .theme-item-page-header-price .btn {
     margin-top: 10px;
     float: right;
   }
   @media (max-width: 992px) {
     .theme-item-page-header-price {
       position: relative;
       left: 0;
       margin-top: 20px;
     }
     .theme-item-page-header-price .btn {
       float: none;
       margin-top: 15px;
     }
     .theme-item-page-header-price .theme-item-page-header-price-body {
       text-align: left;
     }
   }
   .theme-item-page-header-price-lg .theme-item-page-header-price-body > b {
     font-size: 40px;
     font-weight: 400;
   }
   .theme-item-page-header-price-xl .theme-item-page-header-price-body > b {
     font-size: 60px;
     font-weight: 200;
   }
   .theme-item-page-header-price-body {
     text-align: right;
     margin-bottom: 0;
   }
   .theme-item-page-header-price-body > b {
     font-size: 30px;
     margin-left: 5px;
     line-height: 1em;
   }
   .theme-item-page-header-price-body > span {
     opacity: 0.66;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
     filter: alpha(opacity=66);
   }
   .theme-item-page-facilities {
     position: relative;
     margin-bottom: -30px;
   }
   .theme-item-page-facilities-item {
     margin-bottom: 30px;
   }
   .theme-item-page-facilities-item-icon {
     font-size: 17px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-item-page-facilities-item-title {
     font-family: helvetica, Arial, sans-serif;
     margin-bottom: 10px;
   }
   .theme-item-page-facilities-item-list,
   .theme-item-page-facilities-item-body {
     font-size: 13px;
   }
   .theme-item-page-facilities-item-list {
     margin: 0;
     padding: 0;
     list-style: none;
   }
   .theme-item-page-facilities-item-list > li {
     margin-bottom: 5px;
   }
   .theme-item-page-overview-rate {
     margin-bottom: 20px;
   }
   .theme-item-page-overview-rate-title {
     text-transform: uppercase;
     margin-bottom: 3px;
     margin-top: 0;
     color: #0093d2;
     font-size: 35px;
   }
   .theme-item-page-overview-rate-subtitle {
     font-size: 17px;
     margin-bottom: 3px;
   }
   .theme-item-page-overview-rate-count {
     font-size: 14px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 0;
   }
   .theme-item-page-overview-summary {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 14px;
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
   }
   .theme-item-page-overview-summary > li {
     margin-bottom: 5px;
   }
   .theme-item-page-overview-summary > li:last-child {
     margin-bottom: 0;
   }
   .theme-item-page-details-section-title {
     margin-top: 0;
     font-size: 18px;
     font-weight: normal;
     margin-bottom: 20px;
   }
   .theme-item-page-details-section {
     margin-top: 25px;
     padding-top: 25px;
     border-top: 1px solid #e6e6e6;
   }
   .theme-item-page-details-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 14px;
     color: #6a6a6a;
   }
   .theme-item-page-details-list > li {
     margin-bottom: 5px;
   }
   .theme-item-page-details-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-item-page-details-list-checked > li {
     padding-left: 20px;
     position: relative;
   }
   .theme-item-page-details-list-checked > li:before {
     font-family: 'FontAwesome';
     content: '\f00c';
     position: absolute;
     font-size: 12px;
     top: 1.5px;
     left: 0;
     color: #0093d2;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-item-page-details-first-nm .theme-item-page-details-section:first-child {
     margin-top: 0;
     padding-top: 0;
     border-top: none;
   }
   .theme-item-page-summary-price-link {
     position: relative;
     display: block;
     padding-bottom: 10px;
     color: #6a6a6a !important;
     text-decoration: none !important;
   }
   .theme-item-page-summary-price-link:after {
     position: absolute;
     top: 0;
     right: 0;
     font-family: 'FontAwesome';
     content: '\f107';
   }
   .theme-item-page-summary-price-total {
     margin-bottom: 0;
     position: relative;
     padding-top: 15px;
     border-top: 1px solid #d9d9d9;
     font-size: 17px;
     text-transform: uppercase;
   }
   .theme-item-page-summary-price-total > span {
     position: absolute;
     bottom: 0;
     right: 0;
     font-size: 25px;
   }
   .theme-item-page-summary-price-list {
     list-style: none;
     margin: 0;
     padding: 0;
     padding-bottom: 12px;
   }
   .theme-item-page-summary-price-list > li {
     position: relative;
     margin-top: 12px;
     padding-top: 12px;
     border-top: 1px dashed #e6e6e6;
   }
   .theme-item-page-summary-price-item-title {
     margin-top: 0;
     margin-bottom: 2px;
     font-weight: normal;
     letter-spacing: 0;
     font-size: 15px;
   }
   .theme-item-page-summary-price-item-subtitle {
     margin-bottom: 0;
     font-size: 13px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-item-page-summary-price-item-price {
     position: absolute;
     top: 10px;
     right: 0;
     margin-bottom: 0;
     font-size: 14px;
     font-weight: bold;
     line-height: 1em;
   }
   .theme-item-page-summary-price-white {
     color: #fff;
   }
   .theme-item-page-summary-price-white .theme-item-page-summary-price-link {
     color: rgba(255,255,255,0.7) !important;
   }
   .theme-item-page-summary-price-white .theme-item-page-summary-price-list > li {
     border-color: rgba(255,255,255,0.5);
   }
   .theme-item-page-summary-price-white .theme-item-page-summary-price-total {
     border-color: #fff;
   }
   .theme-item-page-rooms-table .table {
     margin: 0;
   }
   @media (max-width: 992px) {
     .theme-item-page-rooms-table .table > thead {
       display: none;
     }
   }
   .theme-item-page-rooms-table .table > thead > tr > th,
   .theme-item-page-rooms-table .table > tbody > tr > th,
   .theme-item-page-rooms-table .table > tfoot > tr > th,
   .theme-item-page-rooms-table .table > thead > tr > td,
   .theme-item-page-rooms-table .table > tbody > tr > td,
   .theme-item-page-rooms-table .table > tfoot > tr > td {
     padding: 20px;
   }
   @media (max-width: 992px) {
     .theme-item-page-rooms-table .table > thead > tr > th,
     .theme-item-page-rooms-table .table > tbody > tr > th,
     .theme-item-page-rooms-table .table > tfoot > tr > th,
     .theme-item-page-rooms-table .table > thead > tr > td,
     .theme-item-page-rooms-table .table > tbody > tr > td,
     .theme-item-page-rooms-table .table > tfoot > tr > td {
       width: 100%;
       display: block;
       border: none !important;
       padding: 10px 0;
     }
   }
   @media (max-width: 992px) {
     .theme-item-page-rooms-table .table > tbody > tr {
       border-bottom: 1px solid #ededed;
       padding-bottom: 20px;
       margin-bottom: 20px;
       display: block;
     }
     .theme-item-page-rooms-table .table > tbody > tr:last-child {
       padding-bottom: 0;
       margin-bottom: 0;
       border: none;
     }
   }
   .theme-item-page-rooms-table .table > thead > tr > th {
     font-weight: 400;
     font-size: 13px;
     color: #a6a6a6;
     border-width: 1px;
     padding-top: 0;
   }
   .theme-item-page-rooms-table .table > tbody > tr > td {
     border: 1px solid #ededed;
     border-left: none;
     border-bottom: none;
   }
   .theme-item-page-rooms-table .table > tbody > tr > td:first-child,
   .theme-item-page-rooms-table .table > thead > tr > th:first-child {
     padding-left: 0;
   }
   .theme-item-page-rooms-table .table > tbody > tr > td:last-child,
   .theme-item-page-rooms-table .table > thead > tr > th:last-child {
     padding-right: 0;
     border-right: none;
   }
   .theme-item-page-rooms-table .table > tbody > tr:nth-child(even) {
     background: #fcfcfc;
   }
   .theme-item-page-rooms-table .table > tbody > tr:last-child td {
     padding-bottom: 0;
   }
   .theme-item-page-rooms-table-first-pad .table > tbody > tr > td:first-child,
   .theme-item-page-rooms-table-first-pad .table > thead > tr > th:first-child {
     padding-left: 20px;
   }
   .theme-item-page-rooms-table-type {
     width: 25%;
   }
   .theme-item-page-rooms-table-type-img {
     width: 100%;
     margin-bottom: 15px;
   }
   .theme-item-page-rooms-table-type-title {
     font-size: 13px;
     margin-top: 0;
   }
   .theme-item-page-rooms-table-type-feature-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
   }
   .theme-item-page-rooms-table-type-feature-list > li {
     margin-bottom: 3px;
   }
   .theme-item-page-rooms-table-type-feature-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-item-page-rooms-table-type-feature-list-icon {
     display: inline-block;
     width: 22px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     font-size: 12px;
     position: relative;
     top: -1px;
   }
   .theme-item-page-rooms-table-guests-count {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-item-page-rooms-table-guests-count > li {
     display: inline-block;
     margin-right: 4px;
     font-size: 19px;
   }
   .theme-item-page-rooms-table-guests-count > li .fa {
     color: #6a6a6a;
   }
   .theme-item-page-rooms-table-guests-count > li:last-child {
     margin-right: 0;
   }
   .theme-item-page-rooms-table-options-list {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 12px;
   }
   .theme-item-page-rooms-table-options-list > li {
     margin-bottom: 10px;
     position: relative;
     padding-left: 9px;
   }
   .theme-item-page-rooms-table-options-list > li:before {
     content: '\f111';
     font-family: 'FontAwesome';
     font-size: 4px;
     position: absolute;
     top: 8px;
     left: 0;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   .theme-item-page-rooms-table-price {
     width: 20%;
   }
   .theme-item-page-rooms-table-price-night {
     margin-bottom: 15px;
   }
   .theme-item-page-rooms-table-price-total {
     margin-bottom: 20px;
   }
   .theme-item-page-rooms-table-price-sign {
     font-size: 11px;
     color: #8c8c8c;
     margin-bottom: 5px;
   }
   .theme-item-page-rooms-table-price-night-amount {
     font-weight: bold;
     font-size: 32px;
     letter-spacing: 0.64px;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     color: #0093d2;
     line-height: 1em;
     margin-bottom: 0;
   }
   .theme-item-page-rooms-table-price-total-amount {
     font-size: 20px;
     font-family: 'Roboto', helvetica, Arial, sans-serif;
     line-height: 1em;
     margin-bottom: 0;
   }
   .theme-item-page-rooms-table-price-note {
     font-size: 12px;
     margin-bottom: 0;
     color: #4d4d4d;
   }
   .theme-item-page-rooms-table-booking-note {
     margin-top: 10px;
     font-size: 11px;
     margin-bottom: 0;
     line-height: 1.35em;
     color: #a6a6a6;
   }
   .theme-payment-page-item-thumb {
     border: none;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
   }
   .theme-payment-page-item-thumb .theme-search-results-item-img {
     max-height: 100px;
     max-width: 100%;
     float: right;
     display: block;
     width: auto;
   }
   @media (max-width: 992px) {
     .theme-payment-page-item-thumb .theme-search-results-item-img {
       float: none;
       width: 100%;
       max-height: none;
     }
   }
   .theme-payment-page-form-title {
     margin-top: 0;
     margin-bottom: 15px;
     font-size: 16px;
     font-weight: normal;
     font-family: helvetica, Arial, sans-serif;
     letter-spacing: 0;
   }
   .theme-payment-page-form-item {
     position: relative;
     margin-bottom: 0;
   }
   .theme-payment-page-form-item select.form-control {
     padding-right: 30px;
   }
   .theme-payment-page-form-item .form-control {
     border-radius: 2px;
     height: 45px;
     outline: none !important;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     -webkit-appearance: none;
   }
   .theme-payment-page-form-item .form-control:before {
     content: 'foo';
   }
   .theme-payment-page-form-item .form-control:focus {
     border-color: #0093d2;
   }
   .theme-payment-page-form-item > .fa {
     position: absolute;
     top: 0;
     right: 10px;
     content: '\f107';
     font-family: 'FontAwesome';
     height: 45px;
     line-height: 45px;
     display: block;
     pointer-events: none;
   }
   .theme-payment-page-booking-header {
     position: relative;
     margin-bottom: 20px;
   }
   .theme-payment-page-booking-price {
     font-size: 35px;
     font-weight: 700;
     line-height: 1em;
   }
   @media (min-width: 992px) {
     .theme-payment-page-booking-price {
       position: absolute;
       top: 0;
       right: 0;
     }
   }
   .theme-payment-page-booking-title {
     margin-top: 0;
     margin-bottom: 5px;
     font-size: 18px;
   }
   .theme-payment-page-booking-subtitle {
     font-size: 13px;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
   }
   @media (min-width: 992px) {
     .theme-payment-page-booking-subtitle {
       width: 64%;
       margin-bottom: 0;
     }
   }
   .theme-payment-page-card-list {
     list-style: none;
     margin: 0;
     padding: 0;
     float: right;
     margin-top: 10px;
   }
   .theme-payment-page-card-list > li {
     display: inline-block;
     margin-left: 10px;
   }
   .theme-payment-page-card-list > li img {
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     display: block;
     height: 25px;
   }
   .theme-payment-page-sections .theme-payment-page-sections-item:last-child {
     margin-bottom: 0;
     padding-bottom: 0;
     border-bottom: none;
   }
   .theme-payment-page-sections-item {
     margin-bottom: 30px;
     padding-bottom: 30px;
     border-bottom: 1px solid #e6e6e6;
   }
   .theme-payment-page-sections-item-title {
     margin-top: 0;
     margin-bottom: 20px;
     font-size: 20px;
   }
   .theme-payment-page-sections-item-new-link {
     margin-top: 20px;
     display: inline-block;
     text-decoration: none !important;
   }
   .theme-payment-page-sections-item-new-extend {
     padding-top: 20px;
   }
   .theme-payment-page-signin {
     overflow: hidden;
     position: relative;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-payment-page-signin:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   @media (max-width: 992px) {
     .theme-payment-page-signin {
       text-align: center;
       opacity: 1;
       -ms-filter: none;
       filter: none;
     }
   }
   .theme-payment-page-signin-icon {
     font-size: 40px;
     display: block;
     float: left;
     margin-right: 12px;
     color: #0093d2;
   }
   @media (max-width: 992px) {
     .theme-payment-page-signin-icon {
       float: none;
       font-size: 64px;
       margin-right: 0;
       margin-bottom: 15px;
     }
   }
   .theme-payment-page-signin-body {
     margin-top: 2px;
   }
   .theme-payment-page-signin-title {
     margin-top: 0;
     margin-bottom: 3px;
     font-size: 16px;
     color: #595959;
   }
   .theme-payment-page-signin-subtitle {
     margin-bottom: 0;
     font-size: 13px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   @media (max-width: 992px) {
     .theme-payment-page-signin-subtitle {
       margin-bottom: 15px;
     }
   }
   .theme-payment-page-signin-link-mask {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 2;
   }
   @media (min-width: 992px) {
     .theme-payment-page-signin-btn {
       position: absolute;
       z-index: 3;
       top: 50%;
       right: 0;
       -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
       -o-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);
     }
   }
   @media (max-width: 992px) {
     .theme-payment-page-signin-btn {
       text-transform: uppercase;
     }
   }
   @media (max-width: 992px) {
     .theme-account-sidebar {
       margin-bottom: 30px;
     }
   }
   .theme-account-page-title {
     margin-top: 0;
     margin-bottom: 33px;
     font-weight: 200;
     font-family: helvetica, Arial, sans-serif;
     font-size: 42px;
   }
   .theme-account-nav-list {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-account-nav-list > li {
     margin-bottom: 15px;
   }
   .theme-account-nav-list > li:last-child {
     margin-bottom: 0;
   }
   .theme-account-nav-list > li.active > a {
     color: #1a1a1a;
     font-weight: bold;
   }
   .theme-account-nav-list > li:hover > a {
     color: #1a1a1a;
     text-decoration: none;
   }
   .theme-account-nav-list > li > a {
     font-size: 15px;
     color: #7a7a7a;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     text-decoration: none !important;
     display: block;
   }
   .theme-account-nav-list > li > a .fa {
     margin-right: 10px;
   }
   .theme-account-avatar {
     overflow: hidden;
     margin-bottom: 30px;
     height: 47px;
   }
   .theme-account-avatar-img {
     float: left;
     width: 40px;
     display: block;
     border-radius: 50%;
     margin-right: 15px;
   }
   .theme-account-avatar-name {
     margin-bottom: 0;
     font-size: 14px;
     line-height: 1.2em;
     margin-top: 4px;
   }
   .theme-account-bookmarks-item {
     margin-bottom: 20px;
   }
   .theme-account-bookmarks-item:hover .theme-account-bookmarks-item-info {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-bookmarks-item:hover .theme-account-bookmarks-item-thumb {
     -webkit-transform: translateY(-3px);
     -moz-transform: translateY(-3px);
     -o-transform: translateY(-3px);
     -ms-transform: translateY(-3px);
     transform: translateY(-3px);
     -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
     box-shadow: 0 5px 10px rgba(0,0,0,0.2);
   }
   .theme-account-bookmarks-item-thumb {
     background: #fff;
     position: relative;
     border-radius: 3px;
     overflow: hidden;
     -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.05);
     box-shadow: 0 1px 5px rgba(0,0,0,0.05);
     z-index: 1;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-bookmarks-item-thumb-link {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 2;
     display: block;
   }
   .theme-account-bookmarks-item-title {
     margin-top: 0;
     letter-spacing: 0;
     line-height: 1.3em;
     margin-bottom: 0;
     height: 32px;
     overflow: hidden;
     font-family: helvetica, Arial, sans-serif;
     font-size: 13px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
   }
   .theme-account-bookmarks-item-price {
     font-size: 18px;
     margin-bottom: 0;
     text-align: right;
   }
   .theme-account-bookmarks-item-thumb-body {
     padding: 15px 20px;
   }
   .theme-account-bookmarks-item-info {
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-top: 15px;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-bookmarks-item-date {
     font-size: 11px;
     margin-bottom: 10px;
   }
   .theme-account-bookmarks-item-location {
     font-size: 12px;
     margin-bottom: 10px;
   }
   .theme-account-bookmarks-item-actions {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
   }
   .theme-account-bookmarks-item-actions > li {
     display: inline-block;
     margin-right: 15px;
   }
   .theme-account-bookmarks-item-actions > li > a {
     color: #595959;
     text-decoration: none !important;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-bookmarks-item-actions > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-bookmarks-item-action-icon {
     margin-right: 5px;
     position: relative;
     top: 1px;
   }
   .theme-account-history table {
     margin-bottom: 0;
     font-size: 14px;
     background: #fff;
     border-radius: 3px;
   }
   .theme-account-history table > thead > tr > th {
     border: none;
     opacity: 0.5;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     font-size: 12px;
   }
   .theme-account-history table > thead > tr > th,
   .theme-account-history table > tbody > tr > th,
   .theme-account-history table > tfoot > tr > th,
   .theme-account-history table > thead > tr > td,
   .theme-account-history table > tbody > tr > td,
   .theme-account-history table > tfoot > tr > td {
     padding: 15px 12px;
   }
   .theme-account-history-type-icon {
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
   }
   .theme-account-history-type-title {
     line-height: 1.2em;
     margin-bottom: 2px;
   }
   .theme-account-history-item-name {
     font-size: 12px;
     line-height: 1.3em;
   }
   .theme-account-history-item-price {
     font-weight: bold;
     margin-bottom: 0;
   }
   .theme-account-history-date {
     font-size: 12px;
   }
   .theme-account-history-tr-date {
     width: 30%;
   }
   .theme-account-card {
     position: relative;
     border-radius: 4px;
     padding: 20px;
     height: 175px;
     background: #f5f5f5;
     -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.15);
     box-shadow: 0 2px 5px rgba(0,0,0,0.15);
     background: -webkit-linear-gradient(135deg, #006793, #00a8f0);
     background: -moz-linear-gradient(135deg, #006793, #00a8f0);
     background: -o-linear-gradient(135deg, #006793, #00a8f0);
     background: -ms-linear-gradient(135deg, #006793, #00a8f0);
     background: linear-gradient(-45deg, #006793, #00a8f0);
     color: #fff;
   }
   .theme-account-card-primary {
     background: -webkit-linear-gradient(135deg, #d23f00, #ff7b42);
     background: -moz-linear-gradient(135deg, #d23f00, #ff7b42);
     background: -o-linear-gradient(135deg, #d23f00, #ff7b42);
     background: -ms-linear-gradient(135deg, #d23f00, #ff7b42);
     background: linear-gradient(-45deg, #d23f00, #ff7b42);
   }
   .theme-account-card-new {
     background: #fff;
     color: #595959;
   }
   .theme-account-card-number {
     margin-top: 25px;
     font-size: 22px;
     line-height: 1em;
     margin-bottom: 10px;
     text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
   }
   .theme-account-card-expire {
     overflow: hidden;
     margin-bottom: 10px;
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-account-card-expire-end {
     float: left;
     display: block;
     font-size: 8px;
     line-height: 1em;
     opacity: 0.7;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
     filter: alpha(opacity=70);
     text-transform: uppercase;
   }
   .theme-account-card-expire-date {
     float: left;
     display: block;
     margin-left: 5px;
     line-height: 1em;
     font-size: 17px;
   }
   .theme-account-card-name {
     font-weight: bold;
     bottom: 20px;
     position: absolute;
     font-size: 17px;
   }
   .theme-account-card-name > p {
     margin-bottom: 0;
     text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
     opacity: 0.9;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
     filter: alpha(opacity=90);
     letter-spacing: 1px;
   }
   .theme-account-card-logo {
     position: absolute;
     bottom: 20px;
     right: 30px;
     display: block;
   }
   .theme-account-card-mask {
     border-radius: 4px;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: #000;
     z-index: 3;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-card:hover .theme-account-card-mask {
     opacity: 0.85;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
     filter: alpha(opacity=85);
   }
   .theme-account-card-actions {
     list-style: none;
     position: absolute;
     top: 50%;
     margin: 0;
     padding: 0;
     width: 100%;
     text-align: center;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
   }
   .theme-account-card-actions > li {
     display: inline-block;
     margin: 0 10px;
   }
   .theme-account-card-actions > li > a {
     display: block;
     color: #fff;
     font-size: 13px;
     text-decoration: none !important;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-card-actions > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-card-actions > li > a .fa {
     background: #fff;
     color: #000;
     font-size: 10px;
     display: block;
     text-align: center;
     margin: 0 auto;
     margin-bottom: 5px;
     height: 22px;
     width: 22px;
     line-height: 20px;
     border: 1px solid #fff;
     border-radius: 50%;
   }
   .theme-account-card-new-title {
     position: absolute;
     z-index: 2;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     margin-bottom: 0;
     font-size: 17px;
   }
   .theme-account-card-mask-link {
     position: absolute;
     z-index: 3;
     width: 100%;
     height: 100%;
     display: block;
   }
   .theme-account-traveler {
     background: #fff;
     padding: 30px;
     border-radius: 3px;
     height: 192px;
     position: relative;
     -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.05);
     box-shadow: 0 1px 4px rgba(0,0,0,0.05);
   }
   .theme-account-traveler:hover .theme-account-traveler-add-new {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-traveler-icon {
     display: block;
     height: 60px;
     width: 60px;
     line-height: 60px;
     color: #fff;
     border-radius: 50%;
     text-align: center;
     font-size: 30px;
     float: left;
     font-weight: bold;
     margin-right: 30px;
     font-style: normal;
   }
   .theme-account-traveler-body {
     display: table;
   }
   .theme-account-traveler-name {
     font-size: 17px;
     line-height: 1em;
     margin-bottom: 15px;
   }
   .theme-account-traveler-details {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 13px;
     margin-bottom: 15px;
   }
   .theme-account-traveler-details > li {
     margin-bottom: 5px;
     color: #6a6a6a;
   }
   .theme-account-traveler-details > li:last-child {
     margin-bottom: 0;
   }
   .theme-account-traveler-actions {
     list-style: none;
     margin: 0;
     padding: 0;
   }
   .theme-account-traveler-actions > li {
     display: inline-block;
     margin-right: 7px;
     font-size: 13px;
   }
   .theme-account-traveler-actions > li > a {
     color: #acacac;
     text-decoration: none !important;
   }
   .theme-account-traveler-actions > li > a:hover {
     color: #595959;
   }
   .theme-account-traveler-link-mask {
     position: absolute;
     z-index: 3;
     display: block;
     width: 100%;
     height: 100%;
   }
   .theme-account-traveler-add-new {
     position: absolute;
     z-index: 2;
     margin-bottom: 0;
     top: 50%;
     left: 50%;
     -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
     -o-transform: translate(-50%, -50%);
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
     font-size: 18px;
     opacity: 0.44;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=44)";
     filter: alpha(opacity=44);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-notifications {
     background: #fff;
     border-radius: 3px;
   }
   .theme-account-notifications-title {
     margin-top: 0;
     margin-bottom: 30px;
     font-weight: normal;
   }
   .theme-account-notifications-item {
     padding: 20px;
     border-bottom: 1px solid #e6e6e6;
   }
   .theme-account-notifications-item .icheck {
     top: 3px;
     width: 16px;
     height: 16px;
     border: 1px solid #666;
     background: none;
     margin-right: 12px;
   }
   .theme-account-notifications-item .icheck.checked {
     background: #0093d2;
     border-color: #0093d2;
   }
   .theme-account-notifications-item .icheck:after {
     display: none;
   }
   .theme-account-notifications-item .icheck:before {
     height: 16px;
     line-height: 14px;
   }
   .theme-account-notifications-item .icheck-title {
     font-weight: normal;
     font-size: 15px;
   }
   .theme-account-notifications-item .icheck-sub-title {
     font-size: 13px;
   }
   .theme-account-preferences .theme-account-preferences-item:last-child {
     margin-bottom: 0;
   }
   .theme-account-preferences-item {
     padding: 20px;
     background: #fff;
     margin-bottom: 20px;
     border-radius: 3px;
   }
   .theme-account-preferences-item-title {
     margin-top: 0;
     margin-bottom: 0;
   }
   .theme-account-preferences-item-value {
     margin-bottom: 0;
     font-size: 14px;
     color: #727272;
   }
   .theme-account-preferences-item-change-link {
     font-size: 13px;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     text-decoration: none !important;
     text-align: right;
     display: block;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-account-preferences-item-change-link .fa {
     margin-right: 7px;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     opacity: 0;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
   }
   .theme-account-preferences-item-change-link:hover,
   .theme-account-preferences-item-change-link[aria-expanded='true'] {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-preferences-item-change-link:hover .fa,
   .theme-account-preferences-item-change-link[aria-expanded='true'] .fa {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-account-preferences-item-change {
     margin-top: 15px;
     padding-top: 15px;
     border-top: 1px solid #e6e6e6;
     margin-bottom: 10px;
   }
   .theme-account-preferences-item-change-description {
     font-size: 13px;
     margin-bottom: 10px;
     color: #666;
   }
   .theme-account-preferences-item-change-actions .btn {
     margin-right: 5px;
   }
   .theme-account-preferences-item-change-form .form-control {
     border-radius: 3px;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     -webkit-appearance: none;
   }
   .theme-account-preferences-item-change-form .form-control:focus {
     border-color: #0093d2;
   }
   .theme-account-preferences-item-change-form label {
     font-size: 12px;
     margin-top: 10px;
     font-weight: normal;
   }
   .theme-testimonial {
     text-align: center;
   }
   .theme-testimonial-body {
     position: relative;
     margin-bottom: 20px;
   }
   .theme-testimonial-body > blockquote {
     font-size: 20px;
     border: none;
     font-style: italic;
     position: relative;
     z-index: 2;
     padding: 0 10px;
     margin: 0;
   }
   .theme-testimonial-body:before,
   .theme-testimonial-body:after {
     position: absolute;
     top: 50%;
     font-family: 'FontAwesome';
     opacity: 0.05;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=5)";
     filter: alpha(opacity=5);
     z-index: 1;
     font-size: 50px;
     -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
     -o-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
   }
   .theme-testimonial-body:before {
     content: '\f10d';
     left: 0;
   }
   .theme-testimonial-body:after {
     content: '\f10e';
     right: 0;
   }
   .theme-testimonial-img {
     border-radius: 50%;
     margin-bottom: 10px;
     display: inline-block !important;
     width: 70px !important;
   }
   .theme-testimonial-author {
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     margin-bottom: 0;
   }
   .theme-coming-soon {
     color: #fff;
   }
   .theme-coming-soon-header {
     text-align: center;
   }
   .theme-coming-soon-title {
     font-size: 50px;
     margin-top: 0;
     margin-bottom: 10px;
     line-height: 1em;
     font-weight: 300;
   }
   .theme-coming-soon-subtitle {
     font-size: 23px;
     opacity: 0.55;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=55)";
     filter: alpha(opacity=55);
     margin-bottom: 0;
   }
   .theme-coming-soon-countdown {
     overflow: hidden;
     display: table;
     margin: 30px auto;
     padding: 30px 0;
   }
   .theme-coming-soon-countdown > div {
     float: left;
     text-align: center;
     position: relative;
     width: auto;
     padding: 0 20px;
     border-radius: 3px;
   }
   .theme-coming-soon-countdown > div:after,
   .theme-coming-soon-countdown > div:before {
     content: '';
     position: absolute;
     top: 25px;
     right: -4px;
     width: 8px;
     height: 8px;
     background: #fff;
     border-radius: 50%;
     opacity: 0.15;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
     filter: alpha(opacity=15);
   }
   .theme-coming-soon-countdown > div:before {
     top: 50px;
   }
   .theme-coming-soon-countdown > div:last-child:after,
   .theme-coming-soon-countdown > div:last-child:before {
     display: none;
   }
   .theme-coming-soon-countdown > div > p {
     font-size: 85px;
     margin-bottom: 0;
     line-height: 1em;
     text-shadow: 5px 5px 15px rgba(0,0,0,0.2);
   }
   .theme-coming-soon-countdown > div > span {
     margin-top: 5px;
     display: block;
     font-size: 20px;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     line-height: 1em;
   }
   .theme-coming-soon-form {
     -webkit-box-shadow: 0 10px 45px rgba(0,0,0,0.2) , 0 2px 4px rgba(0,0,0,0.15);
     box-shadow: 0 10px 45px rgba(0,0,0,0.2) , 0 2px 4px rgba(0,0,0,0.15);
   }
   .theme-coming-soon-form .form-control {
     height: 60px;
     border: none !important;
     -webkit-box-shadow: none;
     box-shadow: none;
     outline: none !important;
     border-radius: 2px 0 0 2px;
     font-size: 20px;
     padding: 6px 20px;
   }
   @media (max-width: 992px) {
     .theme-coming-soon-form .form-control {
       margin-bottom: 10px;
       border-radius: 2px;
     }
   }
   .theme-coming-soon-form-btn {
     height: 60px;
     border: none;
     border-radius: 0;
     line-height: 60px;
     padding: 0;
     border-radius: 0 2px 2px 0;
     font-size: 20px;
     text-transform: uppercase;
   }
   @media (max-width: 992px) {
     .theme-coming-soon-form-btn {
       border-radius: 2px;
     }
   }
   .theme-coming-soon-social {
     list-style: none;
     margin: 0;
     padding: 0;
     position: absolute;
     z-index: 6;
     bottom: 30px;
     width: 100%;
     text-align: center;
   }
   .theme-coming-soon-social > li {
     display: inline-block;
     margin: 0 10px;
   }
   .theme-coming-soon-social > li > .fa {
     display: block;
     height: 35px;
     width: 35px;
     line-height: 35px;
     background: rgba(255,255,255,0.1);
     border-radius: 50%;
     color: #fff;
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     text-shadow: 1px 2px 5px rgba(0,0,0,0.35);
   }
   .theme-coming-soon-social > li > .fa:hover {
     background: #0093d2;
   }
   /*  --/ELEMENTS--  */
   /*  --FOOTER--  */
   .theme-footer {
     background: #f7f7f7;
     border-top: 1px solid #d9d9d9;
   }
   @media (max-width: 992px) {
     .theme-footer {
       padding-top: 30px;
     }
   }
   .theme-footer-section {
     padding: 30px 0;
     position: relative;
   }
   @media (max-width: 992px) {
     .theme-footer-section {
       text-align: center;
       padding: 15px 0;
     }
   }
   .theme-footer-section-subscribe-bg {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     z-index: 1;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     background-size: cover;
   }
   .theme-footer-section-subscribe-content {
     position: relative;
     z-index: 2;
   }
   .theme-footer-section-subscribe {
     padding: 30px;
     background: #0093d2;
     height: 100%;
     -webkit-box-shadow: 0 1px 60px rgba(0,0,0,0.25) , 0 0 2px rgba(0,0,0,0.3);
     box-shadow: 0 1px 60px rgba(0,0,0,0.25) , 0 0 2px rgba(0,0,0,0.3);
     color: #fff;
   }
   .theme-footer-section-title {
     margin-bottom: 15px;
     color: var(--craneGreenDark);
     margin-top: 0;
   }
   .theme-footer-section-list {
     list-style: none;
     marign: 0;
     padding: 0;
     font-size: 12px;
     margin-bottom: -5px;
   }
   .theme-footer-section-list > li > a {
     padding-bottom: 5px;
     color: #000;
     display: block;
     opacity: 0.9;
     font-size: 14px;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
     filter: alpha(opacity=40);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-footer-section-list > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
     text-decoration: none;
   }
   .theme-footer-brand {
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
     filter: alpha(opacity=50);
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
     display: block;
     margin-bottom: 20px;
   }
   .theme-footer-brand:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-footer-brand > img {
     width: 80%;
   }
   .theme-footer-brand-text {
     color: #b3b3b3;
     font-size: 11px;
   }
   @media (max-width: 992px) {
     .theme-footer-brand-text {
       width: 70%;
       margin: 0 auto;
     }
   }
   .theme-footer-subscribe-form-control {
     -webkit-box-shadow: none;
     box-shadow: none;
     padding: 10px 15px;
     border-radius: 2px;
     height: auto;
     border: none;
     font-size: 14px;
   }
   .theme-copyright {
     background-color: var(--craneGreenDark);
     padding: 10px 0;
   }
   @media (max-width: 992px) {
     .theme-copyright {
       padding: 20px 0;
     }
   }
   .theme-copyright-text {
     font-size: 13px;
     margin-bottom: 0;
     color: #b3b3b3;
   }
   @media (max-width: 992px) {
     .theme-copyright-text {
       text-align: center;
     }
   }
   .theme-copyright-text a {
     font-weight: bold;
     color: #fff;
   }
   .theme-copyright-text a:hover {
     color: #fff;
   }
   .theme-copyright-social {
     text-align: right;
     list-style: none;
     margin: 0;
     padding: 0;
   }
   @media (max-width: 992px) {
     .theme-copyright-social {
       text-align: center;
       margin-top: 10px;
     }
     .theme-copyright-social > li {
       margin: 0 10px;
     }
   }
   .theme-copyright-social > li {
     display: inline-block;
     margin-left: 15px;
   }
   .theme-copyright-social > li > a {
     color: var(--craneGreenLight);
     display: block;
     opacity: 0.75;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
     filter: alpha(opacity=75);
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-copyright-social > li > a:hover {
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-footer-subscribe-btn {
     padding: 10px 14px;
   }
   .theme-footer-abs {
     position: absolute;
     bottom: 30px;
     left: 0;
     width: 100%;
     z-index: 1;
   }
   @media (max-width: 992px) {
     .theme-footer-abs {
       position: relative;
       bottom: auto;
       background: #333;
       padding: 20px 0;
     }
   }
   .theme-footer-abs-nav {
     list-style: none;
     margin: 0;
     padding: 0;
     font-size: 14px;
   }
   @media (max-width: 992px) {
     .theme-footer-abs-nav {
       overflow-x: auto;
       white-space: nowrap;
       padding-bottom: 10px;
     }
     .theme-footer-abs-nav > li {
       text-align: center;
       margin: 0 10px;
     }
   }
   .theme-footer-abs-nav > li {
     display: inline-block;
     margin-right: 15px;
   }
   .theme-footer-abs-nav > li > a {
     color: #fff;
     opacity: 0.65;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
     filter: alpha(opacity=65);
     text-decoration: none !important;
     -webkit-transition: 0.3s;
     -moz-transition: 0.3s;
     -o-transition: 0.3s;
     -ms-transition: 0.3s;
     transition: 0.3s;
   }
   .theme-footer-abs-nav > li > a:hover {
     color: #fff;
     opacity: 1;
     -ms-filter: none;
     filter: none;
   }
   .theme-footer-abs-copyright {
     margin-top: 10px;
     margin-bottom: 0;
     font-size: 12px;
     color: #fff;
     opacity: 0.33;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=33)";
     filter: alpha(opacity=33);
   }
   @media (max-width: 992px) {
     .theme-footer-abs-copyright {
       text-align: center;
     }
   }
   .theme-footer-abs-copyright > a {
     color: #fff;
     text-decoration: underline;
   }
   .theme-footer-abs-social {
     position: relative;
     bottom: auto;
     text-align: right;
   }
   @media (max-width: 992px) {
     .theme-footer-abs-social {
       text-align: center;
       margin-top: 20px;
     }
     .theme-footer-abs-social > li {
       margin: 0 10px;
     }
   }
   .theme-footer-abs-social > li {
     text-align: center;
     margin: 0;
     margin-left: 15px;
   }
   .theme-footer-abs-social > li > .fa {
     font-size: 13px;
     height: 30px;
     width: 30px;
     line-height: 30px;
   }
   /*  --/FOOTER--  */
   /*  --UTILITIES--  */
   ._ofw-h {
     overflow: hidden !important;
   }
   ._m-a {
     margin: auto !important;
   }
   ._m-0 {
     margin: 0 !important;
   }
   ._m-5 {
     margin: 5px !important;
   }
   ._m-10 {
     margin: 10px !important;
   }
   ._m-15 {
     margin: 15px !important;
   }
   ._m-20 {
     margin: 20px !important;
   }
   ._m-25 {
     margin: 25px !important;
   }
   ._m-30 {
     margin: 30px !important;
   }
   ._m-40 {
     margin: 40px !important;
   }
   ._m-45 {
     margin: 45px !important;
   }
   ._m-50 {
     margin: 50px !important;
   }
   ._m-60 {
     margin: 60px !important;
   }
   ._m-70 {
     margin: 70px !important;
   }
   ._m-80 {
     margin: 80px !important;
   }
   ._m-90 {
     margin: 90px !important;
   }
   ._m-100 {
     margin: 100px !important;
   }
   ._mh-a {
     margin-left: auto !important;
     margin-right: auto !important;
   }
   ._mh-0 {
     margin-left: 0 !important;
     margin-right: 0 !important;
   }
   ._mh-5 {
     margin-left: 5px !important;
     margin-right: 5px !important;
   }
   ._mh-10 {
     margin-left: 10px !important;
     margin-right: 10px !important;
   }
   ._mh-15 {
     margin-left: 15px !important;
     margin-right: 15px !important;
   }
   ._mh-20 {
     margin-left: 20px !important;
     margin-right: 20px !important;
   }
   ._mh-25 {
     margin-left: 25px !important;
     margin-right: 25px !important;
   }
   ._mh-30 {
     margin-left: 30px !important;
     margin-right: 30px !important;
   }
   ._mh-40 {
     margin-left: 40px !important;
     margin-right: 40px !important;
   }
   ._mh-45 {
     margin-left: 45px !important;
     margin-right: 45px !important;
   }
   ._mh-50 {
     margin-left: 50px !important;
     margin-right: 50px !important;
   }
   ._mh-60 {
     margin-left: 60px !important;
     margin-right: 60px !important;
   }
   ._mh-70 {
     margin-left: 70px !important;
     margin-right: 70px !important;
   }
   ._mh-80 {
     margin-left: 80px !important;
     margin-right: 80px !important;
   }
   ._mh-90 {
     margin-left: 90px !important;
     margin-right: 90px !important;
   }
   ._mh-100 {
     margin-left: 100px !important;
     margin-right: 100px !important;
   }
   ._mv-a {
     margin-top: auto !important;
     margin-bottom: auto !important;
   }
   ._mv-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important;
   }
   ._mv-5 {
     margin-top: 5px !important;
     margin-bottom: 5px !important;
   }
   ._mv-10 {
     margin-top: 10px !important;
     margin-bottom: 10px !important;
   }
   ._mv-15 {
     margin-top: 15px !important;
     margin-bottom: 15px !important;
   }
   ._mv-20 {
     margin-top: 20px !important;
     margin-bottom: 20px !important;
   }
   ._mv-25 {
     margin-top: 25px !important;
     margin-bottom: 25px !important;
   }
   ._mv-30 {
     margin-top: 30px !important;
     margin-bottom: 30px !important;
   }
   ._mv-40 {
     margin-top: 40px !important;
     margin-bottom: 40px !important;
   }
   ._mv-45 {
     margin-top: 45px !important;
     margin-bottom: 45px !important;
   }
   ._mv-50 {
     margin-top: 50px !important;
     margin-bottom: 50px !important;
   }
   ._mv-60 {
     margin-top: 60px !important;
     margin-bottom: 60px !important;
   }
   ._mv-70 {
     margin-top: 70px !important;
     margin-bottom: 70px !important;
   }
   ._mv-80 {
     margin-top: 80px !important;
     margin-bottom: 80px !important;
   }
   ._mv-90 {
     margin-top: 90px !important;
     margin-bottom: 90px !important;
   }
   ._mv-100 {
     margin-top: 100px !important;
     margin-bottom: 100px !important;
   }
   ._mt-a {
     margin-top: auto !important;
   }
   ._mt-0 {
     margin-top: 0 !important;
   }
   ._mt-5 {
     margin-top: 5px !important;
   }
   ._mt-10 {
     margin-top: 10px !important;
   }
   ._mt-15 {
     margin-top: 15px !important;
   }
   ._mt-20 {
     margin-top: 20px !important;
   }
   ._mt-25 {
     margin-top: 25px !important;
   }
   ._mt-30 {
     margin-top: 30px !important;
   }
   ._mt-40 {
     margin-top: 40px !important;
   }
   ._mt-45 {
     margin-top: 45px !important;
   }
   ._mt-50 {
     margin-top: 50px !important;
   }
   ._mt-60 {
     margin-top: 60px !important;
   }
   ._mt-70 {
     margin-top: 70px !important;
   }
   ._mt-80 {
     margin-top: 80px !important;
   }
   ._mt-90 {
     margin-top: 90px !important;
   }
   ._mt-100 {
     margin-top: 100px !important;
   }
   ._mt-120 {
     margin-top: 120px !important;
   }

   ._mt-370 {
     margin-top: 373px !important;
  }
   ._mb-a {
     margin-bottom: auto !important;
   }
   ._mb-0 {
     margin-bottom: 0 !important;
   }
   ._mb-5 {
     margin-bottom: 5px !important;
   }
   ._mb-10 {
     margin-bottom: 10px !important;
   }
   ._mb-15 {
     margin-bottom: 15px !important;
   }
   ._mb-20 {
     margin-bottom: 20px !important;
   }
   ._mb-25 {
     margin-bottom: 25px !important;
   }
   ._mb-30 {
     margin-bottom: 30px !important;
   }
   ._mb-40 {
     margin-bottom: 40px !important;
   }
   ._mb-45 {
     margin-bottom: 45px !important;
   }
   ._mb-50 {
     margin-bottom: 50px !important;
   }
   ._mb-60 {
     margin-bottom: 60px !important;
   }
   ._mb-70 {
     margin-bottom: 70px !important;
   }
   ._mb-80 {
     margin-bottom: 80px !important;
   }
   ._mb-90 {
     margin-bottom: 90px !important;
   }
   ._mb-100 {
     margin-bottom: 100px !important;
   }
   ._ml-a {
     margin-left: auto !important;
   }
   ._ml-0 {
     margin-left: 0 !important;
   }
   ._ml-5 {
     margin-left: 5px !important;
   }
   ._ml-10 {
     margin-left: 10px !important;
   }
   ._ml-15 {
     margin-left: 15px !important;
   }
   ._ml-20 {
     margin-left: 20px !important;
   }
   ._ml-25 {
     margin-left: 25px !important;
   }
   ._ml-30 {
     margin-left: 30px !important;
   }
   ._ml-40 {
     margin-left: 40px !important;
   }
   ._ml-45 {
     margin-left: 45px !important;
   }
   ._ml-50 {
     margin-left: 50px !important;
   }
   ._ml-60 {
     margin-left: 60px !important;
   }
   ._ml-70 {
     margin-left: 70px !important;
   }
   ._ml-80 {
     margin-left: 80px !important;
   }
   ._ml-90 {
     margin-left: 90px !important;
   }
   ._ml-100 {
     margin-left: 100px !important;
   }
   ._ml-100 {
     margin-left: 40% !important;
   }
   ._ml-101 {
     margin-left: 34% !important;
   }
   ._ml-40p {
     margin-left: 40% !important;
   }
   ._mr-a {
     margin-right: auto !important;
   }
   ._mr-0 {
     margin-right: 0 !important;
   }
   ._mr-5 {
     margin-right: 5px !important;
   }
   ._mr-10 {
     margin-right: 10px !important;
   }
   ._mr-15 {
     margin-right: 15px !important;
   }
   ._mr-20 {
     margin-right: 20px !important;
   }
   ._mr-25 {
     margin-right: 25px !important;
   }
   ._mr-30 {
     margin-right: 30px !important;
   }
   ._mr-40 {
     margin-right: 40px !important;
   }
   ._mr-45 {
     margin-right: 45px !important;
   }
   ._mr-50 {
     margin-right: 50px !important;
   }
   ._mr-60 {
     margin-right: 60px !important;
   }
   ._mr-70 {
     margin-right: 70px !important;
   }
   ._mr-80 {
     margin-right: 80px !important;
   }
   ._mr-90 {
     margin-right: 90px !important;
   }
   ._mr-100 {
     margin-right: 100px !important;
   }
   ._mt--5 {
     margin-top: -5px !important;
   }
   ._mt--10 {
     margin-top: -10px !important;
   }
   ._mt--15 {
     margin-top: -15px !important;
   }
   ._mt--20 {
     margin-top: -20px !important;
   }
   ._mt--25 {
     margin-top: -25px !important;
   }
   ._mt--30 {
     margin-top: -30px !important;
   }
   ._mt--40 {
     margin-top: -40px !important;
   }
   ._mt--45 {
     margin-top: -45px !important;
   }
   ._mt--50 {
     margin-top: -50px !important;
   }
   ._mt--60 {
     margin-top: -60px !important;
   }
   ._mt--70 {
     margin-top: -70px !important;
   }
   ._mt--90 {
     margin-top: -90px !important;
   }
   ._mt--100 {
     margin-top: -100px !important;
   }
   ._mb--5 {
     margin-bottom: -5px !important;
   }
   ._mb--10 {
     margin-bottom: -10px !important;
   }
   ._mb--15 {
     margin-bottom: -15px !important;
   }
   ._mb--20 {
     margin-bottom: -20px !important;
   }
   ._mb--25 {
     margin-bottom: -25px !important;
   }
   ._mb--30 {
     margin-bottom: -30px !important;
   }
   ._mb--40 {
     margin-bottom: -40px !important;
   }
   ._mb--45 {
     margin-bottom: -45px !important;
   }
   ._mb--50 {
     margin-bottom: -50px !important;
   }
   ._mb--60 {
     margin-bottom: -60px !important;
   }
   ._mb--70 {
     margin-bottom: -70px !important;
   }
   ._mb--90 {
     margin-bottom: -90px !important;
   }
   ._mb--100 {
     margin-bottom: -100px !important;
   }
   ._ml--5 {
     margin-left: -5px !important;
   }
   ._ml--10 {
     margin-left: -10px !important;
   }
   ._ml--15 {
     margin-left: -15px !important;
   }
   ._ml--20 {
     margin-left: -20px !important;
   }
   ._ml--25 {
     margin-left: -25px !important;
   }
   ._ml--30 {
     margin-left: -30px !important;
   }
   ._ml--40 {
     margin-left: -40px !important;
   }
   ._ml--45 {
     margin-left: -45px !important;
   }
   ._ml--50 {
     margin-left: -50px !important;
   }
   ._ml--60 {
     margin-left: -60px !important;
   }
   ._ml--70 {
     margin-left: -70px !important;
   }
   ._ml--90 {
     margin-left: -90px !important;
   }
   ._ml--100 {
     margin-left: -100px !important;
   }
   ._mr--5 {
     margin-right: -5px !important;
   }
   ._mr--10 {
     margin-right: -10px !important;
   }
   ._mr--15 {
     margin-right: -15px !important;
   }
   ._mr--20 {
     margin-right: -20px !important;
   }
   ._mr--25 {
     margin-right: -25px !important;
   }
   ._mr--30 {
     margin-right: -30px !important;
   }
   ._mr--40 {
     margin-right: -40px !important;
   }
   ._mr--45 {
     margin-right: -45px !important;
   }
   ._mr--50 {
     margin-right: -50px !important;
   }
   ._mr--60 {
     margin-right: -60px !important;
   }
   ._mr--70 {
     margin-right: -70px !important;
   }
   ._mr--90 {
     margin-right: -90px !important;
   }
   ._mr--100 {
     margin-right: -100px !important;
   }
   @media (max-width: 992px) {
     ._m-mob-a {
       margin: auto !important;
     }
     ._m-mob-0 {
       margin: 0 !important;
     }
     ._m-mob-5 {
       margin: 5px !important;
     }
     ._m-mob-10 {
       margin: 10px !important;
     }
     ._m-mob-15 {
       margin: 15px !important;
     }
     ._m-mob-20 {
       margin: 20px !important;
     }
     ._m-mob-25 {
       margin: 25px !important;
     }
     ._m-mob-30 {
       margin: 30px !important;
     }
     ._m-mob-40 {
       margin: 40px !important;
     }
     ._m-mob-45 {
       margin: 45px !important;
     }
     ._m-mob-50 {
       margin: 50px !important;
     }
     ._m-mob-60 {
       margin: 60px !important;
     }
     ._m-mob-70 {
       margin: 70px !important;
     }
     ._m-mob-80 {
       margin: 80px !important;
     }
     ._m-mob-90 {
       margin: 90px !important;
     }
     ._m-mob-100 {
       margin: 100px !important;
     }
     ._mh-mob-a {
       margin-left: auto !important;
       margin-right: auto !important;
     }
     ._mh-mob-0 {
       margin-left: 0 !important;
       margin-right: 0 !important;
     }
     ._mh-mob-5 {
       margin-left: 5px !important;
       margin-right: 5px !important;
     }
     ._mh-mob-10 {
       margin-left: 10px !important;
       margin-right: 10px !important;
     }
     ._mh-mob-15 {
       margin-left: 15px !important;
       margin-right: 15px !important;
     }
     ._mh-mob-20 {
       margin-left: 20px !important;
       margin-right: 20px !important;
     }
     ._mh-mob-25 {
       margin-left: 25px !important;
       margin-right: 25px !important;
     }
     ._mh-mob-30 {
       margin-left: 30px !important;
       margin-right: 30px !important;
     }
     ._mh-mob-40 {
       margin-left: 40px !important;
       margin-right: 40px !important;
     }
     ._mh-mob-45 {
       margin-left: 45px !important;
       margin-right: 45px !important;
     }
     ._mh-mob-50 {
       margin-left: 50px !important;
       margin-right: 50px !important;
     }
     ._mh-mob-60 {
       margin-left: 60px !important;
       margin-right: 60px !important;
     }
     ._mh-mob-70 {
       margin-left: 70px !important;
       margin-right: 70px !important;
     }
     ._mh-mob-80 {
       margin-left: 80px !important;
       margin-right: 80px !important;
     }
     ._mh-mob-90 {
       margin-left: 90px !important;
       margin-right: 90px !important;
     }
     ._mh-mob-100 {
       margin-left: 100px !important;
       margin-right: 100px !important;
     }
     ._mv-mob-a {
       margin-top: auto !important;
       margin-bottom: auto !important;
     }
     ._mv-mob-0 {
       margin-top: 0 !important;
       margin-bottom: 0 !important;
     }
     ._mv-mob-5 {
       margin-top: 5px !important;
       margin-bottom: 5px !important;
     }
     ._mv-mob-10 {
       margin-top: 10px !important;
       margin-bottom: 10px !important;
     }
     ._mv-mob-15 {
       margin-top: 15px !important;
       margin-bottom: 15px !important;
     }
     ._mv-mob-20 {
       margin-top: 20px !important;
       margin-bottom: 20px !important;
     }
     ._mv-mob-25 {
       margin-top: 25px !important;
       margin-bottom: 25px !important;
     }
     ._mv-mob-30 {
       margin-top: 30px !important;
       margin-bottom: 30px !important;
     }
     ._mv-mob-40 {
       margin-top: 40px !important;
       margin-bottom: 40px !important;
     }
     ._mv-mob-45 {
       margin-top: 45px !important;
       margin-bottom: 45px !important;
     }
     ._mv-mob-50 {
       margin-top: 50px !important;
       margin-bottom: 50px !important;
     }
     ._mv-mob-60 {
       margin-top: 60px !important;
       margin-bottom: 60px !important;
     }
     ._mv-mob-70 {
       margin-top: 70px !important;
       margin-bottom: 70px !important;
     }
     ._mv-mob-80 {
       margin-top: 80px !important;
       margin-bottom: 80px !important;
     }
     ._mv-mob-90 {
       margin-top: 90px !important;
       margin-bottom: 90px !important;
     }
     ._mv-mob-100 {
       margin-top: 100px !important;
       margin-bottom: 100px !important;
     }
     ._mt-mob-a {
       margin-top: auto !important;
     }
     ._mt-mob-0 {
       margin-top: 0 !important;
     }
     ._mt-mob-5 {
       margin-top: 5px !important;
     }
     ._mt-mob-10 {
       margin-top: 10px !important;
     }
     ._mt-mob-15 {
       margin-top: 15px !important;
     }
     ._mt-mob-20 {
       margin-top: 20px !important;
     }
     ._mt-mob-25 {
       margin-top: 25px !important;
     }
     ._mt-mob-30 {
       margin-top: 30px !important;
     }
     ._mt-mob-40 {
       margin-top: 40px !important;
     }
     ._mt-mob-45 {
       margin-top: 45px !important;
     }
     ._mt-mob-50 {
       margin-top: 50px !important;
     }
     ._mt-mob-60 {
       margin-top: 60px !important;
     }
     ._mt-mob-70 {
       margin-top: 70px !important;
     }
     ._mt-mob-80 {
       margin-top: 80px !important;
     }
     ._mt-mob-90 {
       margin-top: 90px !important;
     }
     ._mt-mob-100 {
       margin-top: 100px !important;
     }
     ._mb-mob-a {
       margin-bottom: auto !important;
     }
     ._mb-mob-0 {
       margin-bottom: 0 !important;
     }
     ._mb-mob-5 {
       margin-bottom: 5px !important;
     }
     ._mb-mob-10 {
       margin-bottom: 10px !important;
     }
     ._mb-mob-15 {
       margin-bottom: 15px !important;
     }
     ._mb-mob-20 {
       margin-bottom: 20px !important;
     }
     ._mb-mob-25 {
       margin-bottom: 25px !important;
     }
     ._mb-mob-30 {
       margin-bottom: 30px !important;
     }
     ._mb-mob-40 {
       margin-bottom: 40px !important;
     }
     ._mb-mob-45 {
       margin-bottom: 45px !important;
     }
     ._mb-mob-50 {
       margin-bottom: 50px !important;
     }
     ._mb-mob-60 {
       margin-bottom: 60px !important;
     }
     ._mb-mob-70 {
       margin-bottom: 70px !important;
     }
     ._mb-mob-80 {
       margin-bottom: 80px !important;
     }
     ._mb-mob-90 {
       margin-bottom: 90px !important;
     }
     ._mb-mob-100 {
       margin-bottom: 100px !important;
     }
     ._ml-mob-a {
       margin-left: auto !important;
     }
     ._ml-mob-0 {
       margin-left: 0 !important;
     }
     ._ml-mob-5 {
       margin-left: 5px !important;
     }
     ._ml-mob-10 {
       margin-left: 10px !important;
     }
     ._ml-mob-15 {
       margin-left: 15px !important;
     }
     ._ml-mob-20 {
       margin-left: 20px !important;
     }
     ._ml-mob-25 {
       margin-left: 25px !important;
     }
     ._ml-mob-30 {
       margin-left: 30px !important;
     }
     ._ml-mob-40 {
       margin-left: 40px !important;
     }
     ._ml-mob-45 {
       margin-left: 45px !important;
     }
     ._ml-mob-50 {
       margin-left: 50px !important;
     }
     ._ml-mob-60 {
       margin-left: 60px !important;
     }
     ._ml-mob-70 {
       margin-left: 70px !important;
     }
     ._ml-mob-80 {
       margin-left: 80px !important;
     }
     ._ml-mob-90 {
       margin-left: 90px !important;
     }
     ._ml-mob-100 {
       margin-left: 100px !important;
     }
     ._mr-mob-a {
       margin-right: auto !important;
     }
     ._mr-mob-0 {
       margin-right: 0 !important;
     }
     ._mr-mob-5 {
       margin-right: 5px !important;
     }
     ._mr-mob-10 {
       margin-right: 10px !important;
     }
     ._mr-mob-15 {
       margin-right: 15px !important;
     }
     ._mr-mob-20 {
       margin-right: 20px !important;
     }
     ._mr-mob-25 {
       margin-right: 25px !important;
     }
     ._mr-mob-30 {
       margin-right: 30px !important;
     }
     ._mr-mob-40 {
       margin-right: 40px !important;
     }
     ._mr-mob-45 {
       margin-right: 45px !important;
     }
     ._mr-mob-50 {
       margin-right: 50px !important;
     }
     ._mr-mob-60 {
       margin-right: 60px !important;
     }
     ._mr-mob-70 {
       margin-right: 70px !important;
     }
     ._mr-mob-80 {
       margin-right: 80px !important;
     }
     ._mr-mob-90 {
       margin-right: 90px !important;
     }
     ._mr-mob-100 {
       margin-right: 100px !important;
     }
     ._mt-mob--5 {
       margin-top: -5px !important;
     }
     ._mt-mob--10 {
       margin-top: -10px !important;
     }
     ._mt-mob--15 {
       margin-top: -15px !important;
     }
     ._mt-mob--20 {
       margin-top: -20px !important;
     }
     ._mt-mob--25 {
       margin-top: -25px !important;
     }
     ._mt-mob--30 {
       margin-top: -30px !important;
     }
     ._mt-mob--40 {
       margin-top: -40px !important;
     }
     ._mt-mob--45 {
       margin-top: -45px !important;
     }
     ._mt-mob--50 {
       margin-top: -50px !important;
     }
     ._mt-mob--60 {
       margin-top: -60px !important;
     }
     ._mt-mob--70 {
       margin-top: -70px !important;
     }
     ._mt-mob--90 {
       margin-top: -90px !important;
     }
     ._mt-mob--100 {
       margin-top: -100px !important;
     }
     ._mb-mob--5 {
       margin-bottom: -5px !important;
     }
     ._mb-mob--10 {
       margin-bottom: -10px !important;
     }
     ._mb-mob--15 {
       margin-bottom: -15px !important;
     }
     ._mb-mob--20 {
       margin-bottom: -20px !important;
     }
     ._mb-mob--25 {
       margin-bottom: -25px !important;
     }
     ._mb-mob--30 {
       margin-bottom: -30px !important;
     }
     ._mb-mob--40 {
       margin-bottom: -40px !important;
     }
     ._mb-mob--45 {
       margin-bottom: -45px !important;
     }
     ._mb-mob--50 {
       margin-bottom: -50px !important;
     }
     ._mb-mob--60 {
       margin-bottom: -60px !important;
     }
     ._mb-mob--70 {
       margin-bottom: -70px !important;
     }
     ._mb-mob--90 {
       margin-bottom: -90px !important;
     }
     ._mb-mob--100 {
       margin-bottom: -100px !important;
     }
     ._ml-mob--5 {
       margin-left: -5px !important;
     }
     ._ml-mob--10 {
       margin-left: -10px !important;
     }
     ._ml-mob--15 {
       margin-left: -15px !important;
     }
     ._ml-mob--20 {
       margin-left: -20px !important;
     }
     ._ml-mob--25 {
       margin-left: -25px !important;
     }
     ._ml-mob--30 {
       margin-left: -30px !important;
     }
     ._ml-mob--40 {
       margin-left: -40px !important;
     }
     ._ml-mob--45 {
       margin-left: -45px !important;
     }
     ._ml-mob--50 {
       margin-left: -50px !important;
     }
     ._ml-mob--60 {
       margin-left: -60px !important;
     }
     ._ml-mob--70 {
       margin-left: -70px !important;
     }
     ._ml-mob--90 {
       margin-left: -90px !important;
     }
     ._ml-mob--100 {
       margin-left: -100px !important;
     }
     ._mr-mob--5 {
       margin-right: -5px !important;
     }
     ._mr-mob--10 {
       margin-right: -10px !important;
     }
     ._mr-mob--15 {
       margin-right: -15px !important;
     }
     ._mr-mob--20 {
       margin-right: -20px !important;
     }
     ._mr-mob--25 {
       margin-right: -25px !important;
     }
     ._mr-mob--30 {
       margin-right: -30px !important;
     }
     ._mr-mob--40 {
       margin-right: -40px !important;
     }
     ._mr-mob--45 {
       margin-right: -45px !important;
     }
     ._mr-mob--50 {
       margin-right: -50px !important;
     }
     ._mr-mob--60 {
       margin-right: -60px !important;
     }
     ._mr-mob--70 {
       margin-right: -70px !important;
     }
     ._mr-mob--90 {
       margin-right: -90px !important;
     }
     ._mr-mob--100 {
       margin-right: -100px !important;
     }
   }
   @media (min-width: 992px) {
     ._m-desk-a {
       margin: auto !important;
     }
     ._m-desk-0 {
       margin: 0 !important;
     }
     ._m-desk-5 {
       margin: 5px !important;
     }
     ._m-desk-10 {
       margin: 10px !important;
     }
     ._m-desk-15 {
       margin: 15px !important;
     }
     ._m-desk-20 {
       margin: 20px !important;
     }
     ._m-desk-25 {
       margin: 25px !important;
     }
     ._m-desk-30 {
       margin: 30px !important;
     }
     ._m-desk-40 {
       margin: 40px !important;
     }
     ._m-desk-45 {
       margin: 45px !important;
     }
     ._m-desk-50 {
       margin: 50px !important;
     }
     ._m-desk-60 {
       margin: 60px !important;
     }
     ._m-desk-70 {
       margin: 70px !important;
     }
     ._m-desk-80 {
       margin: 80px !important;
     }
     ._m-desk-90 {
       margin: 90px !important;
     }
     ._m-desk-100 {
       margin: 100px !important;
     }
     ._mh-desk-a {
       margin-left: auto !important;
       margin-right: auto !important;
     }
     ._mh-desk-0 {
       margin-left: 0 !important;
       margin-right: 0 !important;
     }
     ._mh-desk-5 {
       margin-left: 5px !important;
       margin-right: 5px !important;
     }
     ._mh-desk-10 {
       margin-left: 10px !important;
       margin-right: 10px !important;
     }
     ._mh-desk-15 {
       margin-left: 15px !important;
       margin-right: 15px !important;
     }
     ._mh-desk-20 {
       margin-left: 20px !important;
       margin-right: 20px !important;
     }
     ._mh-desk-25 {
       margin-left: 25px !important;
       margin-right: 25px !important;
     }
     ._mh-desk-30 {
       margin-left: 30px !important;
       margin-right: 30px !important;
     }
     ._mh-desk-40 {
       margin-left: 40px !important;
       margin-right: 40px !important;
     }
     ._mh-desk-45 {
       margin-left: 45px !important;
       margin-right: 45px !important;
     }
     ._mh-desk-50 {
       margin-left: 50px !important;
       margin-right: 50px !important;
     }
     ._mh-desk-60 {
       margin-left: 60px !important;
       margin-right: 60px !important;
     }
     ._mh-desk-70 {
       margin-left: 70px !important;
       margin-right: 70px !important;
     }
     ._mh-desk-80 {
       margin-left: 80px !important;
       margin-right: 80px !important;
     }
     ._mh-desk-90 {
       margin-left: 90px !important;
       margin-right: 90px !important;
     }
     ._mh-desk-100 {
       margin-left: 100px !important;
       margin-right: 100px !important;
     }
     ._mv-desk-a {
       margin-top: auto !important;
       margin-bottom: auto !important;
     }
     ._mv-desk-0 {
       margin-top: 0 !important;
       margin-bottom: 0 !important;
     }
     ._mv-desk-5 {
       margin-top: 5px !important;
       margin-bottom: 5px !important;
     }
     ._mv-desk-10 {
       margin-top: 10px !important;
       margin-bottom: 10px !important;
     }
     ._mv-desk-15 {
       margin-top: 15px !important;
       margin-bottom: 15px !important;
     }
     ._mv-desk-20 {
       margin-top: 20px !important;
       margin-bottom: 20px !important;
     }
     ._mv-desk-25 {
       margin-top: 25px !important;
       margin-bottom: 25px !important;
     }
     ._mv-desk-30 {
       margin-top: 30px !important;
       margin-bottom: 30px !important;
     }
     ._mv-desk-40 {
       margin-top: 40px !important;
       margin-bottom: 40px !important;
     }
     ._mv-desk-45 {
       margin-top: 45px !important;
       margin-bottom: 45px !important;
     }
     ._mv-desk-50 {
       margin-top: 50px !important;
       margin-bottom: 50px !important;
     }
     ._mv-desk-60 {
       margin-top: 60px !important;
       margin-bottom: 60px !important;
     }
     ._mv-desk-70 {
       margin-top: 70px !important;
       margin-bottom: 70px !important;
     }
     ._mv-desk-80 {
       margin-top: 80px !important;
       margin-bottom: 80px !important;
     }
     ._mv-desk-90 {
       margin-top: 90px !important;
       margin-bottom: 90px !important;
     }
     ._mv-desk-100 {
       margin-top: 100px !important;
       margin-bottom: 100px !important;
     }
     ._mt-desk-a {
       margin-top: auto !important;
     }
     ._mt-desk-0 {
       margin-top: 0 !important;
     }
     ._mt-desk-5 {
       margin-top: 5px !important;
     }
     ._mt-desk-10 {
       margin-top: 10px !important;
     }
     ._mt-desk-15 {
       margin-top: 15px !important;
     }
     ._mt-desk-20 {
       margin-top: 20px !important;
     }
     ._mt-desk-25 {
       margin-top: 25px !important;
     }
     ._mt-desk-30 {
       margin-top: 30px !important;
     }
     ._mt-desk-40 {
       margin-top: 40px !important;
     }
     ._mt-desk-45 {
       margin-top: 45px !important;
     }
     ._mt-desk-50 {
       margin-top: 50px !important;
     }
     ._mt-desk-60 {
       margin-top: 60px !important;
     }
     ._mt-desk-70 {
       margin-top: 70px !important;
     }
     ._mt-desk-80 {
       margin-top: 80px !important;
     }
     ._mt-desk-90 {
       margin-top: 90px !important;
     }
     ._mt-desk-100 {
       margin-top: 100px !important;
     }
     ._mb-desk-a {
       margin-bottom: auto !important;
     }
     ._mb-desk-0 {
       margin-bottom: 0 !important;
     }
     ._mb-desk-5 {
       margin-bottom: 5px !important;
     }
     ._mb-desk-10 {
       margin-bottom: 10px !important;
     }
     ._mb-desk-15 {
       margin-bottom: 15px !important;
     }
     ._mb-desk-20 {
       margin-bottom: 20px !important;
     }
     ._mb-desk-25 {
       margin-bottom: 25px !important;
     }
     ._mb-desk-30 {
       margin-bottom: 30px !important;
     }
     ._mb-desk-40 {
       margin-bottom: 40px !important;
     }
     ._mb-desk-45 {
       margin-bottom: 45px !important;
     }
     ._mb-desk-50 {
       margin-bottom: 50px !important;
     }
     ._mb-desk-60 {
       margin-bottom: 60px !important;
     }
     ._mb-desk-70 {
       margin-bottom: 70px !important;
     }
     ._mb-desk-80 {
       margin-bottom: 80px !important;
     }
     ._mb-desk-90 {
       margin-bottom: 90px !important;
     }
     ._mb-desk-100 {
       margin-bottom: 100px !important;
     }
     ._ml-desk-a {
       margin-left: auto !important;
     }
     ._ml-desk-0 {
       margin-left: 0 !important;
     }
     ._ml-desk-5 {
       margin-left: 5px !important;
     }
     ._ml-desk-10 {
       margin-left: 10px !important;
     }
     ._ml-desk-15 {
       margin-left: 15px !important;
     }
     ._ml-desk-20 {
       margin-left: 20px !important;
     }
     ._ml-desk-25 {
       margin-left: 25px !important;
     }
     ._ml-desk-30 {
       margin-left: 30px !important;
     }
     ._ml-desk-40 {
       margin-left: 40px !important;
     }
     ._ml-desk-45 {
       margin-left: 45px !important;
     }
     ._ml-desk-50 {
       margin-left: 50px !important;
     }
     ._ml-desk-60 {
       margin-left: 60px !important;
     }
     ._ml-desk-70 {
       margin-left: 70px !important;
     }
     ._ml-desk-80 {
       margin-left: 80px !important;
     }
     ._ml-desk-90 {
       margin-left: 90px !important;
     }
     ._ml-desk-100 {
       margin-left: 100px !important;
     }
     ._mr-desk-a {
       margin-right: auto !important;
     }
     ._mr-desk-0 {
       margin-right: 0 !important;
     }
     ._mr-desk-5 {
       margin-right: 5px !important;
     }
     ._mr-desk-10 {
       margin-right: 10px !important;
     }
     ._mr-desk-15 {
       margin-right: 15px !important;
     }
     ._mr-desk-20 {
       margin-right: 20px !important;
     }
     ._mr-desk-25 {
       margin-right: 25px !important;
     }
     ._mr-desk-30 {
       margin-right: 30px !important;
     }
     ._mr-desk-40 {
       margin-right: 40px !important;
     }
     ._mr-desk-45 {
       margin-right: 45px !important;
     }
     ._mr-desk-50 {
       margin-right: 50px !important;
     }
     ._mr-desk-60 {
       margin-right: 60px !important;
     }
     ._mr-desk-70 {
       margin-right: 70px !important;
     }
     ._mr-desk-80 {
       margin-right: 80px !important;
     }
     ._mr-desk-90 {
       margin-right: 90px !important;
     }
     ._mr-desk-100 {
       margin-right: 100px !important;
     }
   }
   ._p-0 {
     padding: 0 !important;
   }
   ._p-5 {
     padding: 5px !important;
   }
   ._p-10 {
     padding: 10px !important;
   }
   ._p-15 {
     padding: 15px !important;
   }
   ._p-20 {
     padding: 20px !important;
   }
   ._p-25 {
     padding: 25px !important;
   }
   ._p-30 {
     padding: 30px !important;
   }
   ._p-40 {
     padding: 40px !important;
   }
   ._p-45 {
     padding: 45px !important;
   }
   ._p-50 {
     padding: 50px !important;
   }
   ._p-60 {
     padding: 60px !important;
   }
   ._p-70 {
     padding: 70px !important;
   }
   ._p-80 {
     padding: 80px !important;
   }
   ._p-90 {
     padding: 90px !important;
   }
   ._p-100 {
     padding: 100px !important;
   }
   ._p-120 {
     padding: 120px !important;
   }
   ._p-150 {
     padding: 150px !important;
   }
   ._p-200 {
     padding: 200px !important;
   }
   ._p-250 {
     padding: 250px !important;
   }
   ._p-300 {
     padding: 300px !important;
   }
   ._p-400 {
     padding: 400px !important;
   }
   ._p-500 {
     padding: 500px !important;
   }
   ._p-600 {
     padding: 600px !important;
   }
   ._ph-0 {
     padding-left: 0 !important;
     padding-right: 0 !important;
   }
   ._ph-5 {
     padding-left: 5px !important;
     padding-right: 5px !important;
   }
   ._ph-10 {
     padding-left: 10px !important;
     padding-right: 10px !important;
   }
   ._ph-15 {
     padding-left: 15px !important;
     padding-right: 15px !important;
   }
   ._ph-20 {
     padding-left: 20px !important;
     padding-right: 20px !important;
   }
   ._ph-25 {
     padding-left: 25px !important;
     padding-right: 25px !important;
   }
   ._ph-30 {
     padding-left: 30px !important;
     padding-right: 30px !important;
   }
   ._ph-40 {
     padding-left: 40px !important;
     padding-right: 40px !important;
   }
   ._ph-45 {
     padding-left: 45px !important;
     padding-right: 45px !important;
   }
   ._ph-50 {
     padding-left: 50px !important;
     padding-right: 50px !important;
   }
   ._ph-60 {
     padding-left: 60px !important;
     padding-right: 60px !important;
   }
   ._ph-70 {
     padding-left: 70px !important;
     padding-right: 70px !important;
   }
   ._ph-80 {
     padding-left: 80px !important;
     padding-right: 80px !important;
   }
   ._ph-90 {
     padding-left: 90px !important;
     padding-right: 90px !important;
   }
   ._ph-100 {
     padding-left: 100px !important;
     padding-right: 100px !important;
   }
   ._ph-120 {
     padding-left: 120px !important;
     padding-right: 120px !important;
   }
   ._ph-150 {
     padding-left: 150px !important;
     padding-right: 150px !important;
   }
   ._ph-200 {
     padding-left: 200px !important;
     padding-right: 200px !important;
   }
   ._ph-250 {
     padding-left: 250px !important;
     padding-right: 250px !important;
   }
   ._ph-300 {
     padding-left: 300px !important;
     padding-right: 300px !important;
   }
   ._ph-400 {
     padding-left: 400px !important;
     padding-right: 400px !important;
   }
   ._ph-500 {
     padding-left: 500px !important;
     padding-right: 500px !important;
   }
   ._ph-600 {
     padding-left: 600px !important;
     padding-right: 600px !important;
   }
   ._pv-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
   }
   ._pv-5 {
     padding-top: 5px !important;
     padding-bottom: 5px !important;
   }
   ._pv-10 {
     padding-top: 10px !important;
     padding-bottom: 10px !important;
   }
   ._pv-15 {
     padding-top: 15px !important;
     padding-bottom: 15px !important;
   }
   ._pv-20 {
     padding-top: 20px !important;
     padding-bottom: 20px !important;
   }
   ._pv-25 {
     padding-top: 25px !important;
     padding-bottom: 25px !important;
   }
   ._pv-30 {
     padding-top: 30px !important;
     padding-bottom: 30px !important;
   }
   ._pv-40 {
     padding-top: 40px !important;
     padding-bottom: 40px !important;
   }
   ._pv-45 {
     padding-top: 45px !important;
     padding-bottom: 45px !important;
   }
   ._pv-50 {
     padding-top: 50px !important;
     padding-bottom: 50px !important;
   }
   ._pv-60 {
     padding-top: 60px !important;
     padding-bottom: 60px !important;
   }
   ._pv-70 {
     padding-top: 70px !important;
     padding-bottom: 70px !important;
   }
   ._pv-80 {
     padding-top: 80px !important;
     padding-bottom: 80px !important;
   }
   ._pv-90 {
     padding-top: 90px !important;
     padding-bottom: 90px !important;
   }
   ._pv-100 {
     padding-top: 100px !important;
     padding-bottom: 100px !important;
   }
   ._pv-120 {
     padding-top: 120px !important;
     padding-bottom: 120px !important;
   }
   ._pv-150 {
     padding-top: 150px !important;
     padding-bottom: 150px !important;
   }
   ._pv-200 {
     padding-top: 200px !important;
     padding-bottom: 200px !important;
   }
   ._pv-250 {
     padding-top: 250px !important;
     padding-bottom: 250px !important;
   }
   ._pv-250C {
     padding-top: 330px !important;
     padding-bottom: 60px !important;
   }
   ._pv-300 {
     padding-top: 300px !important;
     padding-bottom: 300px !important;
   }
   ._pv-400 {
     padding-top: 400px !important;
     padding-bottom: 400px !important;
   }
   ._pv-500 {
     padding-top: 500px !important;
     padding-bottom: 500px !important;
   }
   ._pv-600 {
     padding-top: 600px !important;
     padding-bottom: 600px !important;
   }
   ._pt-0 {
     padding-top: 0 !important;
   }
   ._pt-5 {
     padding-top: 5px !important;
   }
   ._pt-10 {
     padding-top: 10px !important;
   }
   ._pt-15 {
     padding-top: 15px !important;
   }
   ._pt-20 {
     padding-top: 20px !important;
   }
   ._pt-25 {
     padding-top: 25px !important;
   }
   ._pt-30 {
     padding-top: 30px !important;
   }
   ._pt-40 {
     padding-top: 40px !important;
   }
   ._pt-45 {
     padding-top: 45px !important;
   }
   ._pt-50 {
     padding-top: 50px !important;
   }
   ._pt-60 {
     padding-top: 60px !important;
   }
   ._pt-70 {
     padding-top: 70px !important;
   }
   ._pt-80 {
     padding-top: 80px !important;
   }
   ._pt-90 {
     padding-top: 90px !important;
   }
   ._pt-100 {
     padding-top: 100px !important;
   }
   ._pt-120 {
     padding-top: 120px !important;
   }
   ._pt-150 {
     padding-top: 150px !important;
   }
   ._pt-200 {
     padding-top: 200px !important;
   }
   ._pt-250 {
     padding-top: 250px !important;
   }
   ._pt-300 {
     padding-top: 300px !important;
   }
   ._pt-400 {
     padding-top: 400px !important;
   }
   ._pt-500 {
     padding-top: 500px !important;
   }
   ._pt-600 {
     padding-top: 600px !important;
   }
   ._pb-0 {
     padding-bottom: 0 !important;
   }
   ._pb-5 {
     padding-bottom: 5px !important;
   }
   ._pb-10 {
     padding-bottom: 10px !important;
   }
   ._pb-15 {
     padding-bottom: 15px !important;
   }
   ._pb-20 {
     padding-bottom: 20px !important;
   }
   ._pb-25 {
     padding-bottom: 25px !important;
   }
   ._pb-30 {
     padding-bottom: 30px !important;
   }
   ._pb-40 {
     padding-bottom: 40px !important;
   }
   ._pb-45 {
     padding-bottom: 45px !important;
   }
   ._pb-50 {
     padding-bottom: 50px !important;
   }
   ._pb-60 {
     padding-bottom: 60px !important;
   }
   ._pb-70 {
     padding-bottom: 70px !important;
   }
   ._pb-80 {
     padding-bottom: 80px !important;
   }
   ._pb-90 {
     padding-bottom: 90px !important;
   }
   ._pb-100 {
     padding-bottom: 100px !important;
   }
   ._pb-120 {
     padding-bottom: 120px !important;
   }
   ._pb-150 {
     padding-bottom: 150px !important;
   }
   ._pb-200 {
     padding-bottom: 200px !important;
   }
   ._pb-250 {
     padding-bottom: 250px !important;
   }
   ._pb-300 {
     padding-bottom: 300px !important;
   }
   ._pb-400 {
     padding-bottom: 400px !important;
   }
   ._pb-500 {
     padding-bottom: 500px !important;
   }
   ._pb-600 {
     padding-bottom: 600px !important;
   }
   ._pl-0 {
     padding-left: 0 !important;
   }
   ._pl-5 {
     padding-left: 5px !important;
   }
   ._pl-10 {
     padding-left: 10px !important;
   }
   ._pl-15 {
     padding-left: 15px !important;
   }
   ._pl-20 {
     padding-left: 20px !important;
   }
   ._pl-25 {
     padding-left: 25px !important;
   }
   ._pl-30 {
     padding-left: 30px !important;
   }
   ._pl-40 {
     padding-left: 40px !important;
   }
   ._pl-45 {
     padding-left: 45px !important;
   }
   ._pl-50 {
     padding-left: 50px !important;
   }
   ._pl-60 {
     padding-left: 60px !important;
   }
   ._pl-70 {
     padding-left: 70px !important;
   }
   ._pl-80 {
     padding-left: 80px !important;
   }
   ._pl-90 {
     padding-left: 90px !important;
   }
   ._pl-100 {
     padding-left: 100px !important;
   }
   ._pl-120 {
     padding-left: 120px !important;
   }
   ._pl-150 {
     padding-left: 150px !important;
   }
   ._pl-200 {
     padding-left: 200px !important;
   }
   ._pl-250 {
     padding-left: 250px !important;
   }
   ._pl-300 {
     padding-left: 300px !important;
   }
   ._pl-400 {
     padding-left: 400px !important;
   }
   ._pl-500 {
     padding-left: 500px !important;
   }
   ._pl-600 {
     padding-left: 600px !important;
   }
   ._pr-0 {
     padding-right: 0 !important;
   }
   ._pr-5 {
     padding-right: 5px !important;
   }
   ._pr-10 {
     padding-right: 10px !important;
   }
   ._pr-15 {
     padding-right: 15px !important;
   }
   ._pr-20 {
     padding-right: 20px !important;
   }
   ._pr-25 {
     padding-right: 25px !important;
   }
   ._pr-30 {
     padding-right: 30px !important;
   }
   ._pr-40 {
     padding-right: 40px !important;
   }
   ._pr-45 {
     padding-right: 45px !important;
   }
   ._pr-50 {
     padding-right: 50px !important;
   }
   ._pr-60 {
     padding-right: 60px !important;
   }
   ._pr-70 {
     padding-right: 70px !important;
   }
   ._pr-80 {
     padding-right: 80px !important;
   }
   ._pr-90 {
     padding-right: 90px !important;
   }
   ._pr-100 {
     padding-right: 100px !important;
   }
   ._pr-120 {
     padding-right: 120px !important;
   }
   ._pr-150 {
     padding-right: 150px !important;
   }
   ._pr-200 {
     padding-right: 200px !important;
   }
   ._pr-250 {
     padding-right: 250px !important;
   }
   ._pr-300 {
     padding-right: 300px !important;
   }
   ._pr-400 {
     padding-right: 400px !important;
   }
   ._pr-500 {
     padding-right: 500px !important;
   }
   ._pr-600 {
     padding-right: 600px !important;
   }
   @media (max-width: 992px) {
     ._p-mob-0 {
       padding: 0 !important;
     }
     ._p-mob-5 {
       padding: 5px !important;
     }
     ._p-mob-10 {
       padding: 10px !important;
     }
     ._p-mob-15 {
       padding: 15px !important;
     }
     ._p-mob-20 {
       padding: 20px !important;
     }
     ._p-mob-25 {
       padding: 25px !important;
     }
     ._p-mob-30 {
       padding: 30px !important;
     }
     ._p-mob-40 {
       padding: 40px !important;
     }
     ._p-mob-45 {
       padding: 45px !important;
     }
     ._p-mob-50 {
       padding: 50px !important;
     }
     ._p-mob-60 {
       padding: 60px !important;
     }
     ._p-mob-70 {
       padding: 70px !important;
     }
     ._p-mob-80 {
       padding: 80px !important;
     }
     ._p-mob-90 {
       padding: 90px !important;
     }
     ._p-mob-100 {
       padding: 100px !important;
     }
     ._p-mob-120 {
       padding: 120px !important;
     }
     ._p-mob-150 {
       padding: 150px !important;
     }
     ._p-mob-200 {
       padding: 200px !important;
     }
     ._p-mob-250 {
       padding: 250px !important;
     }
     ._p-mob-300 {
       padding: 300px !important;
     }
     ._p-mob-400 {
       padding: 400px !important;
     }
     ._p-mob-500 {
       padding: 500px !important;
     }
     ._p-mob-600 {
       padding: 600px !important;
     }
     ._ph-mob-0 {
       padding-left: 0 !important;
       padding-right: 0 !important;
     }
     ._ph-mob-5 {
       padding-left: 5px !important;
       padding-right: 5px !important;
     }
     ._ph-mob-10 {
       padding-left: 10px !important;
       padding-right: 10px !important;
     }
     ._ph-mob-15 {
       padding-left: 15px !important;
       padding-right: 15px !important;
     }
     ._ph-mob-20 {
       padding-left: 20px !important;
       padding-right: 20px !important;
     }
     ._ph-mob-25 {
       padding-left: 25px !important;
       padding-right: 25px !important;
     }
     ._ph-mob-30 {
       padding-left: 30px !important;
       padding-right: 30px !important;
     }
     ._ph-mob-40 {
       padding-left: 40px !important;
       padding-right: 40px !important;
     }
     ._ph-mob-45 {
       padding-left: 45px !important;
       padding-right: 45px !important;
     }
     ._ph-mob-50 {
       padding-left: 50px !important;
       padding-right: 50px !important;
     }
     ._ph-mob-60 {
       padding-left: 60px !important;
       padding-right: 60px !important;
     }
     ._ph-mob-70 {
       padding-left: 70px !important;
       padding-right: 70px !important;
     }
     ._ph-mob-80 {
       padding-left: 80px !important;
       padding-right: 80px !important;
     }
     ._ph-mob-90 {
       padding-left: 90px !important;
       padding-right: 90px !important;
     }
     ._ph-mob-100 {
       padding-left: 100px !important;
       padding-right: 100px !important;
     }
     ._ph-mob-120 {
       padding-left: 120px !important;
       padding-right: 120px !important;
     }
     ._ph-mob-150 {
       padding-left: 150px !important;
       padding-right: 150px !important;
     }
     ._ph-mob-200 {
       padding-left: 200px !important;
       padding-right: 200px !important;
     }
     ._ph-mob-250 {
       padding-left: 250px !important;
       padding-right: 250px !important;
     }
     ._ph-mob-300 {
       padding-left: 300px !important;
       padding-right: 300px !important;
     }
     ._ph-mob-400 {
       padding-left: 400px !important;
       padding-right: 400px !important;
     }
     ._ph-mob-500 {
       padding-left: 500px !important;
       padding-right: 500px !important;
     }
     ._ph-mob-600 {
       padding-left: 600px !important;
       padding-right: 600px !important;
     }
     ._pv-mob-0 {
       padding-top: 0 !important;
       padding-bottom: 0 !important;
     }
     ._pv-mob-5 {
       padding-top: 5px !important;
       padding-bottom: 5px !important;
     }
     ._pv-mob-10 {
       padding-top: 10px !important;
       padding-bottom: 10px !important;
     }
     ._pv-mob-15 {
       padding-top: 15px !important;
       padding-bottom: 15px !important;
     }
     ._pv-mob-20 {
       padding-top: 20px !important;
       padding-bottom: 20px !important;
     }
     ._pv-mob-25 {
       padding-top: 25px !important;
       padding-bottom: 25px !important;
     }
     ._pv-mob-30 {
       padding-top: 30px !important;
       padding-bottom: 30px !important;
     }
     ._pv-mob-40 {
       padding-top: 40px !important;
       padding-bottom: 40px !important;
     }
     ._pv-mob-45 {
       padding-top: 45px !important;
       padding-bottom: 45px !important;
     }
     ._pv-mob-50 {
       padding-top: 50px !important;
       padding-bottom: 50px !important;
     }
     ._pv-mob-60 {
       padding-top: 60px !important;
       padding-bottom: 60px !important;
     }
     ._pv-mob-70 {
       padding-top: 70px !important;
       padding-bottom: 70px !important;
     }
     ._pv-mob-80 {
       padding-top: 80px !important;
       padding-bottom: 80px !important;
     }
     ._pv-mob-90 {
       padding-top: 90px !important;
       padding-bottom: 90px !important;
     }
     ._pv-mob-100 {
       padding-top: 100px !important;
       padding-bottom: 100px !important;
     }
     ._pv-mob-120 {
       padding-top: 120px !important;
       padding-bottom: 120px !important;
     }
     ._pv-mob-150 {
       padding-top: 150px !important;
       padding-bottom: 150px !important;
     }
     ._pv-mob-200 {
       padding-top: 200px !important;
       padding-bottom: 200px !important;
     }
     ._pv-mob-250 {
       padding-top: 250px !important;
       padding-bottom: 250px !important;
     }
     ._pv-mob-300 {
       padding-top: 300px !important;
       padding-bottom: 300px !important;
     }
     ._pv-mob-400 {
       padding-top: 400px !important;
       padding-bottom: 400px !important;
     }
     ._pv-mob-500 {
       padding-top: 500px !important;
       padding-bottom: 500px !important;
     }
     ._pv-mob-600 {
       padding-top: 600px !important;
       padding-bottom: 600px !important;
     }
     ._pt-mob-0 {
       padding-top: 0 !important;
     }
     ._pt-mob-5 {
       padding-top: 5px !important;
     }
     ._pt-mob-10 {
       padding-top: 10px !important;
     }
     ._pt-mob-15 {
       padding-top: 15px !important;
     }
     ._pt-mob-20 {
       padding-top: 20px !important;
     }
     ._pt-mob-25 {
       padding-top: 25px !important;
     }
     ._pt-mob-30 {
       padding-top: 30px !important;
     }
     ._pt-mob-40 {
       padding-top: 40px !important;
     }
     ._pt-mob-45 {
       padding-top: 45px !important;
     }
     ._pt-mob-50 {
       padding-top: 50px !important;
     }
     ._pt-mob-60 {
       padding-top: 60px !important;
     }
     ._pt-mob-70 {
       padding-top: 70px !important;
     }
     ._pt-mob-80 {
       padding-top: 80px !important;
     }
     ._pt-mob-90 {
       padding-top: 90px !important;
     }
     ._pt-mob-100 {
       padding-top: 100px !important;
     }
     ._pt-mob-120 {
       padding-top: 120px !important;
     }
     ._pt-mob-150 {
       padding-top: 150px !important;
     }
     ._pt-mob-200 {
       padding-top: 200px !important;
     }
     ._pt-mob-250 {
       padding-top: 250px !important;
     }
     ._pt-mob-300 {
       padding-top: 300px !important;
     }
     ._pt-mob-400 {
       padding-top: 400px !important;
     }
     ._pt-mob-500 {
       padding-top: 500px !important;
     }
     ._pt-mob-600 {
       padding-top: 600px !important;
     }
     ._pb-mob-0 {
       padding-bottom: 0 !important;
     }
     ._pb-mob-5 {
       padding-bottom: 5px !important;
     }
     ._pb-mob-10 {
       padding-bottom: 10px !important;
     }
     ._pb-mob-15 {
       padding-bottom: 15px !important;
     }
     ._pb-mob-20 {
       padding-bottom: 20px !important;
     }
     ._pb-mob-25 {
       padding-bottom: 25px !important;
     }
     ._pb-mob-30 {
       padding-bottom: 30px !important;
     }
     ._pb-mob-40 {
       padding-bottom: 40px !important;
     }
     ._pb-mob-45 {
       padding-bottom: 45px !important;
     }
     ._pb-mob-50 {
       padding-bottom: 50px !important;
     }
     ._pb-mob-60 {
       padding-bottom: 60px !important;
     }
     ._pb-mob-70 {
       padding-bottom: 70px !important;
     }
     ._pb-mob-80 {
       padding-bottom: 80px !important;
     }
     ._pb-mob-90 {
       padding-bottom: 90px !important;
     }
     ._pb-mob-100 {
       padding-bottom: 100px !important;
     }
     ._pb-mob-120 {
       padding-bottom: 120px !important;
     }
     ._pb-mob-150 {
       padding-bottom: 150px !important;
     }
     ._pb-mob-200 {
       padding-bottom: 200px !important;
     }
     ._pb-mob-250 {
       padding-bottom: 250px !important;
     }
     ._pb-mob-300 {
       padding-bottom: 300px !important;
     }
     ._pb-mob-400 {
       padding-bottom: 400px !important;
     }
     ._pb-mob-500 {
       padding-bottom: 500px !important;
     }
     ._pb-mob-600 {
       padding-bottom: 600px !important;
     }
     ._pl-mob-0 {
       padding-left: 0 !important;
     }
     ._pl-mob-5 {
       padding-left: 5px !important;
     }
     ._pl-mob-10 {
       padding-left: 10px !important;
     }
     ._pl-mob-15 {
       padding-left: 15px !important;
     }
     ._pl-mob-20 {
       padding-left: 20px !important;
     }
     ._pl-mob-25 {
       padding-left: 25px !important;
     }
     ._pl-mob-30 {
       padding-left: 30px !important;
     }
     ._pl-mob-40 {
       padding-left: 40px !important;
     }
     ._pl-mob-45 {
       padding-left: 45px !important;
     }
     ._pl-mob-50 {
       padding-left: 50px !important;
     }
     ._pl-mob-60 {
       padding-left: 60px !important;
     }
     ._pl-mob-70 {
       padding-left: 70px !important;
     }
     ._pl-mob-80 {
       padding-left: 80px !important;
     }
     ._pl-mob-90 {
       padding-left: 90px !important;
     }
     ._pl-mob-100 {
       padding-left: 100px !important;
     }
     ._pl-mob-120 {
       padding-left: 120px !important;
     }
     ._pl-mob-150 {
       padding-left: 150px !important;
     }
     ._pl-mob-200 {
       padding-left: 200px !important;
     }
     ._pl-mob-250 {
       padding-left: 250px !important;
     }
     ._pl-mob-300 {
       padding-left: 300px !important;
     }
     ._pl-mob-400 {
       padding-left: 400px !important;
     }
     ._pl-mob-500 {
       padding-left: 500px !important;
     }
     ._pl-mob-600 {
       padding-left: 600px !important;
     }
     ._pr-mob-0 {
       padding-right: 0 !important;
     }
     ._pr-mob-5 {
       padding-right: 5px !important;
     }
     ._pr-mob-10 {
       padding-right: 10px !important;
     }
     ._pr-mob-15 {
       padding-right: 15px !important;
     }
     ._pr-mob-20 {
       padding-right: 20px !important;
     }
     ._pr-mob-25 {
       padding-right: 25px !important;
     }
     ._pr-mob-30 {
       padding-right: 30px !important;
     }
     ._pr-mob-40 {
       padding-right: 40px !important;
     }
     ._pr-mob-45 {
       padding-right: 45px !important;
     }
     ._pr-mob-50 {
       padding-right: 50px !important;
     }
     ._pr-mob-60 {
       padding-right: 60px !important;
     }
     ._pr-mob-70 {
       padding-right: 70px !important;
     }
     ._pr-mob-80 {
       padding-right: 80px !important;
     }
     ._pr-mob-90 {
       padding-right: 90px !important;
     }
     ._pr-mob-100 {
       padding-right: 100px !important;
     }
     ._pr-mob-120 {
       padding-right: 120px !important;
     }
     ._pr-mob-150 {
       padding-right: 150px !important;
     }
     ._pr-mob-200 {
       padding-right: 200px !important;
     }
     ._pr-mob-250 {
       padding-right: 250px !important;
     }
     ._pr-mob-300 {
       padding-right: 300px !important;
     }
     ._pr-mob-400 {
       padding-right: 400px !important;
     }
     ._pr-mob-500 {
       padding-right: 500px !important;
     }
     ._pr-mob-600 {
       padding-right: 600px !important;
     }
   }
   @media (min-width: 992px) {
     ._p-desk-0 {
       padding: 0 !important;
     }
     ._p-desk-5 {
       padding: 5px !important;
     }
     ._p-desk-10 {
       padding: 10px !important;
     }
     ._p-desk-15 {
       padding: 15px !important;
     }
     ._p-desk-20 {
       padding: 20px !important;
     }
     ._p-desk-25 {
       padding: 25px !important;
     }
     ._p-desk-30 {
       padding: 30px !important;
     }
     ._p-desk-40 {
       padding: 40px !important;
     }
     ._p-desk-45 {
       padding: 45px !important;
     }
     ._p-desk-50 {
       padding: 50px !important;
     }
     ._p-desk-60 {
       padding: 60px !important;
     }
     ._p-desk-70 {
       padding: 70px !important;
     }
     ._p-desk-80 {
       padding: 80px !important;
     }
     ._p-desk-90 {
       padding: 90px !important;
     }
     ._p-desk-100 {
       padding: 100px !important;
     }
     ._p-desk-120 {
       padding: 120px !important;
     }
     ._p-desk-150 {
       padding: 150px !important;
     }
     ._p-desk-200 {
       padding: 200px !important;
     }
     ._p-desk-250 {
       padding: 250px !important;
     }
     ._p-desk-300 {
       padding: 300px !important;
     }
     ._p-desk-400 {
       padding: 400px !important;
     }
     ._p-desk-500 {
       padding: 500px !important;
     }
     ._p-desk-600 {
       padding: 600px !important;
     }
     ._ph-desk-0 {
       padding-left: 0 !important;
       padding-right: 0 !important;
     }
     ._ph-desk-5 {
       padding-left: 5px !important;
       padding-right: 5px !important;
     }
     ._ph-desk-10 {
       padding-left: 10px !important;
       padding-right: 10px !important;
     }
     ._ph-desk-15 {
       padding-left: 15px !important;
       padding-right: 15px !important;
     }
     ._ph-desk-20 {
       padding-left: 20px !important;
       padding-right: 20px !important;
     }
     ._ph-desk-25 {
       padding-left: 25px !important;
       padding-right: 25px !important;
     }
     ._ph-desk-30 {
       padding-left: 30px !important;
       padding-right: 30px !important;
     }
     ._ph-desk-40 {
       padding-left: 40px !important;
       padding-right: 40px !important;
     }
     ._ph-desk-45 {
       padding-left: 45px !important;
       padding-right: 45px !important;
     }
     ._ph-desk-50 {
       padding-left: 50px !important;
       padding-right: 50px !important;
     }
     ._ph-desk-60 {
       padding-left: 60px !important;
       padding-right: 60px !important;
     }
     ._ph-desk-70 {
       padding-left: 70px !important;
       padding-right: 70px !important;
     }
     ._ph-desk-80 {
       padding-left: 80px !important;
       padding-right: 80px !important;
     }
     ._ph-desk-90 {
       padding-left: 90px !important;
       padding-right: 90px !important;
     }
     ._ph-desk-100 {
       padding-left: 100px !important;
       padding-right: 100px !important;
     }
     ._ph-desk-120 {
       padding-left: 120px !important;
       padding-right: 120px !important;
     }
     ._ph-desk-150 {
       padding-left: 150px !important;
       padding-right: 150px !important;
     }
     ._ph-desk-200 {
       padding-left: 200px !important;
       padding-right: 200px !important;
     }
     ._ph-desk-250 {
       padding-left: 250px !important;
       padding-right: 250px !important;
     }
     ._ph-desk-300 {
       padding-left: 300px !important;
       padding-right: 300px !important;
     }
     ._ph-desk-400 {
       padding-left: 400px !important;
       padding-right: 400px !important;
     }
     ._ph-desk-500 {
       padding-left: 500px !important;
       padding-right: 500px !important;
     }
     ._ph-desk-600 {
       padding-left: 600px !important;
       padding-right: 600px !important;
     }
     ._pv-desk-0 {
       padding-top: 0 !important;
       padding-bottom: 0 !important;
     }
     ._pv-desk-5 {
       padding-top: 5px !important;
       padding-bottom: 5px !important;
     }
     ._pv-desk-10 {
       padding-top: 10px !important;
       padding-bottom: 10px !important;
     }
     ._pv-desk-15 {
       padding-top: 15px !important;
       padding-bottom: 15px !important;
     }
     ._pv-desk-20 {
       padding-top: 20px !important;
       padding-bottom: 20px !important;
     }
     ._pv-desk-25 {
       padding-top: 25px !important;
       padding-bottom: 25px !important;
     }
     ._pv-desk-30 {
       padding-top: 30px !important;
       padding-bottom: 30px !important;
     }
     ._pv-desk-40 {
       padding-top: 40px !important;
       padding-bottom: 40px !important;
     }
     ._pv-desk-45 {
       padding-top: 45px !important;
       padding-bottom: 45px !important;
     }
     ._pv-desk-50 {
       padding-top: 50px !important;
       padding-bottom: 50px !important;
     }
     ._pv-desk-60 {
       padding-top: 60px !important;
       padding-bottom: 60px !important;
     }
     ._pv-desk-70 {
       padding-top: 70px !important;
       padding-bottom: 70px !important;
     }
     ._pv-desk-80 {
       padding-top: 80px !important;
       padding-bottom: 80px !important;
     }
     ._pv-desk-90 {
       padding-top: 90px !important;
       padding-bottom: 90px !important;
     }
     ._pv-desk-100 {
       padding-top: 100px !important;
       padding-bottom: 100px !important;
     }
     ._pv-desk-120 {
       padding-top: 120px !important;
       padding-bottom: 120px !important;
     }
     ._pv-desk-150 {
       padding-top: 150px !important;
       padding-bottom: 150px !important;
     }
     ._pv-desk-200 {
       padding-top: 200px !important;
       padding-bottom: 200px !important;
     }
     ._pv-desk-250 {
       padding-top: 250px !important;
       padding-bottom: 250px !important;
     }
     ._pv-desk-300 {
       padding-top: 300px !important;
       padding-bottom: 300px !important;
     }
     ._pv-desk-400 {
       padding-top: 400px !important;
       padding-bottom: 400px !important;
     }
     ._pv-desk-500 {
       padding-top: 500px !important;
       padding-bottom: 500px !important;
     }
     ._pv-desk-600 {
       padding-top: 600px !important;
       padding-bottom: 600px !important;
     }
     ._pt-desk-0 {
       padding-top: 0 !important;
     }
     ._pt-desk-5 {
       padding-top: 5px !important;
     }
     ._pt-desk-10 {
       padding-top: 10px !important;
     }
     ._pt-desk-15 {
       padding-top: 15px !important;
     }
     ._pt-desk-20 {
       padding-top: 20px !important;
     }
     ._pt-desk-25 {
       padding-top: 25px !important;
     }
     ._pt-desk-30 {
       padding-top: 30px !important;
     }
     ._pt-desk-40 {
       padding-top: 40px !important;
     }
     ._pt-desk-45 {
       padding-top: 45px !important;
     }
     ._pt-desk-50 {
       padding-top: 50px !important;
     }
     ._pt-desk-60 {
       padding-top: 60px !important;
     }
     ._pt-desk-70 {
       padding-top: 70px !important;
     }
     ._pt-desk-80 {
       padding-top: 80px !important;
     }
     ._pt-desk-90 {
       padding-top: 90px !important;
     }
     ._pt-desk-100 {
       padding-top: 100px !important;
     }
     ._pt-desk-120 {
       padding-top: 120px !important;
     }
     ._pt-desk-150 {
       padding-top: 150px !important;
     }
     ._pt-desk-200 {
       padding-top: 200px !important;
     }
     ._pt-desk-250 {
       padding-top: 250px !important;
     }
     ._pt-desk-300 {
       padding-top: 300px !important;
     }
     ._pt-desk-400 {
       padding-top: 400px !important;
     }
     ._pt-desk-500 {
       padding-top: 500px !important;
     }
     ._pt-desk-600 {
       padding-top: 600px !important;
     }
     ._pb-desk-0 {
       padding-bottom: 0 !important;
     }
     ._pb-desk-5 {
       padding-bottom: 5px !important;
     }
     ._pb-desk-10 {
       padding-bottom: 10px !important;
     }
     ._pb-desk-15 {
       padding-bottom: 15px !important;
     }
     ._pb-desk-20 {
       padding-bottom: 20px !important;
     }
     ._pb-desk-25 {
       padding-bottom: 25px !important;
     }
     ._pb-desk-30 {
       padding-bottom: 30px !important;
     }
     ._pb-desk-40 {
       padding-bottom: 40px !important;
     }
     ._pb-desk-45 {
       padding-bottom: 45px !important;
     }
     ._pb-desk-50 {
       padding-bottom: 50px !important;
     }
     ._pb-desk-60 {
       padding-bottom: 60px !important;
     }
     ._pb-desk-70 {
       padding-bottom: 70px !important;
     }
     ._pb-desk-80 {
       padding-bottom: 80px !important;
     }
     ._pb-desk-90 {
       padding-bottom: 90px !important;
     }
     ._pb-desk-100 {
       padding-bottom: 100px !important;
     }
     ._pb-desk-120 {
       padding-bottom: 120px !important;
     }
     ._pb-desk-150 {
       padding-bottom: 150px !important;
     }
     ._pb-desk-200 {
       padding-bottom: 200px !important;
     }
     ._pb-desk-250 {
       padding-bottom: 250px !important;
     }
     ._pb-desk-300 {
       padding-bottom: 300px !important;
     }
     ._pb-desk-400 {
       padding-bottom: 400px !important;
     }
     ._pb-desk-500 {
       padding-bottom: 500px !important;
     }
     ._pb-desk-600 {
       padding-bottom: 600px !important;
     }
     ._pl-desk-0 {
       padding-left: 0 !important;
     }
     ._pl-desk-5 {
       padding-left: 5px !important;
     }
     ._pl-desk-10 {
       padding-left: 10px !important;
     }
     ._pl-desk-15 {
       padding-left: 15px !important;
     }
     ._pl-desk-20 {
       padding-left: 20px !important;
     }
     ._pl-desk-25 {
       padding-left: 25px !important;
     }
     ._pl-desk-30 {
       padding-left: 30px !important;
     }
     ._pl-desk-40 {
       padding-left: 40px !important;
     }
     ._pl-desk-45 {
       padding-left: 45px !important;
     }
     ._pl-desk-50 {
       padding-left: 50px !important;
     }
     ._pl-desk-60 {
       padding-left: 60px !important;
     }
     ._pl-desk-70 {
       padding-left: 70px !important;
     }
     ._pl-desk-80 {
       padding-left: 80px !important;
     }
     ._pl-desk-90 {
       padding-left: 90px !important;
     }
     ._pl-desk-100 {
       padding-left: 100px !important;
     }
     ._pl-desk-120 {
       padding-left: 120px !important;
     }
     ._pl-desk-150 {
       padding-left: 150px !important;
     }
     ._pl-desk-200 {
       padding-left: 200px !important;
     }
     ._pl-desk-250 {
       padding-left: 250px !important;
     }
     ._pl-desk-300 {
       padding-left: 300px !important;
     }
     ._pl-desk-400 {
       padding-left: 400px !important;
     }
     ._pl-desk-500 {
       padding-left: 500px !important;
     }
     ._pl-desk-600 {
       padding-left: 600px !important;
     }
     ._pr-desk-0 {
       padding-right: 0 !important;
     }
     ._pr-desk-5 {
       padding-right: 5px !important;
     }
     ._pr-desk-10 {
       padding-right: 10px !important;
     }
     ._pr-desk-15 {
       padding-right: 15px !important;
     }
     ._pr-desk-20 {
       padding-right: 20px !important;
     }
     ._pr-desk-25 {
       padding-right: 25px !important;
     }
     ._pr-desk-30 {
       padding-right: 30px !important;
     }
     ._pr-desk-40 {
       padding-right: 40px !important;
     }
     ._pr-desk-45 {
       padding-right: 45px !important;
     }
     ._pr-desk-50 {
       padding-right: 50px !important;
     }
     ._pr-desk-60 {
       padding-right: 60px !important;
     }
     ._pr-desk-70 {
       padding-right: 70px !important;
     }
     ._pr-desk-80 {
       padding-right: 80px !important;
     }
     ._pr-desk-90 {
       padding-right: 90px !important;
     }
     ._pr-desk-100 {
       padding-right: 100px !important;
     }
     ._pr-desk-120 {
       padding-right: 120px !important;
     }
     ._pr-desk-150 {
       padding-right: 150px !important;
     }
     ._pr-desk-200 {
       padding-right: 200px !important;
     }
     ._pr-desk-250 {
       padding-right: 250px !important;
     }
     ._pr-desk-300 {
       padding-right: 300px !important;
     }
     ._pr-desk-400 {
       padding-right: 400px !important;
     }
     ._pr-desk-500 {
       padding-right: 500px !important;
     }
     ._pr-desk-600 {
       padding-right: 600px !important;
     }
   }
   ._h-10vh {
     height: 10vh !important;
   }
   ._h-15vh {
     height: 15vh !important;
   }
   ._h-20vh {
     height: 20vh !important;
   }
   ._h-25vh {
     height: 25vh !important;
   }
   ._h-30vh {
     height: 30vh !important;
   }
   ._h-33vh {
     height: 33vh !important;
   }
   ._h-40vh {
     height: 40vh !important;
   }
   ._h-45vh {
     height: 45vh !important;
   }
   ._h-50vh {
     height: 50vh !important;
   }
   ._h-55vh {
     height: 55vh !important;
   }
   ._h-60vh {
     height: 60vh !important;
   }
   ._h-66vh {
     height: 66vh !important;
   }
   ._h-75vh {
     height: 75vh !important;
   }
   ._h-80vh {
     height: 80vh !important;
   }
   ._h-81vh {
     height: 81vh !important;
   }
   ._h-90vh {
    height: 90vh !important;
  }
   ._h-100vh {
     height: 100vh !important;
   }
   ._h-120vh {
     height: 120vh !important;
   }
   ._h-10pct {
     height: 10% !important;
   }
   ._h-15pct {
     height: 15% !important;
   }
   ._h-20pct {
     height: 20% !important;
   }
   ._h-25pct {
     height: 25% !important;
   }
   ._h-30pct {
     height: 30% !important;
   }
   ._h-33pct {
     height: 33% !important;
   }
   ._h-40pct {
     height: 40% !important;
   }
   ._h-45pct {
     height: 45% !important;
   }
   ._h-50pct {
     height: 50% !important;
   }
   ._h-60pct {
     height: 60% !important;
   }
   ._h-66pct {
     height: 66% !important;
   }
   ._h-75pct {
     height: 75% !important;
   }
   ._h-80pct {
     height: 80% !important;
   }
   ._h-100pct {
     height: 100% !important;
   }
   ._h-120pct {
     height: 120% !important;
   }
   @media (min-width: 992px) {
     ._h-desk-10vh {
       height: 10vh !important;
     }
     ._h-desk-15vh {
       height: 15vh !important;
     }
     ._h-desk-20vh {
       height: 20vh !important;
     }
     ._h-desk-25vh {
       height: 25vh !important;
     }
     ._h-desk-30vh {
       height: 30vh !important;
     }
     ._h-desk-33vh {
       height: 33vh !important;
     }
     ._h-desk-40vh {
       height: 40vh !important;
     }
     ._h-desk-45vh {
       height: 45vh !important;
     }
     ._h-desk-50vh {
       height: 50vh !important;
     }
     ._h-desk-60vh {
       height: 60vh !important;
     }
     ._h-desk-66vh {
       height: 66vh !important;
     }
     ._h-desk-75vh {
       height: 75vh !important;
     }
     ._h-desk-80vh {
       height: 80vh !important;
     }
     ._h-desk-100vh {
       height: 100vh !important;
     }
     ._h-desk-120vh {
       height: 120vh !important;
     }
   }
   @media (max-width: 992px) {
     ._h-mob-10vh {
       height: 10vh !important;
     }
     ._h-mob-15vh {
       height: 15vh !important;
     }
     ._h-mob-20vh {
       height: 20vh !important;
     }
     ._h-mob-25vh {
       height: 25vh !important;
     }
     ._h-mob-30vh {
       height: 30vh !important;
     }
     ._h-mob-33vh {
       height: 33vh !important;
     }
     ._h-mob-40vh {
       height: 40vh !important;
     }
     ._h-mob-45vh {
       height: 45vh !important;
     }
     ._h-mob-50vh {
       height: 50vh !important;
     }
     ._h-mob-60vh {
       height: 60vh !important;
     }
     ._h-mob-66vh {
       height: 66vh !important;
     }
     ._h-mob-75vh {
       height: 75vh !important;
     }
     ._h-mob-80vh {
       height: 80vh !important;
     }
     ._h-mob-100vh {
       height: 100vh !important;
     }
     ._h-mob-120vh {
       height: 120vh !important;
     }
   }
   ._h-a {
     height: auto !important;
   }
   @media (max-width: 992px) {
     ._h-mob-a {
       height: auto !important;
     }
   }
   ._w-10pct {
     width: 10% !important;
   }
   ._w-15pct {
     width: 15% !important;
   }
   ._w-20pct {
     width: 20% !important;
   }
   ._w-25pct {
     width: 25% !important;
   }
   ._w-30pct {
     width: 30% !important;
   }
   ._w-33pct {
     width: 33% !important;
   }
   ._w-40pct {
     width: 40% !important;
   }
   ._w-45pct {
     width: 45% !important;
   }
   ._w-50pct {
     width: 50% !important;
   }
   ._w-60pct {
     width: 60% !important;
   }
   ._w-66pct {
     width: 66% !important;
   }
   ._w-75pct {
     width: 75% !important;
   }
   ._w-80pct {
     width: 80% !important;
   }
   ._w-100pct {
     width: 100% !important;
   }
   ._w-120pct {
     width: 120% !important;
   }
   ._w-a {
     width: auto !important;
   }
   ._w-f,
   ._w-full {
     width: 100% !important;
   }
   ._mw-10 {
     max-width: 10px !important;
   }
   ._mw-30 {
     max-width: 30px !important;
   }
   ._mw-50 {
     max-width: 50px !important;
   }
   ._mw-100 {
     max-width: 100px !important;
   }
   ._mw-200 {
     max-width: 200px !important;
   }
   ._mw-300 {
     max-width: 300px !important;
   }
   ._mw-400 {
     max-width: 400px !important;
   }
   ._mw-500 {
     max-width: 500px !important;
   }
   ._mw-600 {
     max-width: 600px !important;
   }
   ._bsh-xs {
     -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
   }
   ._bsh-sm {
     -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
     box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   }
   ._bsh {
     -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
   }
   ._bsh-lg {
     -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.23);
     box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.23);
   }
   ._bsh-xl {
     -webkit-box-shadow: 0 19px 38px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.22);
     box-shadow: 0 19px 38px rgba(0,0,0,0.3), 0 15px 12px rgba(0,0,0,0.22);
   }
   ._bsh-xxl {
     -webkit-box-shadow: 0 25px 50px rgba(0,0,0,0.4), 0 18px 16px rgba(0,0,0,0.21);
     box-shadow: 0 25px 50px rgba(0,0,0,0.4), 0 18px 16px rgba(0,0,0,0.21);
   }
   ._bsh-light._bsh-xs {
     -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
     box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
   }
   ._bsh-light._bsh-sm {
     -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.16);
     box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.16);
   }
   ._bsh-light._bsh {
     -webkit-box-shadow: 0 8px 20px rgba(0,0,0,0.08), 0 4px 6px rgba(0,0,0,0.12);
     box-shadow: 0 8px 20px rgba(0,0,0,0.08), 0 4px 6px rgba(0,0,0,0.12);
   }
   ._bsh-light._bsh-lg {
     -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.13), 0 10px 10px rgba(0,0,0,0.15);
     box-shadow: 0 14px 28px rgba(0,0,0,0.13), 0 10px 10px rgba(0,0,0,0.15);
   }
   ._bsh-light._bsh-xl {
     -webkit-box-shadow: 0 15px 40px rgba(0,0,0,0.15), 0 7px 12px rgba(0,0,0,0.11);
     box-shadow: 0 15px 40px rgba(0,0,0,0.15), 0 7px 12px rgba(0,0,0,0.11);
   }
   ._bsh-light._bsh-xxl {
     -webkit-box-shadow: 0 17px 50px rgba(0,0,0,0.17), 0 10px 10px rgba(0,0,0,0.11);
     box-shadow: 0 17px 50px rgba(0,0,0,0.17), 0 10px 10px rgba(0,0,0,0.11);
   }
   ._b {
     border: 1px solid #e6e6e6 !important;
   }
   ._b-n {
     border: none !important;
   }
   ._bc-dg {
     border-color: #d9d9d9 !important;
   }
   ._bc-w {
     border-color: #fff !important;
   }
   ._bc-g {
     border-color: #e6e6e6 !important;
   }
   ._bc-b {
     border-color: #000 !important;
   }
   ._bc-lb {
     border-color: #1a1a1a !important;
   }
   ._bc-p {
     border-color: #0093d2 !important;
   }
   ._bc-pi {
     border-color: #ff6c2d !important;
   }
   .front {
     position: relative !important;
     z-index: 99 !important;
   }
   ._pos-r {
     position: relative !important;
   }
   ._pos-a-b {
     bottom: 0 !important;
     top: auto !important;
     position: absolute !important;
     left: 0 !important;
     z-index: 99 !important;
   }
   ._pos-a-br {
     position: absolute !important;
     bottom: 0 !important;
     top: auto !important;
     right: 0 !important;
     left: auto !important;
     height: auto !important;
   }
   ._pos-a-bl {
     position: absolute !important;
     bottom: 0 !important;
     top: auto !important;
     right: auto !important;
     left: 0 !important;
     width: 100% !important;
     height: auto !important;
   }
   ._pos-a-tr {
     position: absolute !important;
     top: 0 !important;
     bottom: auto !important;
     right: 0 !important;
     left: auto !important;
     height: auto !important;
   }
   ._pos-a-tl {
     position: absolute !important;
     top: 0 !important;
     bottom: auto !important;
     left: 0 !important;
     right: auto !important;
     height: auto !important;
   }
   ._pos-v-c {
     position: absolute !important;
     top: 50% !important;
     height: auto !important;
     -webkit-transform: translateY(-50%) !important;
     -moz-transform: translateY(-50%) !important;
     -o-transform: translateY(-50%) !important;
     -ms-transform: translateY(-50%) !important;
     transform: translateY(-50%) !important;
   }
   ._pos-h-c {
     position: absolute !important;
     left: 50% !important;
     height: auto !important;
     -webkit-transform: translateX(-50%) !important;
     -moz-transform: translateX(-50%) !important;
     -o-transform: translateX(-50%) !important;
     -ms-transform: translateX(-50%) !important;
     transform: translateX(-50%) !important;
   }
   @media (min-width: 992px) {
     ._pos-desk-v-c {
       position: absolute !important;
       top: 40% !important;
       height: auto !important;
       -webkit-transform: translateY(-50%) !important;
       -moz-transform: translateY(-50%) !important;
       -o-transform: translateY(-50%) !important;
       -ms-transform: translateY(-50%) !important;
       transform: translateY(-50%) !important;
     }
     ._pos-desk-a-b {
       bottom: 0 !important;
       top: 99px !important;
       position: relative !important;
       left: 0 !important;
       z-index: 99 !important;
     }
   }
   @media (max-width: 992px) {
    ._pos-desk-v-c {
      position: absolute !important;
      top: 8.5% !important;
      height: auto !important;
      -webkit-transform: translateY(-50%) !important;
      -moz-transform: translateY(-50%) !important;
      -o-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    ._pos-desk-a-b {
      bottom: 0 !important;
      top: 167px !important;
      position: relative !important;
      left: 0 !important;
      z-index: 99 !important;
      display: none;
    }
      ._mb-50 {
        margin-bottom: 0px !important;
    }
      ._ml-101 {
        margin-left: 0% !important;
    }
      ._mt-120 {
        margin-top: 5px !important;
    }
    .theme-search-area-section-inner {
      position:inherit;
      border: 1px solid #d9d9d9;
  }
  }
   ._br-0 {
     border-radius: 0 !important;
   }
   ._br-1 {
     border-radius: 1px !important;
   }
   ._br-2 {
     border-radius: 2px !important;
   }
   ._br-3 {
     border-radius: 3px !important;
   }
   ._br-4 {
     border-radius: 4px !important;
   }
   ._br-5 {
     border-radius: 5px !important;
   }
   ._br-10 {
     border-radius: 10px !important;
   }
   ._br-r {
     border-radius: 50% !important;
   }
   ._bg-p,
   ._bg-primary {
     background-color: #0093d2 !important;
   }
   ._bg-p-grad {
     background: -webkit-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -moz-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -o-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: -ms-linear-gradient(left, #00a8f0, #0084bd) !important;
     background: linear-gradient(to right, #00a8f0, #0084bd) !important;
   }
   ._bg-pi,
   ._bg-primary-inverse {
     background-color: #ff6c2d !important;
   }
   ._bg-w,
   ._bg-white {
     background-color: #fff !important;
   }
   ._bg-b,
   ._bg-black {
     background-color: #000 !important;
   }
   ._bg-d,
   ._bg-dark {
     background-color: #333 !important;
   }
   ._bg-g,
   ._bg-gray {
     background-color: #e6e6e6 !important;
   }
   ._bg-b-03 {
     background-color: rgba(0,0,0,0.7) !important;
   }
   ._bg-b-04 {
     background-color: rgba(0,0,0,0.6) !important;
   }
   ._bg-b-05 {
     background-color: rgba(0,0,0,0.5) !important;
   }
   ._bg-w-05 {
     background-color: rgba(255,255,255,0.5) !important;
   }
   ._c {
     color: #595959 !important;
   }
   ._c-p,
   ._c-primary {
     color: #0093d2 !important;
   }
   ._c-pi,
   ._c-primary-inverse {
     color: #ff6c2d !important;
   }
   ._c-w,
   ._c-white {
     color: #fff !important;
   }
   ._c-b,
   ._c-black {
     color: #000 !important;
   }
   ._c-d,
   ._c-dark {
     color: #404040 !important;
   }
   ._fw-b,
   ._fw-bold {
     font-weight: bold !important;
   }
   ._fw-n,
   ._fw-normal {
     font-weight: normal !important;
   }
   ._fw-200 {
     font-weight: 200 !important;
   }
   ._ff-d {
     font-family: helvetica, Arial, sans-serif !important;
   }
   ._ff-p {
     font-family: 'Roboto', helvetica, Arial, sans-serif !important;
   }
   ._fs {
     font-size: 15px !important;
   }
   ._fs-b {
     font-size: 16px !important;
   }
   ._fs-lg {
     font-size: 18px !important;
   }
   ._fs-xl {
     font-size: 21px !important;
   }
   ._fs-xxl {
     font-size: 31px !important;
   }
   ._fs-sm {
     font-size: 14px !important;
   }
   ._fs-xs {
     font-size: 12px !important;
   }
   ._ta-c,
   ._ta-center {
     text-align: center !important;
   }
   @media (max-width: 992px) {
     ._ta-mob-c,
     ._ta-mob-center {
       text-align: center;
     }
   }
   ._ta-l,
   ._ta-lelft {
     text-align: left !important;
   }
   ._ta-r,
   ._ta-right {
     text-align: right !important;
   }
   ._tt-uc {
     text-transform: uppercase !important;
   }
   ._tt-lc {
     text-transform: lowercase !important;
   }
   ._tt-n {
     text-transform: none !important;
   }
   ._ls-0 {
     letter-spacing: 0 !important;
   }
   ._d-t {
     display: table !important;
   }
   ._d-b {
     display: block !important;
   }
   ._op-0 {
     opacity: 0 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)" !important;
     filter: alpha(opacity=0) !important;
   }
   ._op-01 {
     opacity: 0.1 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)" !important;
     filter: alpha(opacity=10) !important;
   }
   ._op-02 {
     opacity: 0.2 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)" !important;
     filter: alpha(opacity=20) !important;
   }
   ._op-03 {
     opacity: 0.3 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)" !important;
     filter: alpha(opacity=30) !important;
   }
   ._op-04 {
     opacity: 0.4 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)" !important;
     filter: alpha(opacity=40) !important;
   }
   ._op-05 {
     opacity: 0.5 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
     filter: alpha(opacity=50) !important;
   }
   ._op-06 {
     opacity: 0.6 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)" !important;
     filter: alpha(opacity=60) !important;
   }
   ._op-07 {
     opacity: 0.7 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)" !important;
     filter: alpha(opacity=70) !important;
   }
   ._op-08 {
     opacity: 0.8 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)" !important;
     filter: alpha(opacity=80) !important;
   }
   ._op-09 {
     opacity: 0.9 !important;
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)" !important;
     filter: alpha(opacity=90) !important;
   }
   ._op-1 {
     opacity: 1 !important;
     -ms-filter: none !important;
     filter: none !important;
   }
   @media (max-width: 992px) {
     ._mob-h {
       display: none !important;
     }
   }
   @media (max-width: 992px) {
     ._mob-s {
       display: block !important;
     }
   }
   @media (min-width: 992px) {
     ._desk-h {
       display: none !important;
     }
   }
   @media (min-width: 992px) {
     ._desk-s {
       display: block !important;
     }
   }
   /*  --/UTILITIES--  */

  .bg-slider-1 {
    background-image:
    url('../img/bg/3.jpeg');
    background-size: cover;
    color: white;
  }

  .bg-slider-2 {
    background-image:
    url('../img/bg/2.jpeg');
    background-size: cover;
    color: white;
  }

  .bg-slider-3 {
    background-image:
    url('../img/bg/5.jpeg');
    background-size: cover;
    color: white;
  }

  @media(max-width:767px) {
    .header-top {
        display: none
    }

    .mobile-hidden {
      display: none;
    }

    .mobile-row {
      margin-bottom: 8px;
    }
}

.header-wrapper {
  background: #fff;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: var(--craneGreenLight) !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
